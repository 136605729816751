import React, { Component } from 'react';
import DataGridToolbar from './DataGridToolbar';
import {
  ceil,
  endsWith,
  isArray,
  isEqual,
  isEmpty,
  trimEnd,
  debounce,
  cloneDeep,
} from 'lodash';
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { Backdrop, Checkbox, CircularProgress, Paper } from '@mui/material';
import { translateValue } from '../service/Translate';
import PropTypes from 'prop-types';

const checkBoxDefinition = [
  {
    field: 'customCheckBox',
    sortable: false,
    maxWidth: 60,
    renderHeader: (params) => {
      return (
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => this.handleHeaderCheckboxChange(e, params)}
        />
      );
    },
    renderCell: (params) => {
      return (
        <Checkbox
          checked={this.state.checkBoxSelectionModel.includes(params.id)}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => this.handleCheckboxChange(e, params)}
        />
      );
    },
  },
];

const TABLE_ROW_LIMIT = 100;

class DataGridTable extends Component {
  static propTypes = {
    noPaper: PropTypes.bool,
    onRowSelect: PropTypes.func,
    onExport: PropTypes.func,
    onReset: PropTypes.func,
    title: PropTypes.string.isRequired,
    rows: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    iteratableRows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    rowHeight: PropTypes.number,
    pagination: PropTypes.object,
    onEdit: PropTypes.func,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onLoadNext: PropTypes.func,
    loading: PropTypes.bool,
    useCheckbox: PropTypes.bool,
    disableExport: PropTypes.bool,
    disableResetButton: PropTypes.bool,
    onSortModelChange: PropTypes.func,
  };

  static defaultProps = {
    onRowSelect: null,
    onReset: null,
    disableResetButton: true,
    onExport: null,
    disableExport: true,
    useCheckBox: false,
    title: '',
    loading: false,
    rowHeight: 52,
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      sortModel: [],
      filterModel: {
        items: [],
      },
      paginationModel: [],
      checkBoxSelectionModel: [],
      columnDefinitionsWithCheckbox: [...props.columns, ...checkBoxDefinition],
      dataLoading: false,
    };
  }

  getFilterType = () => {
    const { filterModel } = this.state;
    let filterType;

    return filterModel.items.map((item) => {
      switch (item.operator) {
        case 'is':
          switch (item.field) {
            case 'affiliateHead':
            case 'headquarter':
            case 'embedded':
            case 'enabled':
            case 'local':
            case 'modified':
            case 'useTls':
              filterType = 'boolean';
              break;
            case 'created':
            case 'updated':
              filterType = 'date_eq';
              break;
            default:
              filterType = 'string_eq';
              break;
          }
          break;
        case 'not':
          switch (item.field) {
            case 'affiliateHead':
            case 'headquarter':
            case 'embedded':
            case 'enabled':
            case 'local':
            case 'modified':
              filterType = 'boolean';
              break;
            case 'created':
            case 'updated':
              filterType = 'date_not';
              break;
            default:
              filterType = 'string_not';
              break;
          }
          break;
        default:
          filterType = item.operator;
      }
      return {
        field: item.field,
        type: filterType,
        value: item.value,
      };
    });
  };

  loadNext = () => {
    const { onLoadNext, columns } = this.props;
    const { sortModel, paginationModel, filterModel } = this.state;

    this.setState({ dataLoading: true });

    const sortColumns = sortModel ? sortModel?.map((value) => value.field) : [];
    const sortOrders = sortModel ? sortModel?.map((value) => value.sort) : [];
    const limit = paginationModel?.pageSize;
    const page = paginationModel?.page;
    const offset = page && limit ? page * limit : undefined;
    const filter = filterModel ? this.getFilterType() : [];
    let join = [];

    if (!isEmpty(sortColumns)) {
      let column = !isEmpty(columns)
        ? columns.find((col) => col.field === sortColumns[0])
        : {};
      join = column.joinBy ? column.joinBy : [];
    }

    Promise.resolve(
      onLoadNext(sortColumns, sortOrders, join, offset, limit, filter),
    ).then(() => {
      this.setState({ dataLoading: false });
    });
  };

  handleSortModelChange = (newSortModel) => {
    const { onSortModelChange } = this.props;

    if (onSortModelChange) {
      onSortModelChange(newSortModel);
    }
    this.setState({ sortModel: newSortModel }, this.loadNext);
  };

  handleFilterModelChange = debounce((newFilterModel) => {
    this.setState({ filterModel: newFilterModel }, this.loadNext);
  }, 2000);

  handlePaginationModelChange = (newPaginationModel) => {
    this.setState({ paginationModel: newPaginationModel }, this.loadNext);
  };

  handleDoubleClick = (params) => {
    if (this.props.processRowUpdate) {
      return;
    }

    this.props.onEdit(params.row.id);
  };

  handleClick = (params) => {
    this.setState({ selected: params.row.id });

    if (this.props.onRowSelect) {
      this.props.onRowSelect(params.row.id);
    }
  };

  handleCheckboxChange = (e, params) => {
    let { checkBoxSelectionModel } = this.state;

    if (e.target.checked && !checkBoxSelectionModel.includes(params.id)) {
      checkBoxSelectionModel.push(params.id);
    } else {
      const index = checkBoxSelectionModel.indexOf(params.id);
      checkBoxSelectionModel.splice(index, 1);
    }
    this.setState({ checkBoxSelectionModel: checkBoxSelectionModel });
    this.props.onCheckboxChange(checkBoxSelectionModel);
  };

  handleHeaderCheckboxChange = (e) => {
    if (e.target.checked === true) {
      //todo
    } else if (e.target.indeterminate === true) {
      //todo
    } else {
      //todo
    }
  };

  handleExport = () => {
    const { onExport } = this.props;
    const { checkBoxSelectionModel } = this.state;

    onExport(checkBoxSelectionModel);
  };

  renderRelationData = (column, row) => {
    let data = row ? row[column.field] : '';

    if (row && column.relation) {
      data = '';
      let id;
      let entity;
      let base = row;
      const relations = column.relation.split('.');

      for (let i = 0; i < relations.length; i++) {
        entity =
          column.relationMap && column.relationMap[relations[i]]
            ? column.relationMap[relations[i]]
            : relations[i];

        if (isArray(base[relations[i]])) {
          entity = endsWith(entity, 's') ? trimEnd(entity, 's') : entity;

          for (let j = 0; j < base[relations[i]].length; j++) {
            id = base[relations[i]][j];
            if (
              this.props.entities[entity] &&
              this.props.entities[entity][id]
            ) {
              data +=
                (data.length ? ', ' : '') +
                this.props.entities[entity][id][column.id];
            }
          }
        } else {
          id = base[relations[i]];

          if (this.props.entities[entity] && this.props.entities[entity][id]) {
            base = this.props.entities[entity][id];

            if (i === relations.length - 1) {
              data = this.props.entities[entity][id][column.id];
            }
          }
        }
      }
    }

    if (Array.isArray(data)) {
      return data.toString();
    }

    return data;
  };

  renderRows = () => {
    const { rows, columns, iteratableRows } = this.props;
    let newRows = [];

    if (!isEmpty(rows) && !isEmpty(iteratableRows)) {
      iteratableRows.map((rowId) => {
        const current = cloneDeep(rows[rowId]);
        columns.map((column) => {
          if (column.relation) {
            current[column.field] = this.renderRelationData(column, current);
          }
        });
        newRows.push(current);
      });
    }
    return newRows;
  };

  onDuplicate = () => {
    const { checkBoxSelectionModel, selected } = this.state;
    const { onDuplicate } = this.props;

    if (
      selected &&
      (!checkBoxSelectionModel || checkBoxSelectionModel?.length <= 1)
    ) {
      onDuplicate([selected]);
    } else {
      onDuplicate(checkBoxSelectionModel);
    }
  };

  render() {
    const {
      disableSelectionOnClick,
      noPaper,
      useCheckBox,
      iteratableRows,
      loading,
      pagination,
      processRowUpdate,
      onCellEditStart,
      title,
      onEdit,
      onDuplicate,
      onAdd,
      onDelete,
      disableToolbar,
      embedded,
      rowHeight,
      onReset,
      disableResetButton,
      deleteTooltipText,
      disableDelete,
      onExport,
      initialState,
      columns,
    } = this.props;
    const {
      selected,
      checkBoxSelectionModel,
      columnDefinitionsWithCheckbox,
      dataLoading,
    } = this.state;

    let disableAdd,
      disableEdit,
      disableDuplicate = false;

    if (selected === null) {
      disableEdit = true;
      disableDuplicate = true;
    }

    if (
      selected !== null &&
      useCheckBox &&
      checkBoxSelectionModel.length === 0
    ) {
      disableDuplicate = true;
    }

    if (useCheckBox && checkBoxSelectionModel.length >= 1) {
      disableDuplicate = false;
      disableEdit = !(checkBoxSelectionModel.length === 1 || selected);
    }

    let disableExport = isEmpty(checkBoxSelectionModel);

    const rows = this.renderRows();

    const gridContent = (
      <>
        {!disableToolbar && (
          <DataGridToolbar
            embedded={embedded}
            onEdit={onEdit}
            onAdd={onAdd}
            onDelete={onDelete}
            title={title}
            selected={selected}
            onDuplicate={onDuplicate ? this.onDuplicate : null}
            disableDuplicate={disableDuplicate}
            disableAdd={disableAdd}
            disableDelete={
              disableDelete || iteratableRows?.length === 0 || !selected
            }
            deleteTooltipText={deleteTooltipText}
            disableEdit={disableEdit}
            onReset={onReset}
            disableResetButton={disableResetButton}
            onExport={onExport ? this.handleExport : null}
            disableExportButton={disableExport}
          />
        )}
        <DataGrid
          sx={{
            border: 'none',
            '& .MuiDataGrid-window': {
              overflowY: 'hidden !important',
            },
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within':
              {
                outline: 'none !important',
              },
            '& .MuiDataGrid-columnsContainer': {
              borderTop: '1px solid rgba(224, 224, 224, 1)',
            },
          }}
          autoHeight={true}
          rows={rows}
          columns={useCheckBox ? columnDefinitionsWithCheckbox : columns}
          rowHeight={rowHeight}
          sortingOrder={['asc', 'desc']}
          pageSizeOptions={[TABLE_ROW_LIMIT]}
          rowCount={pagination?.total ?? iteratableRows?.length ?? 1}
          onRowDoubleClick={this.handleDoubleClick}
          onRowClick={this.handleClick}
          onSortModelChange={this.handleSortModelChange}
          onFilterModelChange={this.handleFilterModelChange}
          onPaginationModelChange={this.handlePaginationModelChange}
          sortingMode="server"
          filterMode="server"
          paginationMode="server"
          experimentalFeatures={{ newEditingApi: true }}
          onCellEditStart={onCellEditStart}
          processRowUpdate={processRowUpdate}
          loading={loading || dataLoading}
          disableRowSelectionOnClick={loading || disableSelectionOnClick}
          slots={{
            loadingOverlay: this.loadingOverlay,
          }}
          initialState={initialState}
        />
      </>
    );

    return noPaper ? (
      gridContent
    ) : (
      <Paper sx={{ p: 0, m: 0 }}>{gridContent}</Paper>
    );
  }
}

function mapStateToProps(state) {
  return {
    entities: state.entities,
  };
}

export default connect(mapStateToProps, {})(DataGridTable);
