import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import RoleAwareComponent from './RoleAwareComponent';
import { Avatar } from '@mui/material';
import { isEqual } from 'lodash';
import { withRouter } from '../withRouter';
import { compose } from 'redux';

class CustomerListCustomerAvatar extends RoleAwareComponent {
  static propTypes = {
    customerListCustomerId: PropTypes.number.isRequired,
    customerListCustomer: PropTypes.object,
    customer: PropTypes.object,
    size: PropTypes.number,
    noWrap: PropTypes.bool,
    docked: PropTypes.bool,
  };

  static defaultProps = {
    docked: false,
    size: 30,
    noWrap: true,
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(
        this.props.customerListCustomerId,
        nextProps.customerListCustomerId,
      ) ||
      !isEqual(this.props.customerListCustomer, nextProps.customerListCustomer)
    );
  };

  wrapCompletedStatus = () => {
    let avatar = this.renderAvatar({
      p: 0,
      position: 'absolute',
      top: '8px',
      left: '26px',
    });

    return avatar;
  };

  getAbbr = () => {
    let abbr = null;
    const { customer } = this.props;

    if (!customer) {
      return '';
    }

    switch (customer.gender) {
      case 'company':
        abbr = customer.completeName.charAt(0);
        break;
      case 'female':
      case 'male':
      default:
        abbr =
          (customer.firstname ? customer.firstname.charAt(0) : '') +
          '' +
          (customer.name ? customer.name.charAt(0) : '');
        break;
    }

    return abbr;
  };

  renderAvatar = (style = {}) => {
    const { size } = this.props;

    return (
      <Avatar sx={style} size={size}>
        {this.getAbbr()}
      </Avatar>
    );
  };

  render() {
    let avatar = this.renderAvatar({
      p: 0,
      position: 'absolute',
      top: '8px',
      left: '26px',
    });

    return avatar;
  }
}

function mapStateToProps(state, props) {
  const customerListCustomerId = props.customerListCustomerId;
  const {
    entities: { customerListCustomer, customer },
  } = state;

  return {
    customerListCustomerId: customerListCustomerId,
    customerListCustomer: customerListCustomerId
      ? customerListCustomer[customerListCustomerId]
      : null,
    customer: customerListCustomerId
      ? customer[customerListCustomer[customerListCustomerId].customer]
      : null,
  };
}

const enhance = compose(withRouter, connect(mapStateToProps, {}));

export default enhance(CustomerListCustomerAvatar);
