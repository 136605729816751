import PropTypes from 'prop-types';
import React from 'react';
import RoleAwareComponent from './RoleAwareComponent';
import { connect } from 'react-redux';
import { pick, keys, isEqual, isEmpty } from 'lodash';
import {
  searchEmailModule,
  searchEmailQueue,
  searchSmsQueue,
  searchProduct,
  searchCampaignConfigParam,
} from '../actions';
import ReactTimeout from 'react-timeout';
import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import { withRouter } from '../withRouter';
import { styled } from '@mui/system';
import { compose } from 'redux';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const PopperMy = function (props) {
  return (
    <Popper
      {...props}
      style={{ width: '100%', maxWidth: 600 }}
      placement="bottom-start"
    />
  );
};

const Root = styled('div')({
  flexShrink: 1,
  flexBasis: '70%',
  width: 'calc(100% - 200px)',
  '& > * + *': {
    mt: 2,
  },
});

const groupMapping = {
  emailModule: 'E-Mail Module',
  emailQueue: 'E-Mails',
  smsQueue: 'SMS',
  product: 'Produkte',
  campaignConfigParam: 'Kampagnenkonfiguration',
};

class SearchBar extends RoleAwareComponent {
  static contextTypes = {
    router: PropTypes.object,
  };

  static defaultProps = {
    docked: true,
  };

  state = {
    localSource: [],
    remote: {},
    remoteSource: [],
    loading: false,
    queryString: null,
  };

  constructor(props, context) {
    super(props, context);
    this.requestsPending = {
      emailModule: 0,
      emailQueue: 0,
      smsQueue: 0,
      product: 0,
      campaignConfigParam: 0,
    };
    this.timeoutId = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(prevProps.resultsets, this.props.resultsets) ||
      !isEqual(
        this.state.searchEmailModulesPending,
        prevState.searchEmailModulesPending,
      ) ||
      !isEqual(
        this.state.searchEmailQueuesPending,
        prevState.searchEmailQueuesPending,
      ) ||
      !isEqual(
        this.state.searchProductsPending,
        prevState.searchProductsPending,
      ) ||
      !isEqual(
        this.state.searchCampaignConfigParamsPending,
        prevState.searchCampaignConfigParamsPending,
      ) ||
      !isEqual(
        this.state.searchSmsQueuesPending,
        prevState.searchSmsQueuesPending,
      )
    ) {
      const { entities, resultsets } = this.props;
      const remote = [];

      Object.keys(resultsets).map((key) => {
        if (!isEmpty(resultsets[key])) {
          resultsets[key].map((id) => {
            remote.push({
              editAction: entities[key][id].searchActions?.editAction
                ? (e) => {
                    e.stopPropagation();
                    this.props.navigate(
                      entities[key][id].searchActions?.editAction,
                    );
                  }
                : null,
              duplicateAction: entities[key][id].searchActions?.duplicateAction
                ? (e) => {
                    e.stopPropagation();
                    this.props.navigate(
                      entities[key][id].searchActions?.duplicateAction,
                    );
                  }
                : null,
              id: entities[key][id].id,
              text: entities[key][id].primaryText,
              value: Routing.generate(
                entities[key][id].routeConfig.name,
                entities[key][id].routeConfig.params,
              ),
              group: groupMapping[key],
            });
          });
        }
      });

      if (this.state.searchEmailModulesPending) {
        remote.push({
          text: 'Daten werden geladen...',
          disabled: true,
          group: groupMapping['emailModule'],
        });
      }

      if (this.state.searchEmailQueuesPending) {
        remote.push({
          text: 'Daten werden geladen...',
          disabled: true,
          group: groupMapping['emailQueue'],
        });
      }

      if (this.state.searchSmsQueuesPending) {
        remote.push({
          text: 'Daten werden geladen...',
          disabled: true,
          group: groupMapping['smsQueue'],
        });
      }

      if (
        this.hasRequiredRole('ROLE_ADMIN') &&
        this.state.searchProductsPending
      ) {
        remote.push({
          text: 'Daten werden geladen...',
          disabled: true,
          group: groupMapping['product'],
        });
      }

      if (
        this.hasRequiredRole('ROLE_ADMIN') &&
        this.state.searchCampaignConfigParamsPending
      ) {
        remote.push({
          text: 'Daten werden geladen...',
          disabled: true,
          group: groupMapping['campaignConfigParam'],
        });
      }

      remote.sort((a, b) => -b.group.localeCompare(a.group));
      this.setState({ remoteSource: remote });
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.resultsets, this.props.resultsets) ||
      !isEqual(this.state.remoteSource, nextState.remoteSource) ||
      !isEqual(
        this.state.searchEmailModulesPending,
        nextState.searchEmailModulesPending,
      ) ||
      !isEqual(
        this.state.searchEmailQueuesPending,
        nextState.searchEmailQueuesPending,
      ) ||
      !isEqual(
        this.state.searchSmsQueuesPending,
        nextState.searchSmsQueuesPending,
      ) ||
      !isEqual(
        this.state.searchProductsPending,
        nextState.searchProductsPending,
      ) ||
      !isEqual(
        this.state.searchCampaignConfigParamsPending,
        nextState.searchCampaignConfigParamsPending,
      ) ||
      !isEqual(this.state.loading, nextState.loading)
    );
  };

  handleNewRequest = (event, request) => {
    if (typeof request === 'object' && request) {
      this.props.navigate(request.value);
    }
  };

  handleUpdateInput = (event, searchText) => {
    //event, values
    const {
      searchEmailModule,
      searchEmailQueue,
      searchSmsQueue,
      searchProduct,
      searchCampaignConfigParam,
    } = this.props;
    this.setState({ queryString: searchText });

    if (searchText.length > 2 && event.type !== 'click') {
      if (this.timeoutId) {
        this.props.clearTimeout(this.timeoutId);
      }

      this.timeoutId = this.props.setTimeout(
        function () {
          //@toDo: cancel previous requests (not possible with fetch, maybe switch to axios) - done with AbortController
          this.setState({
            searchEmailModulesPending: this.hasRequiredRole('ROLE_SUPERADMIN'),
            searchEmailQueuesPending: this.hasRequiredRole('ROLE_ADMIN'),
            searchSmsQueuesPending: this.hasRequiredRole('ROLE_ADMIN'),
            searchProductsPending: this.hasRequiredRole('ROLE_ADMIN'),
            searchCampaignConfigParamsPending:
              this.hasRequiredRole('ROLE_ADMIN'),
            loading: true,
          });

          this.requestsPending.emailModule++;
          // eslint-disable-next-line no-unused-vars
          Promise.all([searchEmailModule(searchText)]).then((values) => {
            this.requestsPending.emailModule--;
            if (this.requestsPending.emailModule === 0) {
              this.setState({
                searchEmailModulesPending: false,
                loading: false,
              });
            }
          });

          this.requestsPending.emailQueue++;
          // eslint-disable-next-line no-unused-vars
          Promise.all([searchEmailQueue(searchText)]).then((values) => {
            this.requestsPending.emailQueue--;
            if (this.requestsPending.emailQueue === 0) {
              this.setState({
                searchEmailQueuesPending: false,
                loading: false,
              });
            }
          });

          this.requestsPending.smsQueue++;
          // eslint-disable-next-line no-unused-vars
          Promise.all([searchSmsQueue(searchText)]).then((values) => {
            this.requestsPending.smsQueue--;
            if (this.requestsPending.smsQueue === 0) {
              this.setState({ searchSmsQueuesPending: false, loading: false });
            }
          });

          if (this.hasRequiredRole('ROLE_ADMIN')) {
            this.requestsPending.product++;
            // eslint-disable-next-line no-unused-vars
            Promise.all([searchProduct(searchText)]).then((values) => {
              this.requestsPending.product--;
              if (this.requestsPending.product === 0) {
                this.setState({ searchProductsPending: false, loading: false });
              }
            });
          }

          if (this.hasRequiredRole('ROLE_ADMIN')) {
            this.requestsPending.campaignConfigParam++;
            // eslint-disable-next-line no-unused-vars
            Promise.all([searchCampaignConfigParam(searchText)]).then(
              (values) => {
                this.requestsPending.campaignConfigParam--;
                if (this.requestsPending.campaignConfigParam === 0) {
                  this.setState({
                    searchCampaignConfigParamsPending: false,
                    loading: false,
                  });
                }
              },
            );
          }
        }.bind(this),
        1000,
      );
    }
  };

  render() {
    const { remoteSource, loading } = this.state;
    const dataSource = !isEmpty(remoteSource) ? remoteSource : [];

    return (
      <Root>
        <Autocomplete
          id="search-autocomplete"
          openOnFocus={true}
          freeSolo={true}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option.text === value.text && option.id === value.id
          }
          getOptionDisabled={(option) => option.disabled}
          renderOption={(props, option) => (
            <li
              {...props}
              key={option.text + option.id}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              {option.text}
              {(option.editAction || option.duplicateAction) && (
                <Box>
                  {option.editAction && (
                    <Tooltip title="Bearbeiten">
                      <IconButton
                        aria-label="Forward"
                        onClick={option.editAction}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {option.duplicateAction && (
                    <Tooltip title="Duplizieren">
                      <IconButton
                        aria-label="Forward"
                        onClick={option.duplicateAction}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              )}
            </li>
          )}
          groupBy={(option) => option.group}
          onInputChange={this.handleUpdateInput}
          onChange={this.handleNewRequest}
          filterOptions={(x) => x}
          options={dataSource}
          loading={loading}
          clearOnBlur={false}
          noOptionsText={'Die Suche ergab keine Ergebnisse'}
          loadingText={'Daten werden geladen'}
          PopperComponent={PopperMy}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Suche"
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Root>
    );
  }
}

SearchBar.propTypes = {
  docked: PropTypes.bool,
  entities: PropTypes.object,
  resultsets: PropTypes.object,
  groups: PropTypes.array,
};

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state, ownProps) {
  const { entities, searchresults } = state;

  return {
    entities: entities ? pick(entities, keys(searchresults)) : {},
    resultsets: searchresults,
    groups: keys(searchresults),
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    searchEmailModule,
    searchSmsQueue,
    searchEmailQueue,
    searchProduct,
    searchCampaignConfigParam,
  }),
  ReactTimeout,
);

export default enhance(SearchBar);
