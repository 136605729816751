import React from 'react';
import { Route, Outlet, createRoutesFromElements } from 'react-router-dom';
import { SEASON_TYPE } from './utils/Constants';

import Default from './scenes/Default/Default';

import CampaignIndex from './scenes/Campaign/Index';
import CampaignShow from './scenes/Campaign/Show';
import CampaignEdit from './scenes/Campaign/Edit';
import NewSeason from './scenes/Season/New';
import SeasonIndex from './scenes/Season/Index';
import SeasonCausalityIndex from './scenes/SeasonCausality/Index';

import Profile from './scenes/Profile/Profile';
import LetterIndex from './scenes/Letter/Index';
import MailingModuleIndex from './scenes/MailingModule/Index';
import OwnerIndex from './scenes/Owner/Index';
import AffiliateIndex from './scenes/Affiliate/Index';
import StaffIndex from './scenes/Staff/Index';
import OwnerInterfaceIndex from './scenes/OwnerInterface/Index';
import LocationIndex from './scenes/Location/Index';
import E404 from './scenes/Error/E404';
import E403 from './scenes/Error/E403';
import Home from './scenes/Home/Home';
import Help from './scenes/Help/Help';
import Ipsum from './scenes/Ipsum/Ipsum';
import Mailingtemplate from './scenes/Help/Mailingtemplate';
import Promotionmailings from './scenes/Help/Promotionmailings';
import Placeholders from './scenes/Help/Placeholders';
import EmailLayoutIndex from './scenes/EmailLayout/Index';
import EmailAccountIndex from './scenes/EmailAccount/Index';
import EmailQueue from './scenes/EmailQueue/Index';
import SmsQueueIndex from './scenes/SmsQueue/Index';
import CcsScanIndex from './scenes/CcsScan/Index';
import TemplateEditor from './scenes/TemplateEditor/Editor';
import SigneeIndex from './scenes/Signee/Index';
import CampaignConfigParamIndex from './scenes/CampaignConfigParam/Index';
import LocationSetting from './scenes/LocationSetting/LocationSetting';
import ProductIndex from './scenes/Product/Index';
import EmailModuleIndex from './scenes/EmailModule/Index';
import StatisticCampaign from './scenes/StatisticCampaign/StatisticCampaign';
import StatisticCampaignIndex from './scenes/StatisticCampaign/Index';
import EmailAccountEdit from './scenes/EmailAccount/Edit';

function DefaultIndex() {
  return <Default />;
}

function ParentIndex() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default createRoutesFromElements(
  <Route path="/" element={<DefaultIndex />}>
    <Route index element={<Home />} />
    <Route path="campaign" element={<ParentIndex />}>
      <Route index element={<CampaignIndex />} />
      <Route path=":campaignId/show" element={<CampaignShow />} />
      <Route
        path=":campaignId/seasoncausality/:seasonId"
        element={<SeasonCausalityIndex />}
      />
      <Route path="new" element={<CampaignEdit />} />
      <Route path=":campaignId/edit" element={<CampaignEdit />} />
      <Route
        path=":campaignId/mailing/new"
        element={<NewSeason seasonType={SEASON_TYPE.MAILING} />}
      />
      <Route
        path=":campaignId/email/new"
        element={<NewSeason seasonType={SEASON_TYPE.EMAIL} />}
      />
      <Route
        path=":campaignId/sms/new"
        element={<NewSeason seasonType={SEASON_TYPE.SMS} />}
      />
      <Route
        path=":campaignId/season/:seasonType/:mode/:seasonId"
        element={<SeasonIndex />}
      />
    </Route>

    <Route path="campaignConfigParam" element={<ParentIndex />}>
      <Route index element={<CampaignConfigParamIndex />} />
      <Route path=":mode" element={<CampaignConfigParamIndex />} />
      <Route
        path=":mode/:campaignConfigParamId"
        element={<CampaignConfigParamIndex />}
      />
    </Route>

    <Route
      path="campaignconfigparam/campaign/:campaignId"
      element={<CampaignConfigParamIndex />}
    />

    <Route path="product" element={<ParentIndex />}>
      <Route index element={<ProductIndex />} />
      <Route path=":mode" element={<ProductIndex />} />
      <Route path=":mode/:productId" element={<ProductIndex />} />
    </Route>

    <Route path="product/campaign/:campaignId" element={<ProductIndex />} />

    <Route path="sms" element={<ParentIndex />}>
      <Route path="queue" element={<SmsQueueIndex />} />
      <Route path="queue/customer/:seasonJobId" element={<SmsQueueIndex />} />
      <Route
        path="queue/customer/:seasonJobId/:customerListCustomerId"
        element={<SmsQueueIndex />}
      />
    </Route>

    <Route path="email" element={<ParentIndex />}>
      <Route path=":emailId/editor" element={<TemplateEditor />} />
      <Route path="queue" element={<EmailQueue />} />
      <Route path="queue/customer/:seasonJobId" element={<EmailQueue />} />
      <Route
        path="queue/customer/:seasonJobId/:customerListCustomerId"
        element={<EmailQueue />}
      />

      <Route path="layout" element={<EmailLayoutIndex />} />
      <Route path="layout/:mode" element={<EmailLayoutIndex />} />
      <Route path="layout/:mode/:layoutId" element={<EmailLayoutIndex />} />

      <Route path="account" element={<EmailAccountIndex />} />
      <Route path="account/:mode" element={<EmailAccountEdit />} />
      <Route path="account/:mode/:accountId" element={<EmailAccountEdit />} />
      <Route path="module" element={<EmailModuleIndex />} />
      <Route path="module/:mode" element={<EmailModuleIndex />} />
      <Route path="module/:mode/:moduleId" element={<EmailModuleIndex />} />
    </Route>

    <Route path="mailing" element={<ParentIndex />}>
      <Route path="signee" element={<SigneeIndex />} />
      <Route path="signee/:mode" element={<SigneeIndex />} />
      <Route path="signee/:mode/:signeeId" element={<SigneeIndex />} />
    </Route>

    <Route path={Routing.generate('letter_index')} element={<LetterIndex />} />
    <Route
      path={Routing.generate('mailingmodule_index')}
      element={<MailingModuleIndex />}
    />
    <Route path={Routing.generate('season_index')} element={<SeasonIndex />} />

    <Route path={Routing.generate('owner_index')} element={<OwnerIndex />} />
    <Route
      path={Routing.generate('affiliate_index')}
      element={<AffiliateIndex />}
    />
    <Route path={Routing.generate('staff_index')} element={<StaffIndex />} />

    <Route path="location" element={<LocationIndex />}>
      <Route path="setting/:mode" element={<LocationSetting />} />
      <Route
        path="setting/:mode/:locationSettingId"
        element={<LocationSetting />}
      />
    </Route>

    <Route
      path={Routing.generate('upload_list')}
      element={<OwnerInterfaceIndex />}
    />

    <Route path="ccsscan" element={<CcsScanIndex />} />

    <Route path="staff" element={<StaffIndex />} />
    <Route path={Routing.generate('staff_profile')} element={<Profile />} />
    <Route path="home" element={<Home />} />

    <Route path="help" element={<Help />} />
    <Route path="help/aktionsmailing" element={<Promotionmailings />} />
    <Route path="help/platzhalter" element={<Placeholders />} />
    <Route path="help/mailingtemplate" element={<Mailingtemplate />} />

    <Route path="statistic/campaign" element={<StatisticCampaign />}>
      <Route index element={<StatisticCampaignIndex />} />
      <Route path=":id" element={<StatisticCampaignIndex />} />
    </Route>

    <Route path="contact" element={<Ipsum />} />
    <Route path="profile" element={<Profile />} />
    <Route path="not-authorized" element={<E403 />} />
    <Route path="*" element={<E404 />} />
  </Route>,
);
