import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Paper, IconButton, Tooltip } from '@mui/material';
import ContentSave from '@mui/icons-material/Save';
import EditorInsertLink from '@mui/icons-material/InsertLink';
import NavigationCancel from '@mui/icons-material/Cancel';
import Translate from '../../components/service/Translate';
import { connect } from 'react-redux';
import { isEqual, isEmpty } from 'lodash';
import { loadSeasons, updateEmailSeason, updateSmsSeason } from './actions';
import { resetEntity, outdateEntity } from '../../actions';
import { fetchCampaign } from '../Campaign/actions';
import { makeCampaignSeasonsByType } from '../Campaign/selectors_deprecated';
import EmailEdit from './components/Edit';
import SmsEdit from './components/EditSms';
import AvPlaylistAddCheck from '@mui/icons-material/PlaylistAddCheck';
import ErrorBoundary from '../../components/ErrorBoundary';
import { withRouter } from '../../withRouter';
import BreadcrumbsNav from '../../components/BreadcrumbNavigation';
import { loadEmailBySeason } from '../Email/actions';
import { SEASON_TYPE } from '../../utils/Constants';
import {
  Spacer,
  Title,
  ToolbarRoot,
} from '../../components/StyledElements/StyledElements';
import { compose } from 'redux';

class Season extends Component {
  static propTypes = {
    fetchCampaign: PropTypes.func.isRequired,
    campaignId: PropTypes.number,
    seasonType: PropTypes.string,
    seasonId: PropTypes.number,
    campaigns: PropTypes.object,
    seasons: PropTypes.object,
    channel: PropTypes.object,
    mode: PropTypes.string,

    resetEntity: PropTypes.func.isRequired,
    outdateEntity: PropTypes.func.isRequired,
    loadSeasons: PropTypes.func.isRequired,
    updateEmailSeason: PropTypes.func.isRequired,
    resultset: PropTypes.array,
  };

  static defaultProps = {
    docked: false,
    resultset: [],
    mode: 'show',
  };

  state = {
    adding: false,
    editing: false,
    valid: false,
    mode: 'show',
    isSubmitting: false,
  };

  componentDidMount = () => {
    this.props.fetchCampaign(this.props.campaignId);

    switch (this.props.mode) {
      case 'new':
        this.setState({
          adding: true,
          editing: false,
          valid: false,
          mode: 'adding',
        });
        break;
      case 'edit':
        this.setState({
          adding: false,
          editing: true,
          valid: false,
          mode: 'editing',
        });
        break;
      default:
        this.setState({
          adding: false,
          editing: false,
          valid: false,
          mode: 'show',
        });
        break;
    }
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.mode != this.props.mode) {
      switch (this.props.mode) {
        case 'new':
          this.setState({
            adding: true,
            editing: false,
            valid: false,
            mode: 'adding',
          });
          break;
        case 'edit':
          this.setState({
            adding: false,
            editing: true,
            valid: false,
            mode: 'editing',
          });
          break;
        default:
          this.setState({
            adding: false,
            editing: false,
            valid: false,
            mode: 'show',
          });
          break;
      }
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.seasons, this.props.seasons) ||
      !isEqual(nextState, this.state) ||
      !isEqual(nextProps.defaultIndex, this.props.defaultIndex) ||
      !isEqual(nextProps.mode, this.props.mode)
    );
  };

  handleFormMount = (form) => {
    this.form = form;
  };

  submit = () => {
    this.form.submit();
  };

  handleSubmit = (form) => {
    const promises = [];
    const {
      updateSMSSeason,
      updateEmailSeason,
      outdateEntity,
      loadEmailBySeason,
      seasonType,
      seasonId,
      seasons,
      campaignId,
      resetEntity,
    } = this.props;
    const { editing } = this.state;
    let updateAction =
      seasonType === SEASON_TYPE.EMAIL ? updateEmailSeason : updateSMSSeason;
    let selectionId = form.get('season[selection]');

    this.setState({ valid: false, isSubmitting: true });
    promises.push(
      updateAction(form, editing ? seasons[seasonId][seasonType] : null),
    );

    return Promise.all(promises).then(
      (values) => {
        if (!(values && values[0] && values[0].error)) {
          outdateEntity('season');
          outdateEntity('email');
          outdateEntity('campaign');
          outdateEntity('layout');
          outdateEntity('sms');
          outdateEntity('selection');
          resetEntity('selection', selectionId);
          resetEntity('season', seasonId);

          if (updateAction === updateEmailSeason) {
            loadEmailBySeason(seasonId, ['masterTemplate', 'template']);
          }

          this.setState({ editing: false, mode: 'show', isSubmitting: false });

          this.props.navigate(
            Routing.generate('campaign_show', { id: campaignId }),
          );
        } else {
          this.setState({ isSubmitting: false });
        }
      },
      () => {
        this.setState({ isSubmitting: false });
      },
    );
  };

  handleValidityChange = (valid) => {
    this.setState({ valid: valid });
  };

  onTouchEdit = (season) => {
    const { campaignId } = this.props;

    this.props.navigate(
      Routing.generate('campaign_season_edit', {
        campaignId: campaignId,
        seasonType: season.type,
        seasonId: season.id,
      }),
    );
  };

  onTouchCancel = () => {
    this.props.navigate(
      Routing.generate('campaign_show', { id: this.props.campaignId }),
    );
  };

  renderActionbar = () => {
    const { seasons, seasonId } = this.props;
    const { adding, editing, valid } = this.state;

    if (adding || editing) {
      return (
        <>
          <Tooltip title="Save">
            <span>
              <IconButton
                aria-label="Save"
                onClick={this.submit}
                disabled={!valid}
                size="large"
              >
                <ContentSave />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton
              aria-label="Cancel"
              onClick={this.onTouchCancel}
              size="large"
            >
              <NavigationCancel />
            </IconButton>
          </Tooltip>
        </>
      );
    }

    const season = seasons[seasonId];

    if (season) {
      return (
        <>
          <Tooltip title="Edit">
            <IconButton
              aria-label={<Translate>edit</Translate>}
              onClick={this.onTouchEdit}
              size="large"
            >
              <EditorInsertLink />
            </IconButton>
          </Tooltip>
          <Tooltip title="Selection">
            <IconButton
              aria-label={<Translate>Selection</Translate>}
              target="_blank"
              href={`${Routing.generate('start', null, true).replace(
                /-v3/,
                '',
              )}#${Routing.generate('selection_show', {
                id: season.selection,
              })}`}
              size="large"
            >
              <AvPlaylistAddCheck />
            </IconButton>
          </Tooltip>
        </>
      );
    }

    return null;
  };

  renderContent() {
    const { campaignId, seasonType, seasonId } = this.props;
    const { mode, isSubmitting } = this.state;

    let child = <Translate>No entries found.</Translate>;

    if (seasonType === 'email' && (mode === 'editing' || mode === 'adding')) {
      child = (
        <ErrorBoundary>
          <EmailEdit
            onMount={this.handleFormMount}
            onCancel={this.onTouchCancel}
            onSubmit={this.handleSubmit}
            isSubmitting={isSubmitting}
            onValidityChange={this.handleValidityChange}
            campaignId={campaignId}
            seasonType={seasonType}
            seasonId={seasonId}
          />
        </ErrorBoundary>
      );
    }

    if (seasonType === 'sms' && (mode === 'editing' || mode === 'adding')) {
      child = (
        <ErrorBoundary>
          <SmsEdit
            onMount={this.handleFormMount}
            onCancel={this.onTouchCancel}
            onSubmit={this.handleSubmit}
            isSubmitting={isSubmitting}
            onValidityChange={this.handleValidityChange}
            campaignId={campaignId}
            seasonType={seasonType}
            seasonId={seasonId}
          />
        </ErrorBoundary>
      );
    }

    return <Paper>{child}</Paper>;
  }

  render() {
    const { campaigns, campaignId } = this.props;

    if (isEmpty(campaigns) || isEmpty(campaigns[campaignId])) {
      return <div>laden...</div>;
    }

    return (
      <>
        <ToolbarRoot>
          <Title>
            <BreadcrumbsNav
              items={[
                { link: '/#/campaign', text: 'Kampagnen' },
                {
                  link: '/#/campaign/' + campaignId + '/show',
                  text: campaigns[campaignId].primaryText,
                },
              ]}
            />
          </Title>
          <Spacer />
        </ToolbarRoot>
        {this.renderContent()}
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getSeasonsByType = makeCampaignSeasonsByType();

  return (state, props) => {
    const {
      entities: { campaign, mailing, email, sms },
    } = state;

    let channel = {};

    switch (props.seasonType) {
      case 'email':
        channel = email;
        break;
      case 'mailing':
        channel = mailing;
        break;
      case 'sms':
        channel = sms;
        break;
    }

    return {
      campaigns: campaign,
      seasons: getSeasonsByType(state, props),
      channel: channel,
    };
  };
};

const enhance = compose(
  withRouter,
  connect(makeMapStateToProps, {
    fetchCampaign,
    loadEmailBySeason,
    loadSeasons,
    updateEmailSeason,
    updateSMSSeason: updateSmsSeason,
    resetEntity,
    outdateEntity,
  }),
);

export default enhance(Season);
