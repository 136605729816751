import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ContentSave from '@mui/icons-material/Save';
import EditorModeEdit from '@mui/icons-material/Edit';
import NavigationCancel from '@mui/icons-material/Cancel';
import { connect } from 'react-redux';
import { isEqual, keys } from 'lodash';
import { loadOwner, updateOwner } from './actions';
import Grid from './components/Grid';
import { IconButton, Tooltip, Typography } from '@mui/material';
import ErrorBoundary from '../../components/ErrorBoundary';
import { outdateEntity } from '../../actions';
import {
  ColorActions,
  Spacer,
  Title,
  ToolbarRoot,
} from '../../components/StyledElements/StyledElements';
import Translate from '../../components/service/Translate';
class Owner extends Component {
  static propTypes = {
    loadOwner: PropTypes.func.isRequired,
    updateOwner: PropTypes.func.isRequired,
    owner: PropTypes.object,
    editable: PropTypes.bool,
  };

  state = {
    editing: false,
    valid: false,
    view: 'grid',
    mode: 'show',
    isSubmitting: false,
  };

  componentDidMount = () => {
    const { sortColumns, sortOrders } = this.state;

    this.props.outdateEntity('owner');
    this.props.loadOwner(sortColumns, sortOrders);
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.owner, this.props.owner) ||
      !isEqual(nextState, this.state)
    );
  };

  handleFormMount = (form) => {
    this.form = form;
  };

  handleSubmit = (form) => {
    const { updateOwner, owner, outdateEntity, loadOwner } = this.props;
    const { sortColumns, sortOrders } = this.state;

    this.setState({ valid: false, isSubmitting: true });

    return Promise.all([updateOwner(form, owner.id)]).then(
      (values) => {
        if (!(values && values[0] && values[0].error)) {
          outdateEntity('owner');
          loadOwner(sortColumns, sortOrders);
          this.setState({ editing: false, mode: 'show', isSubmitting: false });
        }
        this.setState({ isSubmitting: false });
      },
      () => {
        this.setState({ isSubmitting: false });
      },
    );
  };

  submit = () => {
    this.form.submit();
  };

  handleValidityChange = (valid) => {
    this.setState({ valid: valid });
  };

  onTouchEdit = () => {
    this.setState({ editing: true, valid: false, mode: 'editing' });
  };

  onTouchCancel = () => {
    this.setState({ editing: false, valid: false, mode: 'show' });
  };

  submitMyForm = null;

  handleSubmitMyForm = (e) => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };

  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm;
  };

  renderActionbar = () => {
    const { editing, isSubmitting } = this.state;

    if (editing) {
      return (
        <>
          <Tooltip title={<Translate>Save</Translate>}>
            <span>
              <IconButton
                aria-label="Save"
                onClick={this.handleSubmitMyForm}
                disabled={isSubmitting}
                color="primary"
                size="large"
              >
                <ContentSave />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={<Translate>Cancel</Translate>}>
            <IconButton
              aria-label="Cancel"
              onClick={this.onTouchCancel}
              size="large"
            >
              <NavigationCancel />
            </IconButton>
          </Tooltip>
        </>
      );
    }

    return (
      <>
        <Tooltip title={<Translate>Edit</Translate>}>
          <span>
            <IconButton
              aria-label="Edit"
              onClick={this.onTouchEdit}
              size="large"
            >
              <EditorModeEdit />
            </IconButton>
          </span>
        </Tooltip>
      </>
    );
  };

  renderContent() {
    const { owner } = this.props;
    const { mode } = this.state;

    return (
      <ErrorBoundary>
        <Grid
          owner={owner}
          bindSubmitForm={this.bindSubmitForm}
          mode={mode}
          onFormMount={this.handleFormMount}
          onSubmit={this.handleSubmit}
          onValidityChange={this.handleValidityChange}
        />
      </ErrorBoundary>
    );
  }

  render() {
    const { owner } = this.props;

    if (!owner) {
      return null;
    }

    return (
      <>
        <ToolbarRoot>
          <Title>
            <Typography variant="h5" id="tableTitle">
              {`Autohaus ${owner.primaryText}`}
            </Typography>
          </Title>
          <Spacer />
          <ColorActions>{this.renderActionbar()}</ColorActions>
        </ToolbarRoot>
        {this.renderContent()}
      </>
    );
  }
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state, ownProps) {
  const {
    entities: { owner },
    forms: { owner: forms },
  } = state;

  return {
    owner: owner ? owner[keys(owner)[0]] : null,
    editable: !!forms,
  };
}

export default connect(mapStateToProps, {
  loadOwner,
  updateOwner,
  outdateEntity,
})(Owner);
