import React from 'react';
import { Gauge, gaugeClasses, SparkLineChart } from '@mui/x-charts';
import {
  Paper,
  Typography,
  Box,
  Table,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  TypeData,
  Interaction as InteractionType,
} from '../../StatisticCampaignTypes';
import InteractionDetail from './InteractionDetail';
import Translate, {
  translateValue,
} from '../../../../components/service/Translate';
import { useAppSelector } from '../../../../hooks';

type InteractionProps = {
  title: string;
  gaugeTitle: string;
  dataset: InteractionType;
};

export default function Interaction({
  title,
  gaugeTitle,
  dataset,
}: InteractionProps) {
  const { data, percentage, absoluteValue } = dataset;
  const theme = useTheme();
  const language = useAppSelector((state) => state.entities.staff.language);
  const percentageAsString =
    percentage !== null
      ? `${percentage.toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} %`
      : '';

  return (
    <Paper sx={{ width: '100%', p: 5 }}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Box margin={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid
            size={{
              xs: 12,
              sm: 4,
              md: 12,
              lg: 4,
            }}
          >
            <SparkLineChart
              plotType="bar"
              data={data.map((item) => item.count)}
              xAxis={{
                scaleType: 'band',
                data: data.map((item) =>
                  translateValue(item.label, language, 'customerGroups'),
                ),
              }}
              height={150}
              showTooltip
              showHighlight
              colors={[theme.palette.primary.main]}
            />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 8,
              md: 12,
              lg: 8,
            }}
          >
            <Table size="small">
              <TableBody>
                {data.map((item) => (
                  <TableRow
                    key={`${title}_${item.label}`}
                    sx={{ 'td, th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Translate context="customerGroups">
                          {item.label}
                        </Translate>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      {item.count.toLocaleString('de-DE')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Box>
      <Typography variant="h5" gutterBottom>
        {gaugeTitle}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Gauge
          width={250}
          height={100}
          startAngle={-90}
          endAngle={90}
          text={percentageAsString}
          value={percentage}
          sx={(theme) => ({
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: 20,
              transform: 'translate(0px, -25px)',
            },
            [`& .${gaugeClasses.valueArc}`]: {
              fill: theme.palette.primary,
            },
            [`& .${gaugeClasses.referenceArc}`]: {
              fill: theme.palette.text.disabled,
            },
          })}
        />
      </Box>
      <Box sx={{ p: 2 }}>
        {data.map(
          (data: TypeData): React.ReactNode => (
            <InteractionDetail
              key={`${title}_${data.label}`}
              total={absoluteValue}
              count={data.count}
              label={data.label}
            />
          ),
        )}
      </Box>
    </Paper>
  );
}
