import {
  EntityId,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { normalize, schema } from 'normalizr';
import { camelizeKeys } from 'humps';
import {
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { Schemas } from '../../schemas';
import { MailingModule } from './MailingModuleType';
import type { RootState } from '../../reducers';
import { Season } from '../Season/SeasonType';
import { GridParams } from '../../components/DataGrid/DataGridTypes';
import { convertGridParamsToRequestParams } from '../../components/DataGrid/EeloyDataGridUtils';
import { Campaign } from '../Campaign/CampaignType';

const mailingModuleAdapter = createEntityAdapter<MailingModule>({
  sortComparer: (a, b) => a.id - b.id,
});

export const fetchAllMailingModules = createAsyncThunk(
  'mailingModule/fetchAll',
  async (data: { gridParams?: GridParams } = {}) => {
    const { gridParams } = data;

    const response = await axios.get(
      Routing.generate('mailingmodule_index', null, true),
      {
        withCredentials: true,
        params: { ...convertGridParamsToRequestParams(gridParams) },
      },
    );
    const normalized = normalize<
      schema.Array<MailingModule>,
      {
        mailingModule: Record<string, MailingModule>;
        season: Record<string, Season>;
        campaign: Record<string, Campaign>;
      }
    >(camelizeKeys(response.data.data), Schemas.MAILINGMODULES);

    return {
      entities: normalized.entities,
      pagination: response.data.pagination,
      paginationModel: gridParams?.paginationModel,
      resultset: normalized.result,
      sortModel: gridParams?.sortModel,
      filterModel: gridParams?.filterModel,
    };
  },
);

export const fetchMailingModuleById = createAsyncThunk(
  'mailingModule/fetchById',
  async (id: EntityId | null) => {
    const response = await axios.get(
      Routing.generate('mailingmodule_show', { id }, true),
      { withCredentials: true },
    );
    const normalized = normalize<
      schema.Entity<MailingModule>,
      {
        mailingModule: Record<string, MailingModule>;
        season: Record<string, Season>;
      }
    >(camelizeKeys(response.data.data), Schemas.MAILINGMODULE);

    return { entities: normalized.entities };
  },
);

export const fetchToken = createAsyncThunk('mailingModule/token', async () => {
  const response = await axios.get(
    Routing.generate('mailingmodule_token', null, true),
    { withCredentials: true },
  );

  return response.data;
});

export const createMailingModule = createAsyncThunk(
  'mailingModule/create',
  async ({ data }: { data: FormData }) => {
    const response = await axios.post(
      Routing.generate('mailingmodule_create', null, true),
      data,
      { withCredentials: true },
    );

    return {
      statusCode: response.status,
      createdId: response.data.id,
      location: response.headers.location,
      message: response.data.message,
    };
  },
);

export const updateMailingModule = createAsyncThunk(
  'mailingModule/write',
  async ({ data, id }: { data: FormData; id: EntityId }) => {
    const response = await axios.post(
      Routing.generate('mailingmodule_update', { id }, true),
      data,
      { withCredentials: true },
    );
    return { statusCode: response.status, message: response.data.message };
  },
);

export const deleteMailingModule = createAsyncThunk(
  'mailingModule/delete',
  async ({ id, deleteToken }: { id: number; deleteToken: string }) => {
    const response = await axios.delete(
      Routing.generate('mailingmodule_delete', { id, deleteToken }, true),
      { withCredentials: true },
    );

    return { id, statusCode: response.status, message: response.data.message };
  },
);

interface SliceState {
  updateToken?: string | null;
  resultset?: number[];
  paginationModel?: GridPaginationModel;
  sortModel?: GridSortModel;
  filterModel?: GridFilterModel;
  pagination?: {
    total: number;
    limit: number;
    offset: number;
  };
}

const initialState: SliceState = {
  updateToken: null,
};

const mailingModuleSlice = createSlice({
  name: 'mailingModule',
  initialState: mailingModuleAdapter.getInitialState(initialState),
  reducers: {
    removeUpdateToken(state) {
      state.updateToken = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllMailingModules.fulfilled, (state, action) => {
      const { mailingModule } = action.payload.entities;

      if (mailingModule) {
        mailingModuleAdapter.upsertMany(state, mailingModule);
      }

      state.paginationModel = action.payload.paginationModel;
      state.filterModel = action.payload.filterModel;
      state.sortModel = action.payload.sortModel;
      state.resultset = action.payload.resultset;
      state.pagination = action.payload.pagination;
    });
    builder.addCase(fetchMailingModuleById.fulfilled, (state, action) => {
      const { mailingModule } = action.payload.entities;

      if (mailingModule) {
        mailingModuleAdapter.upsertMany(state, mailingModule);
      }

      state.updateToken = null;
    });
    builder.addCase(fetchToken.fulfilled, (state, action) => {
      state.updateToken = action.payload.results.updateToken;
    });
    builder.addCase(fetchToken.rejected, (state) => {
      state.updateToken = null;
    });
    builder.addCase(updateMailingModule.fulfilled, (state) => {
      state.updateToken = null;
    });
    builder.addCase(deleteMailingModule.fulfilled, (state, action) => {
      mailingModuleAdapter.removeOne(state, action.payload.id);

      if (state.resultset) {
        const index: number = state.resultset.findIndex(
          (item) => item === action.payload.id,
        );

        if (index > -1) {
          state.resultset.splice(index, 1);
        }
      }
    });
  },
});

export const {
  selectAll: selectAllMailingModules,
  selectById: selectMailingModuleById,
  selectIds: selectMailingModulesByIds,
} = mailingModuleAdapter.getSelectors<RootState>(
  (state) => state.mailingModule,
);

export const { removeUpdateToken } = mailingModuleSlice.actions;

export default mailingModuleSlice.reducer;
