import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import Form from './Form';
import { initCustomerMemo } from '../forms';
import TextField from '@mui/material/TextField';
import Translate from './service/Translate';
import { Input } from '@mui/material';
import { HiddenInput } from './StyledElements/StyledElements';

class CustomerListCustomerMemo extends Component {
  static propTypes = {
    initCustomerMemo: PropTypes.func.isRequired,
    customerId: PropTypes.number.isRequired,
    onMount: PropTypes.func.isRequired,
    customers: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func.isRequired,
  };

  state = {
    valid: false,
  };

  componentDidMount = () => {
    //this.props.initCustomerMemo();
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(nextProps, nextState) {
    if (!isEqual(this.props.customerId, nextProps.customerId)) {
      this.setState({ valid: false });
    }
  }

  renderFieldset = (props) => {
    const { bindSubmitForm } = this.props;
    const { values, handleChange, handleBlur } = props;

    bindSubmitForm(props.submitForm);

    if (values) {
      return (
        <>
          <HiddenInput
            type="hidden"
            name="updateToken"
            value={values.updateToken ? values.updateToken : ''}
          />
          <HiddenInput
            type="hidden"
            name="id"
            defaultValue={values.id ? values.id : ''}
          />
          <TextField
            id="memo"
            label={<Translate>Notiz</Translate>}
            name="memo"
            defaultValue={values.memo}
            onChange={handleChange}
            onBlur={handleBlur}
            /*helperText={touched.name ? errors.name : ''}
                        error={touched.name && Boolean(errors.name)}*/
            fullWidth
          />
        </>
      );
    }

    return null;
  };

  render() {
    const { customer, onMount, onValidityChange, onSubmit, token, customerId } =
      this.props;

    if (customer) {
      return (
        <Form
          disableToolbar={true}
          onMount={onMount}
          initialValues={{
            updateToken: token,
            memo: customer.memo,
            id: customerId,
          }}
          values={customer}
          onValidityChange={onValidityChange}
          onSubmit={onSubmit}
          name="customer_memo"
          renderFieldset={this.renderFieldset}
        />
      );
    }

    return null;
  }
}

function mapStateToProps(state, ownProps) {
  const customerId = ownProps.customerId;
  const {
    entities: { customer: customers },
    // eslint-disable-next-line no-unused-vars
    forms: { customer_memo: form },
  } = state;

  return {
    customerId,
    customer: customers ? customers[customerId] : null,
    token: customers ? customers[customerId].updateToken : null,
  };
}

export default connect(mapStateToProps, { initCustomerMemo })(
  CustomerListCustomerMemo,
);
