import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  TextField,
  Typography,
} from '@mui/material';
import { merge, isEmpty, pick } from 'lodash';
import Form from '../../components/Form';
import Translate from '../../components/service/Translate';
import { withRouter } from '../../withRouter';
import * as Yup from 'yup';
import ValidationErrors from '../../ValidationErrors';
import EmailEditor from 'react-email-editor';
import Config from '../../utils/EmailEditor/Config';
import Variables from '../../utils/EmailEditor/Variables';
import { updateTemplate } from './actions';
import Button from '@mui/material/Button';
import RequestPreview from './RequestPreview';
import ErrorBoundary from '../../components/ErrorBoundary';
import ReactTimeout from 'react-timeout';
import { loadEmail } from '../Email/actions';
import { makeEmailById, makeTemplateByEmail } from '../Email/selectors';
import { outdateEntity } from '../../actions';
import { loadMailingModulesByMode } from '../MailingModule/actions';
import { loadModules } from '../EmailModule/actions';
import DisplayConditionDialog from '../Season/components/DisplayConditionDialog';
import LinkTypes from '../../utils/EmailEditor/LinkTypes';
import {
  Actions,
  Title,
  ToolbarRoot,
  MarginButton,
  Spacer,
  HiddenInput,
} from '../../components/StyledElements/StyledElements';
import { compose } from 'redux';
import Grid from '@mui/material/Grid2';
import { withTheme } from '../../withTheme';
import { makeTextModulesBySeasonIdForMergeTags } from '../MailingModule/selectors_deprecated';

const validationSchema = Yup.object().shape({
  addresser: Yup.string().required(ValidationErrors.required),
  subject: Yup.string().required(ValidationErrors.required),
  body: Yup.string().required(ValidationErrors.required),
  txtBody: Yup.string().nullable(),
  embeddedEditor: Yup.bool(),
});

class TemplateEditor extends Component {
  editor = createRef();

  constructor(props) {
    super(props);
    this.isEditorLoaded = false;
    this.isComponentMounted = false;
    this.formikProps = null;
    this.submitMyForm = null;
    this.timeoutId = null;
  }

  static propTypes = {
    loadTemplate: PropTypes.func,
    updateTemplate: PropTypes.func,
    emailTemplate: PropTypes.object,
    templateId: PropTypes.number,
  };

  static defaultProps = {
    values: {},
  };

  state = {
    embeddedEditorSwitchTouched: false,
    submitting: false,
    openRequestPreview: false,
    edited: false,
    showApprovedModal: false,
    conditionsDialog: false,
    modulesLoaded: false,
    templateLoaded: false,
  };

  componentDidMount = () => {
    this.props.loadEmail(this.props?.match?.params?.emailId);
    this.props.loadMailingModulesByMode('text');
    Promise.all([this.props.loadModules()]).then(() => {
      this.setState({ modulesLoaded: true });
    });

    this.isComponentMounted = true;
    this.props.setTimeout(this.loadTemplate, 1);
    this.initInterval();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.showApprovedModal || this.state.openRequestPreview) {
      this.props.clearTimeout(this.timeoutId);
    }
    if (
      (prevState.showApprovedModal && !this.state.showApprovedModal) ||
      (prevState.openRequestPreview && !this.state.openRequestPreview)
    ) {
      this.initInterval();
    }
  }

  initInterval() {
    const { setTimeout } = this.props;

    this.timeoutId = setTimeout(
      function () {
        this.props.outdateEntity('email');
        Promise.all([
          this.props.loadEmail(this.props?.match?.params?.emailId),
        ]).then(
          () => {
            this.initInterval();
          },
          () => {
            this.initInterval();
          },
        );
      }.bind(this),
      20000,
    );
  }

  renderEditor = () => {
    const { modules } = this.props;

    let blocks = Object.keys(modules).map((key) => {
      return typeof modules[key].blockDesign === 'string' ||
        modules[key].blockDesign instanceof String
        ? JSON.parse(modules[key].blockDesign)
        : modules[key].blockDesign;
    });

    return (
      <>
        <Grid size={12}>
          <React.StrictMode>
            <EmailEditor
              ref={this.editor}
              options={merge(
                {},
                Config.base,
                { blocks: blocks },
                { mergeTagsConfig: { sort: false } } /*Config.layout*/,
              )}
              onReady={this.onEmailEditorLoad}
              minHeight={'1000px'}
            />
          </React.StrictMode>
        </Grid>
      </>
    );
  };

  setMergeTags = () => {
    const { mailingModules } = this.props;

    let newTags = {
      mailing_modules: {
        name: 'Bausteine',
        mergeTags: {},
      },
    };

    let mergeTags = {};

    Object.values(mailingModules).map((module) => {
      let tag = {};
      let tagString = module.tag ? ',"' + module.tag + '"' : ',' + module.tag;
      let affiliateString = module.affiliate
        ? ', customer.affiliate.interfaceId'
        : ',' + module.affiliate;

      tag['name'] = module.position;
      tag['value'] =
        '{{textmodule("' +
        module.position +
        '"' +
        tagString +
        affiliateString +
        ')}}';
      tag['sample'] = module.boilerplate ? module.boilerplate : '';

      mergeTags[module.position] = tag;
    });

    newTags.mailing_modules.mergeTags = Object.keys(mergeTags)
      .sort()
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: mergeTags[key],
        }),
        {},
      );

    let finalTags = merge({}, Variables, newTags);

    this.editor.current.editor.setMergeTags(finalTags);
  };

  loadTemplate = () => {
    const { emailTemplate, templateId } = this.props;
    const { templateLoaded } = this.state;

    if (
      !this.isEditorLoaded ||
      !this.isComponentMounted ||
      !this.editor.current.editor ||
      (templateId && isEmpty(emailTemplate))
    ) {
      return;
    }

    if (templateLoaded) {
      return;
    }

    this.editor.current.editor.addEventListener('design:updated', () => {
      this.setState({ edited: true });
      this.editor.current.editor.exportHtml((editorData) => {
        const { design, html } = editorData;
        this.formikProps.setFieldValue('jsonBody', JSON.stringify(design));
        this.formikProps.setFieldValue('body', html);
      });
    });

    this.editor.current.editor.addEventListener('design:loaded', () => {
      this.editor.current.editor.exportHtml((editorData) => {
        const { design, html } = editorData;
        this.setState({ jsonBody: JSON.stringify(design) });
        if (
          this.formikProps.values &&
          this.formikProps.values.masterTemplate &&
          this.formikProps.values.masterTemplate.embeddedEditor
        ) {
          this.formikProps.setFieldValue('jsonBody', JSON.stringify(design));
          this.formikProps.setFieldValue('body', html);
        }
      });
    });

    this.editor.current.editor.registerCallback(
      'displayCondition',
      (data, done) => {
        this.setState({ conditionsDialog: true });
        this.done = done;
      },
    );

    this.setMergeTags();
    this.editor.current.editor.setLinkTypes(LinkTypes);

    if (
      !isEmpty(emailTemplate) &&
      emailTemplate.jsonBody &&
      emailTemplate.body &&
      emailTemplate.embeddedEditor
    ) {
      const design = JSON.parse(emailTemplate.jsonBody);
      this.editor.current.editor.loadDesign(design);
      this.formikProps.setFieldValue('jsonBody', emailTemplate.jsonBody);
    }
    this.setState({ templateLoaded: true });
  };

  onEmailEditorLoad = () => {
    this.isEditorLoaded = true;
    this.props.setTimeout(this.loadTemplate, 1);
  };

  handleSubmit = (form) => {
    const { updateTemplate, templateId } = this.props;

    form.set('status', 'preparation');

    const promises = [updateTemplate(form, templateId)];
    this.setState({ submitting: true });
    return Promise.all(promises).then(
      (values) => {
        if (!(values && values[0] && values[0].error)) {
          this.setState({ edited: false, showApprovedModal: false });
        }
        this.setState({ submitting: false });
      },
      () => {
        this.setState({ submitting: false });
      },
    );
  };

  showRequestPreview = () => {
    this.setState({ openRequestPreview: true });
  };

  closeRequestPreview = () => {
    this.setState({ openRequestPreview: false });
  };

  handleSubmitMyForm = (e) => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };
  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm;
  };

  renderPreviewButton = () => {
    const { email } = this.props;
    const { submitting, edited } = this.state;

    if (email.hasAccount) {
      return (
        <MarginButton
          onClick={this.showRequestPreview}
          variant="contained"
          aria-label="Save"
          type="submit"
          color="primary"
          disabled={submitting || edited}
        >
          <Translate>Request Preview</Translate>
        </MarginButton>
      );
    }

    return (
      <MarginButton
        onClick={this.showRequestPreview}
        variant="contained"
        aria-label="Save"
        type="submit"
        color="primary"
        disabled={true}
      >
        <Translate>Request Preview</Translate>
      </MarginButton>
    );
  };

  renderToolbar = (headline, props) => {
    const { emailTemplate, theme } = this.props;
    const { submitting } = this.state;
    const approved =
      emailTemplate.approved &&
      emailTemplate.approved.length > 0 &&
      emailTemplate.status &&
      emailTemplate.status === 'approved';
    let options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const approvedDate = new Intl.DateTimeFormat(
      theme.locale.de,
      options,
    ).format(new Date());

    return (
      <ToolbarRoot>
        <Title>
          <Typography variant="h5" id="tableTitle">
            {headline}
          </Typography>
        </Title>
        <Spacer />
        <Actions>
          <div>
            {this.renderPreviewButton()}
            <MarginButton
              onClick={
                approved
                  ? (e) => {
                      e.preventDefault();
                      this.editor.current.editor.exportHtml((editorData) => {
                        const { design, html } = editorData;
                        props.setFieldValue('jsonBody', JSON.stringify(design));
                        props.setFieldValue('body', html);
                        props.setFieldValue('templateApproved', '');
                      });
                      this.setState({
                        showApprovedModal: true,
                        saveAndApprove: false,
                      });
                    }
                  : (e) => {
                      e.preventDefault();
                      this.editor.current.editor.exportHtml((editorData) => {
                        const { design, html } = editorData;
                        props.setFieldValue('jsonBody', JSON.stringify(design));
                        props.setFieldValue('body', html);
                        props.setFieldValue('templateApproved', '');
                        this.handleSubmitMyForm(e);
                      });
                    }
              }
              variant="contained"
              aria-label="Save"
              type="submit"
              color="primary"
              disabled={submitting}
            >
              <Translate>Save</Translate>
            </MarginButton>
            <MarginButton
              onClick={
                approved
                  ? (e) => {
                      e.preventDefault();
                      this.editor.current.editor.exportHtml((editorData) => {
                        const { design, html } = editorData;
                        props.setFieldValue('jsonBody', JSON.stringify(design));
                        props.setFieldValue('body', html);
                        props.setFieldValue('templateApproved', approvedDate);
                      });
                      this.setState({
                        showApprovedModal: true,
                        saveAndApprove: true,
                      });
                    }
                  : (e) => {
                      e.preventDefault();
                      this.editor.current.editor.exportHtml((editorData) => {
                        const { design, html } = editorData;
                        props.setFieldValue('jsonBody', JSON.stringify(design));
                        props.setFieldValue('body', html);
                        props.setFieldValue('templateApproved', approvedDate);
                        this.handleSubmitMyForm(e);
                      });
                    }
              }
              variant="contained"
              aria-label="Save"
              type="submit"
              color="primary"
              disabled={submitting}
            >
              <Translate>Save & Approve</Translate>
            </MarginButton>
          </div>
        </Actions>
      </ToolbarRoot>
    );
  };

  renderFieldset = (props) => {
    const { values, handleChange, handleBlur, errors, touched } = props;
    const { emailTemplate } = this.props;
    this.formikProps = props;
    this.bindSubmitForm(props.submitForm);
    let headline = emailTemplate.name;

    return (
      <>
        {this.renderToolbar(headline, props)}
        <HiddenInput
          type="hidden"
          name="updateToken"
          defaultValue={values.updateToken ? values.updateToken : ''}
        />
        <Grid container>
          <Grid size={12}>
            <h4>
              <Translate>Master template</Translate>
            </h4>
          </Grid>
          <Grid size={12}>
            <TextField
              id={'addresser'}
              label={<Translate>Addresser</Translate>}
              name={'addresser'}
              defaultValue={values.addresser ? values.addresser : ''}
              onChange={(e) => {
                handleChange(e);
                this.setState({ edited: true });
              }}
              onBlur={handleBlur}
              helperText={touched.addresser ? errors.addresser : ''}
              error={touched.addresser && Boolean(errors.addresser)}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              id={'subject'}
              label={<Translate>Subject</Translate>}
              name={'subject'}
              defaultValue={values.subject ? values.subject : ''}
              onChange={(e) => {
                handleChange(e);
                this.setState({ edited: true });
              }}
              onBlur={handleBlur}
              helperText={touched.subject ? errors.subject : ''}
              error={touched.subject && Boolean(errors.subject)}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              id={'txtBody'}
              label={<Translate>Body (text)</Translate>}
              name={'txtBody'}
              defaultValue={values.txtBody ? values.txtBody : ''}
              onChange={(e) => {
                handleChange(e);
                this.setState({ edited: true });
              }}
              onBlur={handleBlur}
              helperText={touched.txtBody ? errors.txtBody : ''}
              error={touched.txtBody && Boolean(errors.txtBody)}
              multiline
            />
          </Grid>
          {this.renderEditor(props)}
        </Grid>
      </>
    );
  };

  addDisplayCondition = (condition) => {
    this.done(condition);
    this.cancelDisplayCondition();
  };

  cancelDisplayCondition = () => {
    this.setState({ conditionsDialog: false });
  };

  renderForm = () => {
    const { emailTemplate } = this.props;

    if (isEmpty(emailTemplate)) {
      return null;
    }

    let initialValues = emailTemplate;

    return (
      <>
        <Form
          validationSchema={validationSchema}
          disableToolbar={true}
          initialValues={initialValues}
          values={initialValues}
          onSubmit={this.handleSubmit}
          name={'editor'}
          renderFieldset={this.renderFieldset}
        />
      </>
    );
  };

  openApprovedModal = () => {
    this.setState({ showApprovedModal: true });
  };

  closeApprovedModal = () => {
    this.setState({ showApprovedModal: false });
  };

  renderApprovedModal = () => {
    const { showApprovedModal, submitting, saveAndApprove } = this.state;

    return (
      <ErrorBoundary>
        <Dialog
          disableEscapeKeyDown
          open={showApprovedModal}
          onClose={this.closeApprovedModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Translate>Reset Campaign</Translate>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Translate>
                Are you sure you want to revoke the approval? Until it is
                approved again, the campaign will be suspended.
              </Translate>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={submitting}
              onClick={this.closeApprovedModal}
              color="primary"
            >
              <Translate>Cancel</Translate>
            </Button>
            <Button
              disabled={submitting}
              onClick={this.handleSubmitMyForm}
              color="primary"
            >
              {saveAndApprove ? (
                <Translate>Save & Approve</Translate>
              ) : (
                <Translate>Save</Translate>
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </ErrorBoundary>
    );
  };

  render() {
    const { emailId, email, emailTemplate } = this.props;
    const { openRequestPreview, modulesLoaded } = this.state;

    if (isEmpty(emailTemplate) || isEmpty(email) || !modulesLoaded) {
      return null;
    }

    return (
      <>
        {this.renderForm()}
        {this.state.conditionsDialog ? (
          <DisplayConditionDialog
            addDisplayCondition={this.addDisplayCondition}
            cancelDisplayCondition={this.cancelDisplayCondition}
          />
        ) : null}
        <RequestPreview
          open={openRequestPreview}
          emailId={emailId}
          onClose={this.closeRequestPreview}
        />
        {this.renderApprovedModal()}
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getTemplateByEmail = makeTemplateByEmail();
  const getEmailById = makeEmailById();
  const getTextModules = makeTextModulesBySeasonIdForMergeTags();

  return (state, props) => {
    const { emailId } = props.match.params;
    const {
      entities: { emailModule },
      resultsets: { emailModule: resultset },
    } = state;

    const email = getEmailById(state, props);
    const emailTemplate = getTemplateByEmail(state, props);
    const modules = getTextModules(state, props);

    return {
      modules: !isEmpty(emailModule) ? pick(emailModule, resultset) : {},
      emailId: emailId,
      emailTemplate: emailTemplate,
      mailingModules: modules,
      email: email,
      templateId: !isEmpty(email) ? email.template : null,
    };
  };
};

const enhance = compose(
  withTheme,
  withRouter,
  connect(makeMapStateToProps, {
    loadMailingModulesByMode,
    loadModules,
    outdateEntity,
    loadEmail,
    updateTemplate,
  }),
  ReactTimeout,
);

export default enhance(TemplateEditor);
