import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Toolbar, Typography, IconButton, Tooltip } from '@mui/material';

import ContentSave from '@mui/icons-material/Save';
import NavigationCancel from '@mui/icons-material/Cancel';
import ActionDelete from '@mui/icons-material/Delete';
import EditorModeEdit from '@mui/icons-material/Edit';
import ContentAdd from '@mui/icons-material/Add';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ListIcon from '@mui/icons-material/List';
import { styled } from '@mui/system';
import { ColorActions, Spacer, Title } from './StyledElements/StyledElements';

const StyledToolbar = styled(Toolbar)({
  position: 'sticky',
  top: 50,
});

class ToolBar extends Component {
  static propTypes = {
    docked: PropTypes.bool,
    adding: PropTypes.bool,
    editing: PropTypes.bool,
    deleting: PropTypes.bool,
    valid: PropTypes.bool,
    deletable: PropTypes.bool,
    current: PropTypes.object,
    onEdit: PropTypes.func,
    onAdd: PropTypes.func,
    onCancel: PropTypes.func,
    onDelete: PropTypes.func,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
    view: PropTypes.string,
    onList: PropTypes.func,
    onGrid: PropTypes.func,
    renderMenu: PropTypes.func,
    renderTitle: PropTypes.func,
  };

  static defaultProps = {
    docked: false,
    adding: false,
    editing: false,
    deleting: false,
    valid: false,
  };

  state = {
    view: 'list',
    mode: 'show',
  };

  renderMenuItems = () => {
    const {
      deletable,
      adding,
      editing,
      valid,
      current,
      onEdit,
      onAdd,
      onCancel,
      onDelete,
      onSubmit,
      view,
      onList,
      onGrid,
      renderMenu,
    } = this.props;

    if (renderMenu) {
      return renderMenu();
    }

    if (adding || editing) {
      return (
        <>
          <Tooltip title="Save">
            <span>
              <IconButton
                color="inherit"
                aria-label="Save"
                onClick={onSubmit}
                disabled={!valid}
                size="large"
              >
                <ContentSave />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Cancel">
            <span>
              <IconButton
                color="inherit"
                aria-label="Cancel"
                onClick={onCancel}
                size="large"
              >
                <NavigationCancel />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Delete">
            <span>
              <IconButton
                color="inherit"
                aria-label="Delete"
                disabled={!deletable}
                onClick={onDelete}
                size="large"
              >
                <ActionDelete />
              </IconButton>
            </span>
          </Tooltip>
        </>
      );
    }

    return (
      <>
        <Tooltip title="Edit">
          <span>
            <IconButton
              color="inherit"
              aria-label="Edit"
              disabled={!current}
              onClick={onEdit}
              size="large"
            >
              <EditorModeEdit />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Add">
          <IconButton
            color="inherit"
            aria-label="Add"
            onClick={onAdd}
            size="large"
          >
            <ContentAdd />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <span>
            <IconButton
              color="inherit"
              aria-label="Delete"
              disabled={!(current && deletable)}
              onClick={onDelete}
              size="large"
            >
              <ActionDelete />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="View">
          {view === 'grid' ? (
            <IconButton color="inherit" onClick={onList} size="large">
              <ViewCompactIcon />
            </IconButton>
          ) : view === 'list' ? (
            <IconButton color="inherit" onClick={onGrid} size="large">
              <ListIcon />
            </IconButton>
          ) : (
            ''
          )}
        </Tooltip>
      </>
    );
  };

  render() {
    const { current, title, renderTitle } = this.props;

    return (
      <div>
        <StyledToolbar>
          <Title>
            <Typography variant="h5" id="tableTitle">
              {renderTitle
                ? renderTitle()
                : current
                  ? title + ' ' + current.primaryText
                  : title}
            </Typography>
          </Title>
          <Spacer />
          <ColorActions>{this.renderMenuItems()}</ColorActions>
        </StyledToolbar>
      </div>
    );
  }
}

export default ToolBar;
