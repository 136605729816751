import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Translate from '../../../components/service/Translate';
import {
  Table,
  TableData,
} from '../../../components/StyledElements/StyledElements';
import { Box } from '@mui/material';

class Show extends Component {
  static propTypes = {
    letterId: PropTypes.number.isRequired,
    letter: PropTypes.object,
  };

  render() {
    const { letter } = this.props;

    if (!letter) {
      return null;
    }

    return (
      <Box sx={{ pt: 3 }}>
        <Table width="100%" verticalAlign="top">
          <tbody>
            <tr>
              <TableData
                width="15%"
                color="secondaryTextColor"
                verticalAlign="top"
              >
                <Translate>Name</Translate>
              </TableData>
              <TableData width="35%">{letter.name}</TableData>
              <TableData
                width="15%"
                color="secondaryTextColor"
                verticalAlign="top"
              >
                <Translate>Config key</Translate>
              </TableData>
              <TableData width="35%">{letter.config}</TableData>
            </tr>
            <tr>
              <TableData
                sx={{
                  color: 'secondaryTextColor',
                  width: 200,
                  pt: 3,
                  fontWeight: 500,
                }}
                colSpan="4"
              >
                <Translate>Address window</Translate>
              </TableData>
            </tr>
            <tr>
              <TableData
                width="15%"
                color="secondaryTextColor"
                verticalAlign="top"
              >
                <Translate>Sender</Translate>
              </TableData>
              <TableData width="35%" colSpan="3">
                {letter.sender}
              </TableData>
            </tr>
            <tr>
              <TableData
                width="15%"
                color="secondaryTextColor"
                verticalAlign="top"
              >
                <Translate>Premium tag</Translate>
              </TableData>
              <TableData width="35%" colSpan="3">
                {letter.premium}
              </TableData>
            </tr>
            <tr>
              <TableData
                width="15%"
                color="secondaryTextColor"
                verticalAlign="top"
              >
                <Translate>Addressee</Translate>
              </TableData>
              <TableData width="35%" colSpan="3">
                {letter.address}
              </TableData>
            </tr>
            <tr>
              <TableData
                sx={{
                  color: 'secondaryTextColor',
                  width: 200,
                  pt: 3,
                  fontWeight: 500,
                }}
                colSpan="4"
              >
                <Translate>Sidebar</Translate>
              </TableData>
            </tr>
            <tr>
              <TableData
                width="15%"
                color="secondaryTextColor"
                verticalAlign="top"
              >
                <Translate>Headline</Translate>
              </TableData>
              <TableData width="35%" colSpan="3">
                {letter.informationHeadline}
              </TableData>
            </tr>
            <tr>
              <TableData
                width="15%"
                color="secondaryTextColor"
                verticalAlign="top"
              >
                <Translate>Running text</Translate>
              </TableData>
              <TableData width="35%" colSpan="3">
                {letter.informationText}
              </TableData>
            </tr>
            <tr>
              <TableData
                width="15%"
                color="secondaryTextColor"
                verticalAlign="top"
              >
                <Translate>Date</Translate>
              </TableData>
              <TableData width="35%" colSpan="3">
                {letter.informationDate}
              </TableData>
            </tr>
            <tr>
              <TableData
                sx={{
                  color: 'secondaryTextColor',
                  width: 200,
                  pt: 3,
                  fontWeight: 500,
                }}
                colSpan="4"
              >
                <Translate>Footer</Translate>
              </TableData>
            </tr>
            <tr>
              <TableData
                width="15%"
                color="secondaryTextColor"
                verticalAlign="top"
              >
                <Translate>Business</Translate>
              </TableData>
              <TableData width="35%" colSpan="3">
                {letter.business}
              </TableData>
            </tr>
          </tbody>
        </Table>
      </Box>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const letterId = ownProps.letterId;
  const {
    entities: { letter },
  } = state;

  return {
    letter: letter ? letter[letterId] : null,
  };
}

export default connect(mapStateToProps, {})(Show);
