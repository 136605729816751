import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import DeleteDialog from '../../components/DeleteDialog';
import Translate from '../../components/service/Translate';
import ErrorBoundary from '../../components/ErrorBoundary';
import LocationSettingEdit from './components/Edit';
import LocationSettingList from './components/List';
import {
  loadLocationSettings,
  removeLocationSetting,
  updateLocationSetting,
  loadSettings,
  loadNext,
} from './actions';
import { isEqual, isEmpty } from 'lodash';
import { resetEntity, outdateEntity } from '../../actions';
import { compose } from 'redux';
import { Paper } from '@mui/material';

const INITIAL_GRID_STATE = {
  sorting: {
    sortModel: [{ field: 'id', sort: 'asc' }],
  },
};

class LocationSetting extends Component {
  static propTypes = {
    outdateEntity: PropTypes.func.isRequired,
    loadNext: PropTypes.func.isRequired,
    mode: PropTypes.string,
  };

  static defaultProps = {
    mode: 'show',
  };

  state = {
    mode: 'show',
    sortColumns: INITIAL_GRID_STATE.sorting.sortModel.map((val) => val.field),
    sortOrders: INITIAL_GRID_STATE.sorting.sortModel.map((val) => val.sort),
    dataLoading: false,
    initialMode: '',
  };

  componentDidMount = () => {
    if (this.props.mode === 'show') {
      this.loadLocationSettings();
    }
    this.setState({ mode: this.props.mode, initialMode: this.props.mode });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.mode !== this.props.mode) {
      this.setState({ mode: this.props.mode, deleting: false });
    }
    if (
      (this.state.initialMode === 'edit' || this.state.initialMode === 'add') &&
      this.props.mode === 'show'
    ) {
      this.setState({ initialMode: null });
      this.loadLocationSettings(true);
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.mode, this.props.mode) ||
      !isEqual(nextProps.locationSettingId, this.props.locationSettingId) ||
      !isEqual(nextProps.locationSettings, this.props.locationSettings) ||
      !isEqual(nextProps.setting, this.props.setting) ||
      !isEqual(nextState.dataLoading, this.state.dataLoading) ||
      !isEqual(nextState.mode, this.state.mode) ||
      !isEqual(nextState.initialMode, this.state.initialMode) ||
      !isEqual(nextState.current, this.state.current) ||
      !isEqual(nextState.deleting, this.state.deleting)
    );
  };

  loadLocationSettings = (showLoading = true) => {
    const { sortColumns, sortOrders } = this.state;
    showLoading ? this.setState({ dataLoading: true }) : null;
    Promise.all([
      this.props.loadLocationSettings(sortColumns, sortOrders),
      this.props.loadSettings(),
    ]).then(
      () => {
        this.setState({ dataLoading: false });
      },
      () => {
        this.setState({ dataLoading: false });
      },
    );
  };

  loadNext = (
    columns,
    sort,
    join = [],
    offset = null,
    limit = null,
    filter = [],
  ) => {
    return Promise.all([
      this.props.loadNext(columns, sort, offset, limit, join, filter),
    ]);
  };

  handleSubmit = (form) => {
    const promises = [];
    const { updateLocationSetting, locationSettingId } = this.props;

    promises.push(updateLocationSetting(form, locationSettingId));

    return Promise.all(promises).then(
      (values) => {
        if (
          !(
            values &&
            values[0] &&
            values[0].type &&
            values[0].type.match(/_FAILURE/)
          )
        ) {
          this.loadLocationSettings(false);
          this.props.navigate('/location');
        }
      },
      () => {
        this.setState({ valid: false });
      },
    );
  };

  handleSortModelChange = (newSortModel) => {
    this.setState({
      sortColumns: newSortModel?.map((value) => value.field),
      sortOrders: newSortModel?.map((value) => value.sort),
    });
  };

  handleDelete = () => {
    const { locationSettings, resetEntity, removeLocationSetting } = this.props;
    const { current } = this.state;
    let deleteToken = locationSettings[current].deleteToken;

    this.setState({ deleting: true });

    Promise.all([removeLocationSetting(current, deleteToken)]).then(
      () => {
        this.setState({ deleting: false });
        resetEntity('locationsetting', current);
        this.loadLocationSettings(false);
        this.props.navigate('/location');
      },
      () => {
        this.setState({ deleting: false });
      },
    );
  };

  onTouchEdit = (current) => {
    this.props.navigate('/location/setting/edit/' + current);
  };

  onTouchAdd = () => {
    this.props.navigate('/location/setting/add');
  };

  onTouchDelete = (current) => {
    this.setState({ current: current, deleting: true });
  };

  onTouchCancel = () => {
    this.setState({ deleting: false });
    this.props.navigate('/location');
  };

  renderDeleteDialog = () => {
    const { deleting, current } = this.state;
    const { locationSettings } = this.props;

    if (deleting) {
      return (
        <DeleteDialog
          current={locationSettings[current]}
          onCancel={this.onTouchCancel}
          onSubmit={this.handleDelete}
        />
      );
    }

    return null;
  };

  renderContent() {
    const { mode, setting, sortOrders, sortColumns, dataLoading } = this.state;

    let child = null;

    if (dataLoading) {
      child = (
        <Paper>
          <Translate>Data loading...</Translate>
        </Paper>
      );
    } else {
      switch (mode) {
        case 'add':
          child = (
            <ErrorBoundary>
              <LocationSettingEdit
                setting={setting}
                onSubmit={this.handleSubmit}
                onCancel={this.onTouchCancel}
              />
            </ErrorBoundary>
          );
          break;
        case 'edit':
          child = (
            <ErrorBoundary>
              <LocationSettingEdit
                onSubmit={this.handleSubmit}
                onCancel={this.onTouchCancel}
              />
            </ErrorBoundary>
          );
          break;
        case 'show':
        default:
          return (
            <ErrorBoundary>
              <LocationSettingList
                defaultSortColumns={sortColumns}
                defaultSortOrders={sortOrders}
                onSortModelChange={this.handleSortModelChange}
                onEdit={this.onTouchEdit}
                onAdd={this.onTouchAdd}
                onDelete={this.onTouchDelete}
                onLoadNext={this.loadNext}
                initialGridState={INITIAL_GRID_STATE}
              />
            </ErrorBoundary>
          );
      }
    }

    return child;
  }

  render() {
    return (
      <>
        {this.renderContent()}
        {this.renderDeleteDialog()}
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const {
    entities: { locationsetting, setting },
    resultsets: { locationsetting: resultset },
    pagination: { locationsetting: pagination },
  } = state;

  return {
    mode: props?.match?.params?.mode ?? 'show',
    settings: setting,
    locationSettingId: props.match.params.locationSettingId
      ? props.match.params.locationSettingId
      : null,
    locationSettings: !isEmpty(locationsetting) ? locationsetting : {},
    resultset: resultset,
    pagination: pagination,
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    loadLocationSettings,
    loadNext,
    removeLocationSetting,
    updateLocationSetting,
    loadSettings,
    resetEntity,
    outdateEntity,
  }),
);

export default enhance(LocationSetting);
