import React, { Component } from 'react';
import { Input, TextField, Typography } from '@mui/material';
import { connect } from 'react-redux';
import Translate from '../../../components/service/Translate';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import Form from '../../../components/Form';
import { initForm } from '../../Affiliate/actions';
import * as Yup from 'yup';
import ValidationErrors from '../../../ValidationErrors';
import Grid from '@mui/material/Grid2';
import ValidationRules from '../../../ValidationRules';
import { HiddenInput } from '../../../components/StyledElements/StyledElements';

let validationSchema = Yup.object().shape({
  company: Yup.string().required(ValidationErrors.required),
  code: Yup.string().max(3),
  accountHolder: Yup.string().nullable(),
  bank: Yup.string().nullable(),
  iban: Yup.string().nullable(),
  bic: Yup.string().nullable(),
  email: Yup.string()
    .required(ValidationErrors.required)
    .email(ValidationErrors.email),
  mailerUrl: Yup.string()
    .matches(ValidationRules.mailerUrlRegex, { message: ValidationErrors.url })
    .nullable(),
  invoice: Yup.string()
    .required(ValidationErrors.required)
    .email(ValidationErrors.email),
  reminder: Yup.string()
    .required(ValidationErrors.required)
    .email(ValidationErrors.email),
  authorisation: Yup.string(),
});

class Edit extends Component {
  static propTypes = {
    ownerId: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func.isRequired,
    values: PropTypes.object,
  };

  static defaultProps = {
    ownerId: null,
    values: {},
  };

  state = {};

  componentDidMount = () => {};

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return !isEqual(nextProps.values, this.props.values);
  };

  renderBankAccount = (props) => {
    const { inMbvdMode } = this.props;
    const { values, handleChange, handleBlur, touched, errors } = props;

    if (!inMbvdMode) {
      return (
        <>
          <Grid size={12}>
            <Typography variant="h6">
              <Translate>Bank account</Translate>
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Account holder</Translate>}
              name="accountHolder"
              defaultValue={values.accountHolder ? values.accountHolder : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.accountHolder ? errors.accountHolder : ''}
              error={touched.accountHolder && Boolean(errors.accountHolder)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Bank</Translate>}
              name="bank"
              defaultValue={values.bank ? values.bank : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.bank ? errors.bank : ''}
              error={touched.bank && Boolean(errors.bank)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>IBAN</Translate>}
              name="iban"
              defaultValue={values.iban ? values.iban : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.iban ? errors.iban : ''}
              error={touched.iban && Boolean(errors.iban)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>BIC</Translate>}
              name="bic"
              defaultValue={values.bic ? values.bic : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.bic ? errors.bic : ''}
              error={touched.bic && Boolean(errors.bic)}
            />
          </Grid>
        </>
      );
    }
    return null;
  };

  renderFieldset = (props) => {
    const { bindSubmitForm } = this.props;
    const { values, handleChange, handleBlur, touched, errors } = props;

    bindSubmitForm(props.submitForm);

    return (
      <>
        <HiddenInput
          type="hidden"
          name="updateToken"
          value={values.updateToken ? values.updateToken : ''}
        />
        <Grid container spacing={3}>
          <Grid size={12}>
            <TextField
              label={<Translate>Name</Translate>}
              name="company"
              defaultValue={values.company ? values.company : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.company ? errors.company : ''}
              error={touched.company && Boolean(errors.company)}
              required
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Acronym</Translate>}
              name="code"
              defaultValue={values.code ? values.code : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.code ? errors.code : ''}
              error={touched.code && Boolean(errors.code)}
              required
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Email</Translate>}
              name="email"
              type="email"
              defaultValue={values.email ? values.email : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.email ? errors.email : ''}
              error={touched.email && Boolean(errors.email)}
              required
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Mailer URL</Translate>}
              name="mailerUrl"
              defaultValue={values.mailerUrl ? values.mailerUrl : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.mailerUrl ? errors.mailerUrl : ''}
              error={touched.mailerUrl && Boolean(errors.mailerUrl)}
            />
          </Grid>
          <Grid size={12}>
            <Typography variant="h6">
              <Translate>Subscriptions</Translate>
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Invoice email</Translate>}
              name="invoice"
              defaultValue={values.invoice ? values.invoice : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.invoice ? errors.invoice : ''}
              error={touched.invoice && Boolean(errors.invoice)}
              required
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Reminder email</Translate>}
              name="reminder"
              type="email"
              defaultValue={values.reminder ? values.reminder : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.reminder ? errors.reminder : ''}
              error={touched.reminder && Boolean(errors.reminder)}
              required
            />
          </Grid>
          <Grid size={12}>
            <Typography variant="h6">
              <Translate>Letter paper</Translate>
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Authorisation</Translate>}
              name="authorisation"
              defaultValue={values.authorisation ? values.authorisation : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.authorisation ? errors.authorisation : ''}
              error={touched.authorisation && Boolean(errors.authorisation)}
            />
          </Grid>
          {this.renderBankAccount(props)}
        </Grid>
      </>
    );
  };

  render() {
    const { owner, token, form, onSubmit, OnMount } = this.props;
    const { ...defaultValues } = this.props.values;

    if (!isEmpty(form)) {
      defaultValues.updateToken = token;
    }

    return (
      <Form
        name="owner"
        onMount={OnMount}
        onSubmit={onSubmit}
        values={owner}
        validationSchema={validationSchema}
        initialValues={owner ? owner : defaultValues}
        disableToolbar={true}
        renderFieldset={this.renderFieldset}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const ownerId = ownProps.ownerId;

  const {
    entities: {
      owner,
      config: { config: config },
    },
    forms: { owner: form },
  } = state;

  return {
    inMbvdMode: owner[config.owner].seedsMode === 'mbvd',
    owner: owner ? owner[ownerId] : {},
    token: !isEmpty(form) ? form.updateToken : '',
    form: form,
  };
}

export default connect(mapStateToProps, { initForm })(Edit);
