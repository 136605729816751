import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Paper } from '@mui/material';
import { connect } from 'react-redux';
import { isEqual, pick } from 'lodash';
import { loadLayouts, updateLayout, removeLayout, loadNext } from './actions';
import { resetEntity, outdateEntity } from '../../actions';
import EmailLayoutList from './components/List';
import EmailLayoutEdit from './components/Edit';
import DeleteDialog from '../../components/DeleteDialog';
import ErrorBoundary from '../../components/ErrorBoundary';
import { withRouter } from '../../withRouter';
import { compose } from 'redux';

const INITIAL_GRID_STATE = {
  sorting: {
    sortModel: [{ field: 'id', sort: 'asc' }],
  },
};

class EmailLayout extends Component {
  static propTypes = {
    resetEntity: PropTypes.func.isRequired,
    outdateEntity: PropTypes.func.isRequired,
    loadLayouts: PropTypes.func.isRequired,
    loadNext: PropTypes.func.isRequired,
    updateLayout: PropTypes.func.isRequired,
    removeLayout: PropTypes.func.isRequired,
    resultset: PropTypes.array,
    defaultIndex: PropTypes.number,
    mode: PropTypes.string,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_SUPERADMIN', 'ROLE_PRO'],
    resultset: [],
    mode: 'show',
  };

  state = {
    current: null,
    currentIndex: 0,
    adding: false,
    editing: false,
    deleting: false,
    valid: false,
    mode: 'show',
    sortColumns: INITIAL_GRID_STATE.sorting.sortModel.map((val) => val.field),
    sortOrders: INITIAL_GRID_STATE.sorting.sortModel.map((val) => val.sort),
  };

  componentDidMount = () => {
    const { sortColumns, sortOrders } = this.state;
    this.props.loadLayouts(sortColumns, sortOrders);
    switch (this.props.mode) {
      case 'new':
        this.setState({
          adding: true,
          editing: false,
          valid: false,
          mode: 'adding',
        });
        break;
      case 'edit':
        this.setState({
          adding: false,
          editing: true,
          valid: false,
          mode: 'editing',
        });
        break;
      default:
        this.setState({
          adding: false,
          editing: false,
          valid: false,
          mode: 'show',
        });
        break;
    }
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.defaultIndex &&
      prevProps.defaultIndex !== this.props.defaultIndex
    ) {
      this.setState({
        currentIndex: this.props.defaultIndex,
        current: this.props.layouts[this.props.defaultIndex],
      });
    }

    if (prevProps.mode !== this.props.mode) {
      switch (this.props.mode) {
        case 'new':
          this.setState({
            adding: true,
            editing: false,
            valid: false,
            mode: 'adding',
          });
          break;
        case 'edit':
          this.setState({
            adding: false,
            editing: true,
            valid: false,
            mode: 'editing',
          });
          break;
        default:
          this.setState({
            adding: false,
            editing: false,
            valid: false,
            mode: 'show',
          });
          break;
      }
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.mode, this.props.mode) ||
      !isEqual(nextProps.layouts, this.props.layouts) ||
      !isEqual(nextState, this.state) ||
      !isEqual(nextProps.defaultIndex, this.props.defaultIndex) ||
      !isEqual(nextProps.pagination, this.props.pagination) ||
      !isEqual(nextState.deleting, this.state.deleting)
    );
  };

  handleFormMount = (form) => {
    this.form = form;
  };

  handleSortModelChange = (newSortModel) => {
    this.setState({
      sortColumns: newSortModel?.map((value) => value.field),
      sortOrders: newSortModel?.map((value) => value.sort),
    });
  };

  loadNext = (
    columns,
    sort,
    join = [],
    offset = null,
    limit = null,
    filter = [],
  ) => {
    return Promise.all([
      this.props.loadNext(columns, sort, offset, limit, join, filter),
    ]);
  };

  handleSubmit = (form) => {
    const promises = [];
    const { updateLayout, outdateEntity, loadLayouts } = this.props;
    const { current, editing, sortColumns, sortOrders } = this.state;

    this.setState({ valid: false });
    promises.push(updateLayout(form, editing ? current : null));

    if (!editing) {
      outdateEntity('layout');
    }

    return Promise.all(promises).then(
      (values) => {
        if (
          !(
            values &&
            values[0] &&
            values[0].type &&
            values[0].type.match(/_FAILURE/)
          )
        ) {
          this.props.outdateEntity('layout');
          loadLayouts(sortColumns, sortOrders);
          this.setState({ editing: false, mode: 'show' });
        }
      },
      () => {
        this.setState({ valid: false });
      },
    );
  };

  handleValidityChange = (valid) => {
    this.setState({ valid: valid });
  };

  handleListSelection = (current) => {
    this.setState({ current: current, currentIndex: current ? current.id : 0 });
  };

  handleDelete = () => {
    const { layouts, resultset, removeLayout, resetEntity, loadLayouts } =
      this.props;
    const { sortColumns, sortOrders, current } = this.state;

    this.setState({ deleting: true, current: current });

    const currentIndex = resultset.indexOf(this.state.currentIndex);
    const nextIndex =
      currentIndex >= 0 && currentIndex < resultset.length - 1
        ? resultset[currentIndex + 1]
        : currentIndex > 0
          ? resultset[currentIndex - 1]
          : 0;
    // eslint-disable-next-line no-unused-vars
    const next = layouts[nextIndex];

    Promise.all([removeLayout(current, layouts[current].deleteToken)]).then(
      () => {
        this.setState({
          deleting: false,
          mode: 'show',
          currentIndex: nextIndex,
        });
        resetEntity('layout', current);
        loadLayouts(sortColumns, sortOrders);
        this.props.navigate(Routing.generate('emaillayout_index'));
      },
      () => {
        loadLayouts(sortColumns, sortOrders);
      },
    );
  };

  onTouchEdit = (current) => {
    // const {defaultIndex} = this.props;

    this.setState({
      adding: false,
      editing: true,
      valid: false,
      mode: 'editing',
      current: current,
    });
    //this.props.navigate(Routing.generate('emaillayout_token', {id: current ? current : defaultIndex}));
  };

  onTouchAdd = () => {
    this.setState({
      adding: true,
      editing: false,
      valid: false,
      mode: 'adding',
    });
    this.props.navigate(Routing.generate('emaillayout_new'));
  };

  onTouchDelete = (current) => {
    this.setState({ current: current, deleting: true });
  };

  onTouchCancel = () => {
    this.setState({
      adding: false,
      editing: false,
      valid: false,
      mode: 'show',
      deleting: false,
    });
    //this.props.navigate(Routing.generate('emaillayout'));
  };

  renderDeleteDialog = () => {
    const { deleting, current } = this.state;
    const { layouts } = this.props;

    if (deleting) {
      return (
        <DeleteDialog
          current={layouts[current]}
          onCancel={this.onTouchCancel}
          onSubmit={this.handleDelete}
        />
      );
    }

    return null;
  };

  renderContent() {
    const { layouts, defaultIndex, resultset, pagination } = this.props;
    const { mode, current } = this.state;

    let child = null;

    switch (mode) {
      case 'adding':
        child = (
          <ErrorBoundary>
            <EmailLayoutEdit
              onMount={this.handleFormMount}
              onSubmit={this.handleSubmit}
              onValidityChange={this.handleValidityChange}
              layoutId={null}
              onCancel={this.onTouchCancel}
            />
          </ErrorBoundary>
        );
        break;
      case 'editing':
        child = (
          <ErrorBoundary>
            <EmailLayoutEdit
              onMount={this.handleFormMount}
              onSubmit={this.handleSubmit}
              onValidityChange={this.handleValidityChange}
              layoutId={current ? current : defaultIndex}
              onCancel={this.onTouchCancel}
            />
          </ErrorBoundary>
        );
        break;
      case 'show':
      default:
        return (
          <ErrorBoundary>
            <EmailLayoutList
              resultset={resultset}
              layouts={layouts}
              pagination={pagination}
              onAdd={this.onTouchAdd}
              onEdit={this.onTouchEdit}
              onDelete={this.onTouchDelete}
              onCancel={this.onTouchCancel}
              onSortModelChange={this.handleSortModelChange}
              onLoadNext={this.loadNext}
              initialGridState={INITIAL_GRID_STATE}
            />
          </ErrorBoundary>
        );
    }

    return <Paper>{child}</Paper>;
  }

  render() {
    return (
      <>
        {this.renderContent()}
        {this.renderDeleteDialog()}
      </>
    );
  }
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state, ownProps) {
  const {
    entities: { layout },
    resultsets: { layout: resultset },
    // eslint-disable-next-line no-unused-vars
    forms: { email_layout: forms },
    pagination: { layout: pagination },
  } = state;

  return {
    layouts: pick(layout, resultset),
    defaultIndex: layout && resultset ? resultset[0] : null,
    resultset: resultset,
    pagination: pagination,
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    loadLayouts,
    loadNext,
    updateLayout,
    resetEntity,
    removeLayout,
    outdateEntity,
  }),
);

export default enhance(EmailLayout);
