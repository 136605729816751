import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Translate from '../../../components/service/Translate';
import {
  Table,
  TableData,
} from '../../../components/StyledElements/StyledElements';
import { Box } from '@mui/material';

class Show extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  renderPrintSettings = () => {
    const { data, inMbvdMode } = this.props;

    if (!data.voucherPdfOffsetX && !data.voucherPdfOffsetY && !data.signature) {
      return null;
    }

    if (inMbvdMode) {
      return (
        <tbody>
          <tr>
            <TableData
              sx={{
                color: 'text.secondary',
                width: 200,
                pt: 3,
                fontWeight: 500,
              }}
              colSpan="4"
            >
              Druck
            </TableData>
          </tr>
        </tbody>
      );
    }

    return (
      <tbody>
        <tr>
          <TableData
            sx={{
              color: 'text.secondary',
              width: 200,
              pt: 3,
              fontWeight: 500,
            }}
            colSpan="4"
          >
            Druck
          </TableData>
        </tr>
        <tr>
          <TableData width="15%" sx={{ color: 'text.secondary' }}>
            Offset X
          </TableData>
          <TableData width="35%">{data.voucherPdfOffsetX}</TableData>
          <TableData width="15%" sx={{ color: 'text.secondary' }}>
            Offset Y
          </TableData>
          <TableData width="35%">{data.voucherPdfOffsetY}</TableData>
        </tr>
      </tbody>
    );
  };

  render() {
    const { data } = this.props;

    if (!data) {
      return null;
    }

    return (
      <Box sx={{ pt: 3 }}>
        <Table width="100%">
          <tbody>
            <tr>
              <TableData width="15%" sx={{ color: 'text.secondary' }}>
                Anrede
              </TableData>
              <TableData width="35%">
                <Translate>{data.gender}</Translate>
              </TableData>
            </tr>
            <tr>
              <TableData width="15%" sx={{ color: 'text.secondary' }}>
                Vorname
              </TableData>
              <TableData width="35%">{data.firstname}</TableData>
              <TableData width="15%" sx={{ color: 'text.secondary' }}>
                Name
              </TableData>
              <TableData width="35%">{data.name}</TableData>
            </tr>
            <tr>
              <TableData width="15%" sx={{ color: 'text.secondary' }}>
                Position
              </TableData>
              <TableData width="35%">{data.position}</TableData>
              <TableData width="15%" sx={{ color: 'text.secondary' }}>
                Kürzel
              </TableData>
              <TableData width="35%">{data.agentCode}</TableData>
            </tr>
            <tr>
              <TableData
                sx={{
                  width: 200,
                  pt: 3,
                  fontWeight: 500,
                }}
                colSpan="4"
              >
                Kontakt
              </TableData>
            </tr>
            <tr>
              <TableData width="15%" sx={{ color: 'text.secondary' }}>
                E-Mail
              </TableData>
              <TableData width="35%">{data.email}</TableData>
              <TableData width="15%" sx={{ color: 'text.secondary' }}>
                Telefon
              </TableData>
              <TableData width="35%">{data.phone}</TableData>
            </tr>
            <tr>
              <TableData width="15%" sx={{ color: 'text.secondary' }}>
                Sprache
              </TableData>
              <TableData width="35%" colSpan="3">
                <Translate>{data.language}</Translate>
              </TableData>
            </tr>
            <tr>
              <TableData
                sx={{
                  width: 200,
                  pt: 3,
                  fontWeight: 500,
                }}
                colSpan="4"
              >
                Zugangsdaten
              </TableData>
            </tr>
            <tr>
              <TableData width="15%" sx={{ color: 'text.secondary' }}>
                Benutzername
              </TableData>
              <TableData width="35%">{data.username}</TableData>
              <TableData width="15%" sx={{ color: 'text.secondary' }}>
                Rolle
              </TableData>
              <TableData width="35%">
                <Translate>{data.role}</Translate>
              </TableData>
            </tr>
            <tr>
              <TableData
                sx={{
                  width: 200,
                  pt: 3,
                  fontWeight: 500,
                }}
                colSpan="4"
              >
                Abonnements
              </TableData>
            </tr>
            <tr>
              <TableData width="15%" sx={{ color: 'text.secondary' }}>
                Freigabe AutoSelect
              </TableData>
              <TableData width="35%">
                <Translate>{data.reminder}</Translate>
              </TableData>
              <TableData width="15%" sx={{ color: 'text.secondary' }}>
                Rechnungen
              </TableData>
              <TableData width="35%">
                <Translate>{data.invoice}</Translate>
              </TableData>
            </tr>
          </tbody>
          {this.renderPrintSettings()}
        </Table>
      </Box>
    );
  }
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state, ownProps) {
  const {
    entities: {
      config: { config: config },
      owner,
    },
  } = state;

  return {
    inMbvdMode: owner[config.owner].seedsMode === 'mbvd',
  };
}

export default connect(mapStateToProps, {})(Show);
