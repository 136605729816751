import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { createOptions as createBaseOptions } from './CreateOptions';
import { createOptions as createLightOptions } from './light/CreateOptions';

declare module '@mui/material/styles/createPalette' {
  interface ColorRange {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  }

  interface Palette {
    neutral: ColorRange;
  }

  interface PaletteColor {
    lightest?: string;
    darkest?: string;
    alpha4?: string;
    alpha8?: string;
    alpha12?: string;
    alpha30?: string;
    alpha50?: string;
  }

  interface PaletteOptions {
    neutral?: ColorRange;
  }

  interface TypeBackground {
    paper: string;
    default: string;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    locale: {
      de: string;
      deString: string;
      fr: string;
    };
  }
  interface ThemeOptions {
    locale?: {
      de?: string;
      deString?: string;
      fr?: string;
    };
  }
}

export type ColorPreset = 'purple'; // | 'blue' | 'green' | 'indigo'

export type Contrast = 'normal' | 'high';

export type Direction = 'ltr' | 'rtl';

export type PaletteMode = 'dark' | 'light';

interface ThemeConfig {
  colorPreset?: ColorPreset;
  contrast?: Contrast;
  direction?: Direction;
  paletteMode?: PaletteMode;
  responsiveFontSizes?: boolean;
}

export const createEeloyTheme = (config: ThemeConfig): Theme => {
  let theme = createMuiTheme(
    createBaseOptions({
      direction: config.direction,
    }),
    createLightOptions({
      colorPreset: config.colorPreset,
      contrast: config.contrast,
    }),
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
