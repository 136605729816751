import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  TextField,
  CssBaseline,
  Box,
  Typography,
  Link,
  Button,
  Stack,
  Divider,
} from '@mui/material';

import { loadLogin, loadPasswordReset } from './actions';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import EeloyIcon from '../../theme/icons/EeloyIcon';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import * as Yup from 'yup';
import ValidationErrors from '../../ValidationErrors';
import ValidationRules from '../../ValidationRules';
import Form from '../../components/Form';
import Translate from '../../components/service/Translate';
import { styled } from '@mui/system';

const validationSchema = Yup.object().shape({
  username: Yup.string().required(ValidationErrors.required),
  password: Yup.string().required(ValidationErrors.required),
});

const validationSchemaOutdated = Yup.object().shape({
  password: Yup.string()
    .min(8, ValidationErrors.passwordRule)
    .test('pwValidation', ValidationErrors.passwordRule, (pw) => {
      if (pw === '' || typeof pw === 'undefined') {
        return true;
      }

      const categories = ValidationRules.passwordRegexCategories;
      let counter = 0;

      categories.forEach((category) => {
        if (category.test(pw)) {
          counter++;
        }
      });
      return counter >= 3;
    }),
  password_confirmation: Yup.string().when('password', {
    is: (val) => !!(val && val.length > 0),
    then: () =>
      Yup.string()
        .oneOf([Yup.ref('password')], ValidationErrors.requiredPasswordConfirm)
        .required(ValidationErrors.required),
  }),
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.formikProps = null;
  }

  static propTypes = {
    name: PropTypes.string,
    values: PropTypes.object,
    form: PropTypes.object,
    showLogin: PropTypes.bool,
    onLoginSuccess: PropTypes.func,
    loggedInBefore: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    values: {},
    showLogin: false,
  };

  state = {
    username: '',
    password: '',
    infoMessage: null,
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.showLogin, this.props.showLogin) ||
      !isEqual(nextState.outdated, this.state.outdated) ||
      !isEqual(nextState.infoMessage, this.state.infoMessage)
    );
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.showLogin && this.props.showLogin) {
      this.setState({ infoMessage: null, loginSuccess: false });
    }
  }

  renderUpdatePasswordMessage = () => {
    return (
      <Box>
        <Typography variant="h1">HALLO BEI EELOY</Typography>
        <p>
          <b>
            Sie haben Ihr Passwort zu lange nicht geändert. Bitte ändern Sie Ihr
            Passwort.
          </b>
        </p>
        <p>
          Bitte geben Sie Ihre Zugangsdaten nicht an Dritte weiter und halten
          Sie diese unter Verschluss. Wir empfehlen die Nutzung von sogenannten
          Passwort-Safes. Verwenden Sie für das Passwort keine einfachen, leicht
          zu erratenden oder bereits verwendete Kombinationen.
        </p>
      </Box>
    );
  };

  renderLoginWelcomeMessage = () => {
    return (
      <Box>
        <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
          HALLO BEI EELOY
        </Typography>
        <Typography component="p" variant="body1" sx={{ mt: 2, mb: 2 }}>
          Der Autopilot für Autohaus-Marketing.
          <br />
          Einloggen und loslegen.
          <br />
          Noch keinen Zugang? Mehr Infos{' '}
          <Link variant="inherit" href="https://www.eeloy.com/seeds/">
            hier
          </Link>
          .
        </Typography>
      </Box>
    );
  };

  renderLoggedOutMessage = () => {
    return (
      <Box>
        <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
          Ups...
        </Typography>
        <Typography component="p" variant="body1" sx={{ mt: 2, mb: 2 }}>
          Sie wurden abgemeldet.
          <br />
          Bitte melden Sie sich erneut an, um mit Ihrer Arbeit fortzufahren.
        </Typography>
      </Box>
    );
  };

  renderFieldset = (props) => {
    const { handleChange, handleBlur, touched, errors } = props;
    this.formikProps = props;

    return (
      <>
        <TextField
          placeholder="Benutzername"
          name="username"
          variant="standard"
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.username ? errors.username : ''}
          error={touched.username && Boolean(errors.username)}
          key={4}
          sx={{ width: '100%', clear: 'both', m: 0 }}
        />
        <TextField
          placeholder="Passwort"
          name="password"
          variant="standard"
          onChange={handleChange}
          onBlur={handleBlur}
          type="password"
          helperText={touched.password ? errors.password : ''}
          error={touched.password && Boolean(errors.password)}
          key={3}
          sx={{ width: '100%', clear: 'both', m: 0 }}
        />
        <Stack
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          sx={{ mt: 2, mb: 2 }}
        >
          <Button variant="contained" type="submit">
            Anmelden
          </Button>
        </Stack>
        <Divider sx={{ borderColor: 'neutral.300' }} />
        <Link
          sx={{
            float: 'right',
            color: 'neutral.400',
          }}
          href={Routing.generate('password_recover', null, true)}
        >
          Passwort vergessen?
        </Link>
      </>
    );
  };

  renderFieldsetUpdatePassword = (props) => {
    const { handleChange, handleBlur, touched, errors } = props;

    return (
      <>
        <TextField
          placeholder="Passwort"
          name="password"
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.password ? errors.password : ''}
          error={touched.password && Boolean(errors.password)}
          type="password"
          key={2}
          sx={{ width: '100%', clear: 'both' }}
        />
        <TextField
          placeholder="Passwort wiederholen"
          name="password_confirmation"
          onChange={handleChange}
          onBlur={handleBlur}
          type="password"
          helperText={
            touched.password_confirmation ? errors.password_confirmation : ''
          }
          error={
            touched.password_confirmation &&
            Boolean(errors.password_confirmation)
          }
          key={1}
          sx={{ width: '100%', clear: 'both' }}
        />
        <Button variant="contained" type="submit">
          Passwort ändern
        </Button>
      </>
    );
  };

  submit = () => {
    this.form.submit();
  };

  handleSubmit = (form) => {
    const username = form.get('username');
    const password = form.get('password');
    if (username && password) {
      this.setState({
        infoMessage: 'Anmeldevorgang läuft...',
        username: username,
        loginSuccess: false,
      });
      return Promise.all([this.props.loadLogin(username, password)]).then(
        (values) => {
          if (values[0].type === 'LOGIN_SUCCESS') {
            this.setState({
              infoMessage: 'Erfolgreich angemeldet. Seite wird geladen...',
              loginSuccess: true,
            });
            this.props.onLoginSuccess();
          } else {
            if (values[0].response.outdated) {
              this.setState({
                outdated: true,
                resetKey: values[0].response.resetKey,
                infoMessage: values[0].response.message,
                loginSuccess: false,
              });
            } else {
              this.setState({
                infoMessage: values[0].error,
                loginSuccess: false,
              });
            }
          }
        },
      );
    }
  };

  handleSubmitUpdatePassword = (form) => {
    this.setState({
      infoMessage: 'Passwort wird geändert...',
      loginSuccess: false,
    });
    const password = form.get('password');

    return Promise.all([
      this.props.loadPasswordReset(this.state.resetKey, password),
    ]).then((values) => {
      if (values[0].type === 'PASSWORD_RESET_SUCCESS') {
        if (values[0].response.entities.login.login.passwordUpdated) {
          this.setState({
            infoMessage:
              'Passwort erfolgreich geändert. Bitte loggen Sie sich erneut ein.',
            outdated: false,
            loginSuccess: false,
          });
        } else {
          if (values[0].response.entities.login.login.usedBefore) {
            this.setState({
              infoMessage: 'Das Passwort wurde bereits genutzt.',
              loginSuccess: false,
            });
          }
          if (values[0].response.entities.login.login.expired) {
            this.setState({
              infoMessage: 'Die Anfrage ist abgelaufen.',
              loginSuccess: false,
            });
          }
        }
      } else {
        this.setState({ infoMessage: values[0].error, loginSuccess: false });
      }
    });
  };

  renderLogin() {
    const { loggedInBefore } = this.props;
    const { infoMessage, outdated, resetKey, loginSuccess } = this.state;

    let info, loginMessage;

    if (infoMessage && !outdated) {
      info = (
        <Box sx={{ color: loginSuccess ? 'success' : 'info' }}>
          <Translate>{infoMessage}</Translate>
        </Box>
      );
    }

    if (outdated) {
      loginMessage = this.renderUpdatePasswordMessage();
    } else if (!loggedInBefore) {
      loginMessage = this.renderLoginWelcomeMessage();
    } else {
      loginMessage = this.renderLoggedOutMessage();
    }

    return (
      <Box>
        <CssBaseline />
        <Box
          sx={{
            position: 'fixed',
            maxWidth: '100%',
            width: '700px',
            maxHeight: '100%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: 99999,
            boxShadow: '0 0 24px 10px rgba(0, 0, 0, 0.45)',
            backgroundColor: 'neutral.50',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              left: '19%',
              top: '37px',
              maxWidth: '71%',
              pb: '80px',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: '-78px',
                top: '-12px',
              }}
            >
              <EeloyIcon style={{ width: '55', height: '55' }} />
            </Box>
            {loginMessage}
            {info ? (
              <Box
                sx={{
                  maxWidth: '500px',
                  height: '32px',
                  marginBottom: '9.38px',
                }}
              >
                {info}
              </Box>
            ) : null}
            {outdated ? (
              <Form
                disableToolbar
                disableMargin
                validationSchema={validationSchemaOutdated}
                onSubmit={this.handleSubmitUpdatePassword}
                initialValues={{ resetKey: resetKey }}
                name="updatePassword"
                renderFieldset={this.renderFieldsetUpdatePassword}
                values={{ resetKey: resetKey }}
              />
            ) : (
              <Form
                disableToolbar
                disableMargin
                validationSchema={validationSchema}
                onSubmit={this.handleSubmit}
                initialValues={{}}
                name="login"
                renderFieldset={this.renderFieldset}
                values={{}}
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  render() {
    const { showLogin, onLoginSuccess, loggedInBefore } = this.props;

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showLogin}
        onClose={onLoginSuccess}
        disableEscapeKeyDown={true}
        closeAfterTransition
        hideBackdrop={!loggedInBefore}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showLogin}>{this.renderLogin()}</Fade>
      </Modal>
    );
  }
}

export default connect(null, { loadLogin, loadPasswordReset })(Login);
