import { Component } from 'react';
import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty, isEqual } from 'lodash';
import {
  CircularProgress,
  List,
  ListItemIcon,
  ListItemText,
  Avatar,
  Box,
} from '@mui/material';
import { connect } from 'react-redux';
import { makeCustomerListCustomerByCl } from '../selectors';
import Paper from '@mui/material/Paper';

import ListItemButton from '@mui/material/ListItemButton';

class DockedList extends Component {
  static propTypes = {
    jobId: PropTypes.number,
    customerListCustomer: PropTypes.object,
    customer: PropTypes.object,
    onItemSelect: PropTypes.func.isRequired,
    isPaging: PropTypes.bool,
    pageable: PropTypes.bool,
    onBottomReached: PropTypes.func,
    iterableCustomerListCustomer: PropTypes.array,
  };

  static defaultProps = {
    listItems: {},
    pageable: false,
    isPaging: false,
    onBottomReached: () => {},
  };

  state = {
    currentSelectedValues: [],
  };

  componentDidMount() {
    if (!isEmpty(this.props.iterableCustomerListCustomer)) {
      this.handleItemSelect(
        null,
        this.props.selectedIndex
          ? this.props.selectedIndex
          : Object.values(this.props.iterableCustomerListCustomer)[0],
      );
    }
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.jobId, this.props.jobId) ||
      !isEqual(nextProps.isPaging, this.props.isPaging) ||
      !isEqual(nextProps.selectedIndex, this.props.selectedIndex) ||
      !isEqual(
        nextProps.iterableCustomerListCustomer,
        this.props.iterableCustomerListCustomer,
      )
    );
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(this.props.jobId, prevProps.jobId) ||
      !isEqual(
        this.props.iterableCustomerListCustomer,
        prevProps.iterableCustomerListCustomer,
      )
    ) {
      if (
        this.props.iterableCustomerListCustomer &&
        this.props.iterableCustomerListCustomer.length !== 0
      ) {
        this.handleItemSelect(
          null,
          this.props.selectedIndex
            ? this.props.selectedIndex
            : Object.values(this.props.iterableCustomerListCustomer)[0],
        );
      }
    }
  }

  handleMount = (list) => {
    this.list = list;
  };

  handleScroll = () => {
    if (!this.props.pageable) {
      return;
    }

    if (
      this.list.scrollTop >=
      this.list.scrollHeight - this.list.clientHeight
    ) {
      this.props.onBottomReached();
    }
  };

  handleItemSelect = (event, index) => {
    this.props.onItemSelect(event, index);
    this.setState({ selectedIndex: index });
  };

  renderPagination = () => {
    if (!this.props.pageable || !this.props.isPaging) {
      return null;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {' '}
        <Paper
          sx={{
            position: 'absolute',
            backgroundColor: 'white',
            borderRadius: '50%',
            bottom: 100,
            zIndex: 99999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '50px',
            height: '50px',
            p: 0,
            m: 0,
          }}
        >
          <CircularProgress
            size={35}
            sx={{
              display: 'inline-block',
              position: 'relative',
            }}
          />
        </Paper>
      </Box>
    );
  };

  renderListItem = (customerListCustomerId) => {
    const customerListCustomer =
      this.props.customerListCustomer[customerListCustomerId];
    const customer = this.props.customer[customerListCustomer.customer];

    let leftAvatar = (
      <Avatar size={10}>
        {customer.name.charAt(0) + customer.firstname.charAt(0)}
      </Avatar>
    );
    return (
      <ListItemButton
        selected={customerListCustomerId === this.props.selectedIndex}
        onClick={(event) => {
          this.handleItemSelect(event, customerListCustomerId);
        }}
        key={`customerListCustomer-${customerListCustomerId}`}
      >
        <ListItemIcon>{leftAvatar}</ListItemIcon>
        <ListItemText
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: 160,
          }}
          primary={
            customer
              ? customer.primaryText
              : 'deleted.CustomerName, deleted.CustomerFirstname'
          }
        />
      </ListItemButton>
    );
  };

  render() {
    const { iterableCustomerListCustomer } = this.props;

    if (!iterableCustomerListCustomer) {
      return null;
    }

    return (
      <Box
        sx={{
          maxHeight: (theme) => `calc(100% - 64px)`,
          overflow: 'auto',
        }}
        ref={this.handleMount}
        onScroll={this.handleScroll}
      >
        <List
          component="nav"
          sx={{
            height: (theme) => `calc(100% - ${2 * 64}px)`,
            mt: 8,
            pt: 0,
          }}
        >
          {iterableCustomerListCustomer.map(this.renderListItem)}
        </List>
        {this.renderPagination()}
      </Box>
    );
  }
}

const makeMapStateToProps = () => {
  const getCustomerListCustomerByCl = makeCustomerListCustomerByCl();

  return (state, props) => {
    const {
      entities: { customerListCustomer, customer },
    } = state;

    return {
      customerListCustomer,
      customer,
      iterableCustomerListCustomer: getCustomerListCustomerByCl(state, props),
    };
  };
};

export default connect(makeMapStateToProps, {})(DockedList);
