import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import DeleteDialog from '../../components/DeleteDialog';
import Translate from '../../components/service/Translate';
import ErrorBoundary from '../../components/ErrorBoundary';
import SigneeEdit from './components/Edit';
import SigneeList from './components/List';
import { Paper } from '@mui/material';
import { loadSignees, loadNext, removeSignee, updateSignee } from './actions';
import { isEqual, isEmpty } from 'lodash';
import { resetEntity, outdateEntity } from '../../actions';
import { compose } from 'redux';

const INITIAL_GRID_STATE = {
  sorting: {
    sortModel: [{ field: 'name', sort: 'asc' }],
  },
};

class Signee extends Component {
  static propTypes = {
    outdateEntity: PropTypes.func.isRequired,
    loadNext: PropTypes.func.isRequired,
    requiredRoles: PropTypes.array,
    mode: PropTypes.string,
    signees: PropTypes.object,
    resultset: PropTypes.array,
    signeeId: PropTypes.number,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_SUPERADMIN'],
    resultset: [],
    mode: 'show',
  };

  state = {
    mode: 'show',
    sortColumns: INITIAL_GRID_STATE.sorting.sortModel.map((val) => val.field),
    sortOrders: INITIAL_GRID_STATE.sorting.sortModel.map((val) => val.sort),
    dataLoading: false,
    initialMode: '',
  };

  componentDidMount = () => {
    if (this.props.mode === 'show') {
      this.loadAllSignees();
    }
    this.setState({ mode: this.props.mode, initialMode: this.props.mode });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.mode !== this.props.mode) {
      this.setState({ mode: this.props.mode, deleting: false });
    }
    if (
      (this.state.initialMode === 'edit' || this.state.initialMode === 'add') &&
      this.props.mode === 'show'
    ) {
      this.setState({ initialMode: null });
      this.loadAllSignees(true);
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.mode, this.props.mode) ||
      !isEqual(nextProps.signeeId, this.props.signeeId) ||
      !isEqual(nextProps.signees, this.props.signees) ||
      !isEqual(nextProps.resultset, this.props.resultset) ||
      !isEqual(nextState.dataLoading, this.state.dataLoading) ||
      !isEqual(nextState.mode, this.state.mode) ||
      !isEqual(nextState.initialMode, this.state.initialMode) ||
      !isEqual(nextState.current, this.state.current) ||
      !isEqual(nextState.deleting, this.state.deleting)
    );
  };

  loadAllSignees = (showLoading = true) => {
    const { sortColumns, sortOrders } = this.state;
    showLoading ? this.setState({ dataLoading: true }) : null;
    Promise.all([this.props.loadSignees(sortColumns, sortOrders)]).then(
      () => {
        this.setState({ dataLoading: false });
      },
      () => {
        this.setState({ dataLoading: false });
      },
    );
  };

  loadNext = (
    columns,
    sort,
    join = [],
    offset = null,
    limit = null,
    filter = [],
  ) => {
    return Promise.all([
      this.props.loadNext(columns, sort, offset, limit, join, filter),
    ]);
  };

  handleSubmit = (form) => {
    const promises = [];
    const { updateSignee, signeeId } = this.props;

    promises.push(updateSignee(form, signeeId));

    return Promise.all(promises).then(
      (values) => {
        if (
          !(
            values &&
            values[0] &&
            values[0].type &&
            values[0].type.match(/_FAILURE/)
          )
        ) {
          this.loadAllSignees(false);
          this.props.navigate('/mailing/signee');
        }
      },
      () => {
        this.setState({ valid: false });
      },
    );
  };

  handleSortModelChange = (newSortModel) => {
    this.setState({
      sortColumns: newSortModel?.map((value) => value.field),
      sortOrders: newSortModel?.map((value) => value.sort),
    });
  };

  handleDelete = (signeeId, deleteToken) => {
    const { resetEntity, removeSignee } = this.props;

    this.setState({ deleting: true });

    Promise.all([removeSignee(signeeId, deleteToken)]).then(
      () => {
        this.setState({ deleting: false });
        resetEntity('signee', signeeId);
        this.loadAllSignees(false);
        this.props.navigate('/mailing/signee');
      },
      () => {
        this.setState({ deleting: false });
      },
    );
  };

  onTouchEdit = (current) => {
    this.props.navigate('/mailing/signee/edit/' + current);
  };

  onTouchAdd = () => {
    this.props.navigate('/mailing/signee/add');
  };

  onTouchDelete = (current) => {
    this.setState({ current: current, deleting: true });
  };

  onTouchCancel = () => {
    this.props.navigate('/mailing/signee/');
  };

  renderDeleteDialog = () => {
    const { deleting, current } = this.state;
    const { signees } = this.props;

    if (deleting) {
      return (
        <DeleteDialog
          current={signees[current]}
          onCancel={this.onTouchCancel}
          onSubmit={this.handleDelete}
        />
      );
    }

    return null;
  };

  renderContent() {
    const { pagination, resultset } = this.props;
    const { mode, sortOrders, sortColumns, dataLoading } = this.state;

    let child = null;

    if (dataLoading) {
      child = <Translate>Data loading...</Translate>;
    } else {
      switch (mode) {
        case 'add':
          child = (
            <ErrorBoundary>
              <SigneeEdit
                onSubmit={this.handleSubmit}
                onCancel={this.onTouchCancel}
              />
            </ErrorBoundary>
          );
          break;
        case 'edit':
          child = (
            <ErrorBoundary>
              <SigneeEdit
                onSubmit={this.handleSubmit}
                onCancel={this.onTouchCancel}
              />
            </ErrorBoundary>
          );
          break;
        case 'show':
        default:
          return (
            <ErrorBoundary>
              <SigneeList
                defaultSortColumns={sortColumns}
                defaultSortOrders={sortOrders}
                resultset={resultset}
                pagination={pagination}
                onSortModelChange={this.handleSortModelChange}
                onEdit={this.onTouchEdit}
                onAdd={this.onTouchAdd}
                onDelete={this.onTouchDelete}
                onLoadNext={this.loadNext}
                initialGridState={INITIAL_GRID_STATE}
              />
            </ErrorBoundary>
          );
      }
    }

    return <Paper>{child}</Paper>;
  }

  render() {
    return (
      <>
        {this.renderContent()}
        {this.renderDeleteDialog()}
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const {
    entities: { signee },
    resultsets: { signee: resultset },
    pagination: { signee: pagination },
  } = state;

  return {
    mode: props?.match?.params?.mode ?? 'show',
    signeeId: props?.match?.params?.signeeId
      ? parseInt(props.match.params.signeeId)
      : null,
    signees: !isEmpty(signee) ? signee : {},
    pagination: pagination,
    resultset: resultset,
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    loadSignees,
    loadNext,
    updateSignee,
    removeSignee,
    resetEntity,
    outdateEntity,
  }),
);

export default enhance(Signee);
