import { schema } from 'normalizr';

export const EmailModuleResult = new schema.Entity(
  'emailModule',
  {},
  { idAttribute: (emailModule) => emailModule.id },
);
export const CarResult = new schema.Entity(
  'car',
  {},
  { idAttribute: (car) => car.id },
);
export const EmailQueueResult = new schema.Entity(
  'emailQueue',
  {},
  { idAttribute: (emailQueue) => emailQueue.id },
);
export const SmsQueueResult = new schema.Entity(
  'smsQueue',
  {},
  { idAttribute: (smsQueue) => smsQueue.id },
);
export const ProductResult = new schema.Entity(
  'product',
  {},
  { idAttribute: (product) => product.id },
);
export const CampaignConfigParamResult = new schema.Entity(
  'campaignConfigParam',
  {},
  { idAttribute: (campaignConfigParam) => campaignConfigParam.id },
);
