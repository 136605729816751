import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { isEqual, keys, merge, isEmpty } from 'lodash';
import Form from '../../../components/Form';
import Translate from '../../../components/service/Translate';
import { initSmsForm, loadSeason, loadSeasons } from '../actions';
import { makeSeasonById } from '../selectors_deprecated';
import { outdateEntity } from '../../../actions';
import { withRouter } from '../../../withRouter';
import * as Yup from 'yup';
import ValidationErrors from '../../../ValidationErrors';
import ValidationRules from '../../../ValidationRules';
import { makeSelectableSelections } from '../../Selection/selectors';
import { loadSelections } from '../../Selection/actions';
import { DatePicker } from '@mui/x-date-pickers';
import {
  EmptyMenuItem,
  HiddenInput,
} from '../../../components/StyledElements/StyledElements';
import { compose } from 'redux';
import Grid from '@mui/material/Grid2';
import { FormCheckbox } from '../../../components/StyledElements/StyledFormElements';
import { parseDate } from '../../../utils/HelperFunctions';

const validationSchema = Yup.object().shape({
  campaign: Yup.object().shape({
    campaignKey: Yup.string().required(ValidationErrors.required),
  }),
  season: Yup.object().shape({
    archived: Yup.string().nullable(),
    configKey: Yup.string()
      .matches(ValidationRules.configKeyRegex, {
        message: ValidationErrors.configKeyRegexMessage,
      })
      .required(ValidationErrors.configKeyRegexMessage),
    cycle: Yup.string().oneOf(
      ['monthly', 'singular', 'consecutively'],
      ValidationErrors.requiredSelect,
    ),
    parent: Yup.string().nullable(),
    selection: Yup.string().when('parent', {
      is: (val) => !!val,
      then: () => Yup.string().required(ValidationErrors.required),
      otherwise: () => Yup.string().nullable(),
    }),
    combined: Yup.bool(),
    delayInterval: Yup.string()
      .matches('\\d+', { message: ValidationErrors.delayIntervalFormat })
      .nullable(),
  }),
  price: Yup.number()
    .transform((_value, originalValue) =>
      Number(originalValue.toString().replace(/,/, '.')),
    )
    .typeError(ValidationErrors.typeErrorNumberWithComma)
    .required(ValidationErrors.required),
  masterTemplate: Yup.object()
    .shape({
      name: Yup.string().required(ValidationErrors.required).max(250),
      sender: Yup.string().required(ValidationErrors.required).max(21),
      content: Yup.string().required(ValidationErrors.required),
    })
    .nullable(),
  approvalPeriod: Yup.mixed()
    .nullable()
    .when('season.cycle', {
      is: (val) => val && val === 'singular',
      then: () => Yup.string().nullable(),
      otherwise: () =>
        Yup.number()
          .nullable()
          .positive(ValidationErrors.positiveIntNumber)
          .integer(ValidationErrors.positiveIntNumber)
          .typeError(ValidationErrors.positiveIntNumber),
    }),
  printPeriod: Yup.mixed()
    .nullable()
    .when('season.cycle', {
      is: (val) => val && val === 'singular',
      then: () => Yup.string().nullable(),
      otherwise: () =>
        Yup.number()
          .nullable()
          .positive(ValidationErrors.positiveIntNumber)
          .integer(ValidationErrors.positiveIntNumber)
          .typeError(ValidationErrors.positiveIntNumber),
    }),
});

class EditSms extends Component {
  static propTypes = {
    campaignId: PropTypes.number.isRequired,
    seasonType: PropTypes.string.isRequired,
    seasonId: PropTypes.number,
    loadSeason: PropTypes.func.isRequired,
    loadSeasons: PropTypes.func.isRequired,
    loadSelections: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    onValidityChange: PropTypes.func.isRequired,
    outdateEntity: PropTypes.func.isRequired,
    seasons: PropTypes.object,
    selections: PropTypes.object,
    channel: PropTypes.object,
    values: PropTypes.object,
    token: PropTypes.string,
  };

  static defaultProps = {
    values: {},
  };

  state = {};

  componentDidMount = () => {
    this.setState({ loading: true });

    let promises = [];

    promises.push(this.props.outdateEntity('selection'));
    promises.push(
      this.props.loadSelections(null, null, ['season'], this.props.seasonId),
    );
    promises.push(this.props.loadSeasons([], [], ['isParent']));

    if (this.props.seasonId) {
      promises.push(this.props.loadSeason(this.props.seasonId));
    } else {
      promises.push(this.props.initSmsForm());
    }

    Promise.all(promises).then(() => {
      this.setState({ loading: false });
    });
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (this.props.seasonId !== prevProps.seasonId) {
      this.props.loadSeason(this.props.seasonId);
    }
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.seasonId, this.props.seasonId) ||
      !isEqual(nextProps.channel, this.props.channel) ||
      !isEqual(nextProps.seasonType, this.props.seasonType) ||
      !isEqual(nextProps.campaignId, this.props.campaignId) ||
      !isEqual(nextProps.season, this.props.season) ||
      !isEqual(nextProps.form, this.props.form) ||
      !isEqual(nextProps.campaign, this.props.campaign) ||
      !isEqual(nextProps.token, this.props.token) ||
      !isEqual(nextState.loading, this.state.loading) ||
      !isEqual(nextProps.selections, this.props.selections)
    );
  };

  renderSelectionSelect = (props) => {
    const { selections, season } = this.props;
    const { values, handleChange, handleBlur, touched, errors } = props;

    if (isEmpty(selections) || season.isParent) {
      return null;
    }

    return (
      <Grid size={{ xs: 12, sm: 6 }}>
        <TextField
          select
          id={'season[selection]'}
          label={<Translate>Selection</Translate>}
          name={'season[selection]'}
          value={
            values.season && values.season.selection
              ? values.season.selection
              : ''
          }
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={
            touched.season && errors.season && touched.season.selection
              ? errors.season.selection
              : ''
          }
          error={
            touched.season &&
            errors.season &&
            touched.season.selection &&
            Boolean(errors.season.selection)
          }
        >
          <EmptyMenuItem key="seasonSelectionNull"> </EmptyMenuItem>
          {keys(selections).map((selectionId) => {
            return (
              <MenuItem key={Math.random()} value={selections[selectionId].id}>
                {' '}
                {selections[selectionId].title}{' '}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
    );
  };

  renderMasterTemplate = (props) => {
    const { handleChange, handleBlur, values, touched, errors } = props;

    return (
      <>
        <Grid size={12}>
          <Typography variant="h6">
            <Translate>Master template</Translate>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            id={'masterTemplate[name]'}
            label={<Translate>Name</Translate>}
            defaultValue={
              values && values.masterTemplate ? values.masterTemplate.name : ''
            }
            name={'masterTemplate[name]'}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.name
                ? errors.masterTemplate.name
                : ''
            }
            error={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.name &&
              Boolean(errors.masterTemplate.name)
            }
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            id={'masterTemplate[sender]'}
            label={<Translate>Sender</Translate>}
            name={'masterTemplate[sender]'}
            defaultValue={
              values && values.masterTemplate
                ? values.masterTemplate.sender
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.sender
                ? errors.masterTemplate.sender
                : ''
            }
            error={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.sender &&
              Boolean(errors.masterTemplate.sender)
            }
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            id={'masterTemplate[sender]'}
            label={<Translate>Sender</Translate>}
            name={'masterTemplate[sender]'}
            defaultValue={
              values && values.masterTemplate
                ? values.masterTemplate.sender
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.sender
                ? errors.masterTemplate.sender
                : ''
            }
            error={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.sender &&
              Boolean(errors.masterTemplate.sender)
            }
          />
        </Grid>
        <Grid size={12}>
          <TextField
            id={'masterTemplate[content]'}
            label={<Translate>Content</Translate>}
            name={'masterTemplate[content]'}
            defaultValue={
              values && values.masterTemplate
                ? values.masterTemplate.content
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.content
                ? errors.masterTemplate.content
                : ''
            }
            error={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.content &&
              Boolean(errors.masterTemplate.content)
            }
            multiline
          />
        </Grid>
      </>
    );
  };

  renderSMSFieldset = (props) => {
    const { season } = this.props;
    const { handleBlur, handleChange, setFieldValue, values, errors, touched } =
      props;

    return (
      <>
        <Grid size={12}>
          <Typography variant="h6">
            <Translate>Customer list</Translate>
          </Typography>
        </Grid>
        {this.renderSelectionSelect(props)}
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormCheckbox
            name="season[combined]"
            label={<Translate>combined</Translate>}
            checked={props.values?.season?.combined ?? false}
            setFieldValue={setFieldValue}
            error={touched?.season?.combined && errors?.season?.combined}
          />
        </Grid>
        <Grid size={12}>
          <Typography variant="h6">
            <Translate>Timing</Translate>
          </Typography>
        </Grid>
        {values.season && values.season.cycle === 'singular' ? (
          <Grid size={{ xs: 12, sm: 6 }}>
            <Stack direction="row" spacing={3}>
              <DatePicker
                label={<Translate>Approval date</Translate>}
                format="dd.MM.yyyy"
                value={
                  values.approvalPeriod
                    ? parseDate(values.approvalPeriod)
                    : null
                }
                onChange={(date) =>
                  setFieldValue(
                    'approvalPeriod',
                    date.toLocaleString('de-DE', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }),
                  )
                }
                slotProps={{
                  textField: {
                    helperText: errors.approvalPeriod,
                  },
                }}
              />
              <DatePicker
                label={<Translate>Print date</Translate>}
                format="dd.MM.yyyy"
                value={
                  values.printPeriod ? parseDate(values.printPeriod) : null
                }
                onChange={(date) =>
                  setFieldValue(
                    'printPeriod',
                    date.toLocaleString('de-DE', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }),
                  )
                }
                slotProps={{
                  textField: {
                    helperText: errors.printPeriod,
                  },
                }}
              />
            </Stack>
          </Grid>
        ) : values.season && values.season.cycle === 'consecutively' ? (
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Delay Interval</Translate>}
              defaultValue={
                season.delayInterval ? season.delayInterval.toString() : ''
              }
              name={'season[delayInterval]'}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.season && touched.season && touched.season.delayInterval
                  ? errors.season.delayInterval
                  : ''
              }
              error={
                errors.season &&
                touched.season &&
                touched.season.delayInterval &&
                Boolean(errors.season.delayInterval)
              }
            />
          </Grid>
        ) : (
          <>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                label={<Translate>Approval period</Translate>}
                defaultValue={
                  values.approvalPeriod ? values.approvalPeriod.toString() : ''
                }
                name={'approvalPeriod'}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.approvalPeriod ? errors.approvalPeriod : ''}
                error={touched.approvalPeriod && Boolean(errors.approvalPeriod)}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                label={<Translate>Print period</Translate>}
                defaultValue={
                  values.printPeriod ? values.printPeriod.toString() : ''
                }
                name={'printPeriod'}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.printPeriod ? errors.printPeriod : ''}
                error={touched.printPeriod && Boolean(errors.printPeriod)}
              />
            </Grid>
          </>
        )}
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            select
            id={'season[cycle]'}
            label={<Translate>cycle</Translate>}
            name={'season[cycle]'}
            value={
              values.season && values.season.cycle
                ? values.season.cycle
                : 'monthly'
            }
            onChange={(e) => {
              handleChange(e);
              if (e.target.value === 'consecutively') {
                props.setFieldValue('approvalPeriod', '');
                props.setFieldValue('printPeriod', '');
              }
            }}
            onBlur={handleBlur}
            helperText={
              touched.season && errors.season && touched.season.cycle
                ? errors.season.cycle
                : ''
            }
            error={
              touched.season &&
              errors.season &&
              touched.season.cycle &&
              Boolean(errors.season.cycle)
            }
          >
            <MenuItem value={'monthly'}>
              {' '}
              {<Translate>monthly</Translate>}{' '}
            </MenuItem>
            <MenuItem value={'singular'}>
              {' '}
              {<Translate>singular</Translate>}{' '}
            </MenuItem>
            <MenuItem value={'consecutively'}>
              {' '}
              {<Translate>consecutively</Translate>}{' '}
            </MenuItem>
          </TextField>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <DatePicker
            label={<Translate>Archived</Translate>}
            format="dd.MM.yyyy"
            value={
              values.season.archived ? parseDate(values.season.archived) : null
            }
            onChange={(date) =>
              setFieldValue(
                'season[archived]',
                date.toLocaleString('de-DE', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }),
              )
            }
            slotProps={{
              textField: {
                helperText: errors.printPeriod,
              },
            }}
          />
        </Grid>
        <Grid size={12}>
          <Typography variant="h6">
            <Translate>Pricing</Translate>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            id={'price'}
            label={<Translate>Dispatch</Translate>}
            defaultValue={values.price ? values.price.toString() : ''}
            name={'price'}
            onChange={handleChange}
            onBlur={(e) => {
              handleBlur(e);
              props.setFieldValue(
                'price',
                props.values.price.toString().replace(/,/g, '.'),
              );
            }}
            helperText={touched.price ? errors.price : ''}
            error={touched.price && Boolean(errors.price)}
          />
        </Grid>
        {this.renderMasterTemplate(props)}
      </>
    );
  };

  renderSeasonSelect = (props) => {
    const { seasons, season } = this.props;
    const { values, handleChange, handleBlur, touched, errors } = props;

    if (isEmpty(seasons) || season.isParent) {
      return null;
    }

    let renderSeasons = [];
    keys(seasons).map((seasonId) => {
      if (!seasons[seasonId].selection && seasons[seasonId].type === 'email') {
        renderSeasons.push(seasons[seasonId]);
      }
    });

    if (isEmpty(renderSeasons)) {
      return null;
    }

    return (
      <Grid size={{ xs: 12, sm: 6 }}>
        <TextField
          select
          id={'season[parent]'}
          label={<Translate>Parent</Translate>}
          name={'season[parent]'}
          value={
            values.season && values.season.parent ? values.season.parent : ''
          }
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={
            touched.season && errors.season && touched.season.parent
              ? errors.season.parent
              : ''
          }
          error={
            touched.season &&
            errors.season &&
            touched.season.parent &&
            Boolean(errors.season.parent)
          }
        >
          <EmptyMenuItem key="seasonNull" />
          {renderSeasons.map((season) => {
            return (
              <MenuItem key={Math.random()} value={season.id}>
                {' '}
                {season.primaryText}{' '}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
    );
  };

  renderFieldset = (props) => {
    const { values, handleChange, handleBlur, errors, touched } = props;
    let typeSpecific = this.renderSMSFieldset;

    return (
      <>
        <HiddenInput
          type="hidden"
          name="updateToken"
          defaultValue={values.updateToken ? values.updateToken : ''}
        />
        <HiddenInput
          type="hidden"
          name="season[id]"
          defaultValue={
            values.season && values.season.id ? values.season.id : ''
          }
        />
        <HiddenInput
          type="hidden"
          name="season[campaign]"
          defaultValue={
            values.season && values.season.campaign
              ? values.season.campaign
              : ''
          }
        />
        <Grid container spacing={3}>
          <Grid size={12}>
            <Typography variant="h6">
              <Translate>General</Translate>
            </Typography>
          </Grid>
          {this.renderSeasonSelect(props)}
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Config key</Translate>}
              defaultValue={
                values.season && values.season.configKey
                  ? values.season.configKey
                  : ''
              }
              name={'season[configKey]'}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                touched.season && errors.season && touched.season.configKey
                  ? errors.season.configKey
                  : ''
              }
              error={
                touched.season &&
                errors.season &&
                touched.season.configKey &&
                Boolean(errors.season.configKey)
              }
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>campaign key</Translate>}
              defaultValue={
                values.campaign && values.campaign.campaignKey
                  ? values.campaign.campaignKey
                  : ''
              }
              name={'campaign[campaignKey]'}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                touched.campaign &&
                errors.campaign &&
                touched.campaign.campaignKey
                  ? errors.campaign.campaignKey
                  : ''
              }
              error={
                touched.campaign &&
                errors.campaign &&
                touched.campaign.campaignKey &&
                Boolean(errors.campaign.campaignKey)
              }
            />
          </Grid>
          {typeSpecific(props)}
        </Grid>
      </>
    );
  };

  renderForm = () => {
    const {
      season,
      channel,
      onCancel,
      seasonType,
      onMount,
      onSubmit,
      onValidityChange,
      seasonId,
      campaignId,
      campaign,
      token,
      form,
      theme,
    } = this.props;
    let { ...defaultValues } = this.props.values;

    if (this.state.loading) {
      return <Translate>Data loading...</Translate>;
    }

    if (isEmpty(form) && !seasonId) {
      // if seasonId is missing form should not be empty
      return null;
    }

    if (seasonId && isEmpty(season)) {
      //if seasonId is set season should not be empty
      return null;
    }

    if (
      seasonId &&
      (isEmpty(channel) ||
        (seasonType === 'sms' && !('masterTemplate' in channel)))
    ) {
      return null;
    }

    if (!isEmpty(form)) {
      //if form is not empty read token and write token into defaultValues
      defaultValues.updateToken = token;
      defaultValues.campaign = campaign;
      defaultValues.season = {
        campaign: campaignId,
        id: null,
        cycle: 'monthly',
      };
    }

    let seasonTypeString = 'New sms';

    let headline = season.primaryText ? (
      season.primaryText
    ) : (
      <Translate>{seasonTypeString}</Translate>
    );
    let smsChannel,
      initialValues,
      printPeriod,
      approvalPeriod,
      printDate,
      approvalDate;

    if (seasonId) {
      if (season.cycle === 'singular') {
        if (channel.printPeriod !== null) {
          printDate = new Date(channel.printPeriod * 1000);
          printPeriod = new Intl.DateTimeFormat(theme.locale.de).format(
            new Date(printDate),
          );
        }

        if (channel.approvalPeriod !== null) {
          approvalDate = new Date(channel.approvalPeriod * 1000);
          approvalPeriod = new Intl.DateTimeFormat(theme.locale.de).format(
            new Date(approvalDate),
          );
        }
      } else {
        printPeriod = channel.printPeriod;
        approvalPeriod = channel.approvalPeriod;
      }
      let channelCopy = Object.assign({}, channel);
      smsChannel = channelCopy.masterTemplate
        ? merge(channelCopy, {
            masterTemplate: { layout: channel.masterTemplate },
            approvalPeriod: approvalPeriod,
            printPeriod: printPeriod,
          })
        : channelCopy;
      delete smsChannel.season;
      initialValues =
        !isEmpty(season) && !isEmpty(smsChannel)
          ? Object.assign({ season: season, campaign: campaign }, smsChannel)
          : defaultValues;
    } else {
      initialValues = defaultValues;
    }

    return (
      <Form
        validationSchema={validationSchema}
        onCancel={onCancel}
        headline={headline}
        onMount={onMount}
        initialValues={initialValues}
        values={season}
        onSubmit={onSubmit}
        onValidityChange={onValidityChange}
        name={seasonType}
        renderFieldset={this.renderFieldset}
      />
    );
  };

  render() {
    return <div>{this.renderForm()}</div>;
  }
}

const makeMapStateToProps = () => {
  const getSeasonById = makeSeasonById();
  const getSelectableSelections = makeSelectableSelections();

  return (state, props) => {
    const {
      entities: { season: seasons, sms: smsChannel, campaign },
      forms: { season: tokenForm },
    } = state;

    const season = getSeasonById(state, props);
    const selections = getSelectableSelections(state, props);

    let channel =
      smsChannel && season && season.sms && smsChannel[season.sms]
        ? smsChannel[season.sms]
        : null;

    return {
      campaign: campaign[props.campaignId],
      seasons: seasons ? seasons : {},
      season: season ? season : {},
      selections: selections,
      channel: channel,
      token: !isEmpty(tokenForm) ? tokenForm.updateToken : '',
      form: tokenForm,
    };
  };
};

const enhance = compose(
  withRouter,
  connect(makeMapStateToProps, {
    loadSeason,
    loadSeasons,
    loadSelections,
    outdateEntity,
    initSmsForm,
  }),
);

export default enhance(EditSms);
