import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide,
  Dialog,
  TextField,
  MenuItem,
  Input,
} from '@mui/material';
import { isEmpty } from 'lodash';
import Translate from '../../../components/service/Translate';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Form from '../../../components/Form';
import * as Yup from 'yup';
import ValidationErrors from '../../../ValidationErrors';
import { withRouter } from '../../../withRouter';
import { connect } from 'react-redux';
import { initForm } from '../actions';
import { FormCheckbox } from '../../../components/StyledElements/StyledFormElements';
import Grid from '@mui/material/Grid2';
import { compose } from 'redux';
import { HiddenInput } from '../../../components/StyledElements/StyledElements';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let validationSchema = Yup.object().shape({
  configKey: Yup.string().required(ValidationErrors.required),
  channel: Yup.string().required(ValidationErrors.required),
  force: Yup.bool(),
});

class CcsScanExport extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    open: PropTypes.bool,
    values: PropTypes.object,
  };

  static defaultProps = {
    values: { configKey: 'reifenS21', channel: 'POST', force: false },
    channel: [
      { id: 'EPOST', primaryText: 'EPOST' },
      { id: 'POST', primaryText: 'POST' },
    ],
  };

  state = {};

  componentDidMount = () => {
    this.props.initForm();
  };

  renderSelect = (item) => {
    return (
      <MenuItem key={item.id} value={item.id}>
        <Translate>{item.primaryText}</Translate>
      </MenuItem>
    );
  };

  renderFieldset = (props) => {
    const { values, touched, errors, handleBlur, handleChange, setFieldValue } =
      props;
    const { channel, onCancel } = this.props;

    return (
      <>
        <HiddenInput
          type="hidden"
          name="updateToken"
          defaultValue={values.updateToken ? values.updateToken : ''}
        />
        <Grid container spacing={3}>
          <Grid size={12}>
            <TextField
              label={<Translate>configKey</Translate>}
              name="configKey"
              defaultValue={values.configKey ? values.configKey : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.configKey ? errors.configKey : ''}
              error={touched.configKey && Boolean(errors.configKey)}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              select
              name="channel"
              label={<Translate>channel</Translate>}
              value={values.channel ? values.channel : ''}
              onChange={handleChange('channel')}
              helperText={touched.channel ? errors.channel : ''}
              error={touched.channel && Boolean(errors.channel)}
            >
              {channel.map(this.renderSelect)}
            </TextField>
          </Grid>
          <Grid size={12}>
            <FormCheckbox
              name="force"
              label={<Translate>Force</Translate>}
              checked={values?.force ?? false}
              setFieldValue={setFieldValue}
              error={touched?.force && errors?.force}
            />
          </Grid>
          <Grid size={12}>
            <DialogActions>
              <Button color="primary" type="submit">
                <Translate>Create</Translate>
              </Button>
              <Button onClick={onCancel} color="primary">
                <Translate>Cancel</Translate>
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </>
    );
  };

  render() {
    const { onSubmit, onCancel, token, form, open, values } = this.props;
    const { ...defaultValues } = this.props.values;

    if (!isEmpty(form)) {
      defaultValues.updateToken = token;
    }

    return (
      <ErrorBoundary>
        <Dialog
          PaperProps={{ sx: { p: 0, m: 0 } }}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={onCancel}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <Translate>CCS Abgleich erstellen</Translate>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {' '}
              {
                'Der Abgleich kann nach Erstellung in der nextcloud abgerufen werden.'
              }{' '}
            </DialogContentText>
            <div>
              <Form
                name="ccsScanExport"
                onSubmit={onSubmit}
                onCancel={onCancel}
                values={values}
                initialValues={defaultValues}
                disableToolbar={true}
                validationSchema={validationSchema}
                renderFieldset={this.renderFieldset}
              />
            </div>
          </DialogContent>
        </Dialog>
      </ErrorBoundary>
    );
  }
}

const makeMapStateToProps = () => {
  return (state) => {
    const {
      entities: { ccsScan },
      forms: { ccsScan: form },
    } = state;

    if (!form) {
      return {};
    }

    return {
      ccsScan: ccsScan,
      token: !isEmpty(form) ? form.updateToken : '',
      form: form,
    };
  };
};

const enhance = compose(withRouter, connect(makeMapStateToProps, { initForm }));
export default enhance(CcsScanExport);
