import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Paper,
  IconButton,
  Typography,
  Tooltip,
  Box,
  Toolbar,
  Card,
  CardHeader,
  CardContent,
  Stack,
  Divider,
  CardActions,
  Button,
  SvgIcon,
} from '@mui/material';
import CommunicationMailOutline from '@mui/icons-material/MailOutline';
import CommunicationPhone from '@mui/icons-material/Phone';
import AvPlaylistAddCheck from '@mui/icons-material/PlaylistAddCheck';
import EditorModeEdit from '@mui/icons-material/Edit';
import ProductIcon from '@mui/icons-material/ListAlt';
import LinkIcon from '@mui/icons-material/Link';
import SmsIcon from '@mui/icons-material/Sms';
import AlternateEmail from '../../../theme/icons/AlternateEmail';
import { isEmpty, isEqual, map } from 'lodash';
import Translate from '../../../components/service/Translate';
import { connect } from 'react-redux';
import { makeCampaignSeasonsByType } from '../../Campaign/selectors_deprecated';
import { withRouter } from '../../../withRouter';
import {
  Spacer,
  ColorActions,
  MultiClassSwitch,
  TableData,
} from '../../../components/StyledElements/StyledElements';
import { styled } from '@mui/system';
import { compose } from 'redux';

const Table = styled('table')({
  width: '100%',
  tableLayout: 'fixed',
  verticalAlign: 'top',
});

class SeasonList extends Component {
  static propTypes = {
    campaignId: PropTypes.number.isRequired,
    seasonType: PropTypes.string.isRequired,
    onToggle: PropTypes.func.isRequired,
    seasons: PropTypes.object,
    media: PropTypes.object,
  };

  static defaultProps = {
    docked: false,
    fitted: false,
  };

  state = {};

  shouldComponentUpdate = (nextProps) => {
    return (
      !isEqual(nextProps.seasons, this.props.seasons) ||
      !isEqual(nextProps.seasonType, this.props.seasonType) ||
      !isEqual(nextProps.campaignId, this.props.campaignId) ||
      !isEqual(nextProps.loading, this.props.loading) ||
      !isEqual(nextProps.media, this.props.media)
    );
  };

  onTouchEdit = (season) => {
    this.props.navigate(
      Routing.generate('campaign_season_edit', {
        campaignId: this.props.campaignId,
        seasonType: season.type,
        seasonId: season.id,
      }),
    );
  };

  onCreateDependency = (season) => {
    this.props.navigate(
      '/campaign/' + this.props.campaignId + '/seasoncausality/' + season.id,
    );
  };

  renderProducts = (campaignId) => {
    this.props.navigate(
      Routing.generate('product_campaign', { id: campaignId }),
    );
  };

  renderStatusLabeldIcon = (season) => {
    switch (season.type) {
      case 'email':
        return <AlternateEmail />;
      case 'print':
        return <CommunicationMailOutline />;
      case 'sms':
        return <SmsIcon />;
    }
  };

  renderMailing = (season) => {
    const mailing =
      season && season.mailing
        ? this.props.media[season.mailing]
          ? this.props.media[season.mailing]
          : null
        : null;

    if (!mailing) {
      return null;
    }

    return (
      <tbody>
        <tr>
          <TableData width="15%" color="secondaryTextColor">
            Format
          </TableData>
          <TableData width="35%">
            <Translate>{mailing.format}</Translate>
          </TableData>
          <TableData width="15%" color="secondaryTextColor">
            Briefpapier
          </TableData>
          <TableData width="35%" />
        </tr>
        <tr>
          <TableData width="15%" color="secondaryTextColor">
            Konsolidierung Kunden
          </TableData>
          <TableData width="35%">
            <Translate>{mailing.combine}</Translate>
          </TableData>
          <TableData width="15%" color="secondaryTextColor">
            Gutscheine
          </TableData>
          <TableData width="35%" />
        </tr>
        <tr>
          <TableData width="15%" color="secondaryTextColor">
            Nächster Aussand
          </TableData>
          <TableData width="35%">
            {season.enabled ? mailing.nextPrintDate : ''}
          </TableData>
          <TableData width="15%" color="secondaryTextColor">
            Letzter Aussand
          </TableData>
          <TableData width="35%" />
        </tr>
        <tr>
          <TableData width="15%" color="secondaryTextColor">
            Kommt nach
          </TableData>
          <TableData width="35%">
            {season.effects[0] ? `${season.effects[0].cause.configKey}` : ''}
          </TableData>
          <TableData width="15%" color="secondaryTextColor">
            Kommt vor
          </TableData>
          <TableData width="35%">
            {season.causes[0] ? `${season.causes[0].season.configKey}` : ''}
          </TableData>
        </tr>
      </tbody>
    );
  };

  renderSms = (season) => {
    const sms =
      season && season.sms
        ? this.props.media[season.sms]
          ? this.props.media[season.sms]
          : null
        : null;

    if (!sms) {
      return null;
    }

    return (
      <tbody>
        <tr>
          <TableData width="15%" color="secondaryTextColor">
            Absender
          </TableData>
          <TableData width="35%">{sms.masterTemplate.sender}</TableData>
          <TableData width="15%" color="secondaryTextColor">
            Text
          </TableData>
          <TableData width="35%">{sms.masterTemplate.content}</TableData>
        </tr>
        <tr>
          <TableData width="15%" color="secondaryTextColor">
            Kommt nach
          </TableData>
          <TableData width="35%">
            {season.effects[0] ? `${season.effects[0].cause.configKey}` : ''}
          </TableData>
          <TableData width="15%" color="secondaryTextColor">
            Kommt vor
          </TableData>
          <TableData width="35%">
            {season.causes[0] ? `${season.causes[0].season.configKey}` : ''}
          </TableData>
        </tr>
      </tbody>
    );
  };

  renderEmail = (season) => {
    return (
      <tbody>
        <tr>
          <TableData width="15%" color="secondaryTextColor">
            Layout-Vorlage
          </TableData>
          <TableData width="35%" />
          <TableData width="15%" color="secondaryTextColor">
            Text
          </TableData>
          <TableData width="35%" />
        </tr>
        <tr>
          <TableData width="15%" color="secondaryTextColor">
            Kommt nach
          </TableData>
          <TableData width="35%">
            {season.effects[0] ? `${season.effects[0].cause.configKey}` : ''}
          </TableData>
          <TableData width="15%" color="secondaryTextColor">
            Kommt vor
          </TableData>
          <TableData width="35%">
            {season.causes[0] ? `${season.causes[0].season.configKey}` : ''}
          </TableData>
        </tr>
      </tbody>
    );
  };

  renderToolbar = (season) => {
    const { loading, campaignId } = this.props;

    return (
      <>
        {season.type === 'email' ? (
          <span>
            <Tooltip title="bearbeiten">
              <IconButton
                aria-label={<Translate>Edit Sms</Translate>}
                onClick={() => this.onTouchEdit(season)}
                disabled={!isEmpty(loading)}
                size="large"
              >
                <EditorModeEdit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Abhängigkeit bearbeiten">
              <IconButton
                aria-label={<Translate>Abhängigkeit bearbeiten</Translate>}
                onClick={() => this.onCreateDependency(season)}
                size="large"
              >
                <LinkIcon />
              </IconButton>
            </Tooltip>
          </span>
        ) : null}
        {season.type === 'sms' ? (
          <span>
            <Tooltip title="bearbeiten">
              <IconButton
                aria-label={<Translate>Edit Sms</Translate>}
                onClick={() => this.onTouchEdit(season)}
                size="large"
              >
                <EditorModeEdit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Abhängigkeit bearbeiten">
              <IconButton
                aria-label={<Translate>Abhängigkeit bearbeiten</Translate>}
                onClick={() => this.onCreateDependency(season)}
                size="large"
              >
                <LinkIcon />
              </IconButton>
            </Tooltip>
          </span>
        ) : null}
        {season.type === 'print' ? (
          <Tooltip title="Abhängigkeit bearbeiten">
            <IconButton
              aria-label={<Translate>Abhängigkeit bearbeiten</Translate>}
              onClick={() => this.onCreateDependency(season)}
              size="large"
            >
              <LinkIcon />
            </IconButton>
          </Tooltip>
        ) : null}
        <Tooltip title="zu den Produkten">
          <IconButton
            aria-label={<Translate>zu den Produkten</Translate>}
            onClick={() => this.renderProducts(campaignId)}
            size="large"
          >
            <ProductIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="zur Selektion">
          <IconButton
            disabled={!season.selection}
            aria-label={<Translate>Selection</Translate>}
            target="_blank"
            href={`${Routing.generate('start', null, true).replace(
              /-v3/,
              '',
            )}#${Routing.generate('selection_show', { id: season.selection })}`}
            size="large"
          >
            <AvPlaylistAddCheck />
          </IconButton>
        </Tooltip>
        <Tooltip title={season.enabled ? 'stornieren' : 'beauftragen'}>
          <MultiClassSwitch
            color="primary"
            label=""
            checked={season.enabled}
            onChange={(e, isInputChecked) => {
              this.props.onToggle(season.id, isInputChecked);
            }}
          />
        </Tooltip>
      </>
    );
  };

  renderSeason = (season) => {
    const { campaign, campaignId } = this.props;
    let typeSpecific;
    let crmId =
      season.parent === null
        ? campaign[campaignId].crmId
        : campaign[campaign[campaignId].rootCampaignId].crmId;

    switch (season.type) {
      case 'email':
        typeSpecific = this.renderEmail;
        break;
      case 'print':
        typeSpecific = this.renderMailing;
        break;
      case 'sms':
        typeSpecific = this.renderSms;
        break;
    }

    return (
      <Paper sx={{ p: 3, my: 3 }} key={`season-${season.id}`}>
        <div>
          <Toolbar sx={{ p: 0, '@media (min-width: 600px)': { p: 0 } }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {this.renderStatusLabeldIcon(season)}
              <Typography variant="h5" id="tableTitle">
                <Box
                  sx={{
                    ml: 3,
                  }}
                >
                  {crmId ? `${crmId}` : `#${season.id} - ${season.configKey}`}
                </Box>
              </Typography>
            </Box>
            <Spacer />
            <ColorActions>{this.renderToolbar(season)}</ColorActions>
          </Toolbar>
        </div>
        <Table>
          <tbody>
            <tr>
              <TableData width="15%" color="secondaryTextColor">
                Beauftragt
              </TableData>
              <TableData width="35%">
                {season.enabled ? (
                  `${season.subscribed}`
                ) : (
                  <Translate>no</Translate>
                )}
              </TableData>
              <TableData width="15%" color="secondaryTextColor">
                Turnus
              </TableData>
              <TableData width="35%">
                <Translate>{season.cycle}</Translate>
              </TableData>
            </tr>
            <tr>
              <TableData width="15%" color="secondaryTextColor">
                CRM ID
              </TableData>
              <TableData width="35%">{crmId}</TableData>
              <TableData width="15%" color="secondaryTextColor">
                Config key
              </TableData>
              <TableData width="35%">{season.configKey}</TableData>
            </tr>
          </tbody>
          {typeSpecific(season)}
        </Table>
      </Paper>
    );
  };

  render() {
    const { seasons } = this.props;

    if (!seasons) {
      return null;
    }

    return <Stack spacing={3}>{map(seasons, this.renderSeason)}</Stack>;
  }
}

const makeMapStateToProps = () => {
  const getSeasonsByType = makeCampaignSeasonsByType();

  return (state, props) => {
    const {
      entities: { mailing, sms, email, campaign },
      loading: loading,
    } = state;

    const {
      params: { campaignId },
    } = props.match;

    let media = {};
    const seasonsByType = getSeasonsByType(state, props);

    switch (props.seasonType) {
      case 'email':
        media = email;
        break;
      case 'print':
        media = mailing;
        break;
      case 'sms':
        media = sms;
        break;
    }

    return {
      campaignId: campaignId ? parseInt(campaignId) : null,
      seasons: seasonsByType,
      campaign: campaign,
      media: media,
      loading: loading,
    };
  };
};

const enhance = compose(withRouter, connect(makeMapStateToProps, {}));

export default enhance(SeasonList);
