import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import Translate from './service/Translate';
import Grow from '@mui/material/Grow';
import { isEmpty } from 'lodash';

function TabPanel(props) {
  const { children, value, index, vertical, ...other } = props;

  const show = value === index;

  if (vertical) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        style={{ width: '100%' }}
      >
        {value === index && <>{children}</>}
      </div>
    );
  } else {
    return (
      <Grow in={show} mountOnEnter hidden={!show}>
        <div
          role="tabpanel"
          //hidden={value !== index} //use this if you don't use grow component
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {
            <Typography component="div" style={{ padding: 8 * 3 }}>
              {children}
            </Typography>
          }
        </div>
      </Grow>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class FullWidthTabs extends React.Component {
  state = {
    value: 0,
  };

  componentDidMount = () => {
    if (this.props.orientation === 'vertical') {
      this.setState({ tabList: this.props.tabs });
    }
  };

  handleChange = (event, value) => {
    if (value === 'add') {
      this.handleAddTab();
    } else if (value === 'delete') {
      this.handleDeleteTab();
    } else {
      this.setState({ value });
    }
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  handleAddTab = () => {
    let tabListCopy = this.props.onAddTab(this.state.tabList);

    this.setState({ tabList: tabListCopy }, () =>
      this.handleChangeIndex(tabListCopy.length - 1),
    );
  };

  handleDeleteTab = () => {
    this.props.onDelete(this.props.childProduct.id);
  };

  render() {
    const { orientation, tabs } = this.props;
    const { value, tabList } = this.state;
    let vertical = orientation === 'vertical';
    let tabss = vertical ? tabList : tabs;

    if (!isEmpty(tabss)) {
      return (
        <Box
          sx={
            vertical
              ? {
                  flexGrow: 1,
                  backgroundColor: 'background.paper',
                  display: 'flex',
                }
              : { backgroundColor: 'background.paper' }
          }
        >
          <Tabs
            value={value}
            onChange={this.handleChange}
            orientation={orientation}
            variant={orientation === 'vertical' ? 'scrollable' : 'fullWidth'}
            textColor="inherit"
          >
            {Object.values(tabss).map((tab, index) => {
              return (
                <Tab
                  key={`tab-${index}`}
                  label={<Translate>{tab.title}</Translate>}
                />
              );
            })}
          </Tabs>
          {Object.values(tabss).map((tab, index) => {
            return (
              <TabPanel
                key={index}
                value={value}
                index={index}
                vertical={vertical}
              >
                {tab.content}
              </TabPanel>
            );
          })}
        </Box>
      );
    }
    return null;
  }
}

FullWidthTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
};

export default FullWidthTabs;
