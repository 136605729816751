import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isEqual } from 'lodash';

import ErrorBoundary from '../../../components/ErrorBoundary';
import DataGridTable from '../../../components/DataGrid/DataGrid';
import RenderCellExpand from '../../../components/DataGrid/RenderCellExpand';

class EmailLayoutList extends Component {
  static propTypes = {
    layouts: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    resultset: PropTypes.array.isRequired,
    onSortModelChange: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onLoadNext: PropTypes.func.isRequired,
    initialGridState: PropTypes.object,
  };

  static defaultProps = {
    pagination: {},
    resultset: [],
  };

  state = {
    columns: [
      {
        field: 'id',
        headerName: 'ID',
        relation: null,
        type: 'number',
        width: 30,
      },
      {
        field: 'name',
        headerName: 'Name',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'created',
        headerName: 'Hinzugefügt',
        minWidth: 150,
        flex: 1,
        renderCell: (params) =>
          RenderCellExpand({ ...params, modifier: 'datetime' }),
      },
      {
        field: 'updated',
        headerName: 'Letzte Änderung',
        minWidth: 150,
        flex: 1,
        renderCell: (params) =>
          RenderCellExpand({ ...params, modifier: 'datetime' }),
      },
    ],
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.layouts, this.props.layouts) ||
      !isEqual(nextProps.pagination, this.props.pagination) ||
      !isEqual(nextProps.initialGridState, this.props.initialGridState) ||
      !isEqual(this.props.resultset, nextProps.resultset)
    );
  };

  render() {
    const {
      layouts,
      resultset,
      pagination,
      onDelete,
      onAdd,
      onEdit,
      onLoadNext,
      onSortModelChange,
      initialGridState,
    } = this.props;
    const { columns } = this.state;

    return (
      <>
        <ErrorBoundary>
          <DataGridTable
            title="E-Mail Layout"
            rows={layouts}
            iteratableRows={resultset}
            columns={columns}
            pagination={pagination}
            onEdit={onEdit}
            onAdd={onAdd}
            onDelete={onDelete}
            onSortModelChange={onSortModelChange}
            onLoadNext={onLoadNext}
            initialState={initialGridState}
          />
        </ErrorBoundary>
      </>
    );
  }
}

export default EmailLayoutList;
