import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Translate from '../../../components/service/Translate';
import {
  Table,
  TableData,
} from '../../../components/StyledElements/StyledElements';
import { Box } from '@mui/material';

class Show extends Component {
  static propTypes = {
    owner: PropTypes.object.isRequired,
  };

  render() {
    const { owner } = this.props;

    if (!owner) {
      return null;
    }

    return (
      <Box sx={{ pt: 3 }}>
        <Table width="100%">
          <tbody>
            <tr>
              <TableData width="15%">
                <Translate>Name</Translate>
              </TableData>
              <TableData width="35%">{owner.primaryText}</TableData>
              <TableData width="15%">
                <Translate>Acronym</Translate>
              </TableData>
              <TableData width="35%">{owner.code}</TableData>
            </tr>
            <tr>
              <TableData width="15%">
                <Translate>Email</Translate>
              </TableData>
              <TableData width="35%">{owner.email}</TableData>
              <TableData width="15%">
                <Translate>Mailer URL</Translate>
              </TableData>
              <TableData width="35%">{owner.mailerUrl}</TableData>
            </tr>
            <tr>
              <TableData
                sx={{
                  color: 'text.secondary',
                  width: 200,
                  pt: 3,
                  fontWeight: 500,
                }}
                colSpan="4"
              >
                <Translate>Subscriptions</Translate>
              </TableData>
            </tr>
            <tr>
              <TableData width="15%">
                <Translate>Invoice email</Translate>
              </TableData>
              <TableData width="35%">{owner.invoice}</TableData>
              <TableData width="15%">
                <Translate>Reminder email</Translate>
              </TableData>
              <TableData width="35%">{owner.reminder}</TableData>
            </tr>
            <tr>
              <TableData
                sx={{
                  color: 'text.secondary',
                  width: 200,
                  pt: 3,
                  fontWeight: 500,
                }}
                colSpan="4"
              >
                <Translate>Letter paper</Translate>
              </TableData>
            </tr>
            <tr>
              <TableData width="15%">
                <Translate>Authorisation</Translate>
              </TableData>
              <TableData width="35%" colSpan="4">
                {owner.authorisation}
              </TableData>
            </tr>
            <tr>
              <TableData
                sx={{
                  color: 'text.secondary',
                  width: 200,
                  pt: 3,
                  fontWeight: 500,
                }}
                colSpan="4"
              >
                <Translate>Bank account</Translate>
              </TableData>
            </tr>
            <tr>
              <TableData width="15%">
                <Translate>Account holder</Translate>
              </TableData>
              <TableData width="35%">{owner.accountHolder}</TableData>
              <TableData width="15%">
                <Translate>Bank</Translate>
              </TableData>
              <TableData width="35%">{owner.bank}</TableData>
            </tr>
            <tr>
              <TableData width="15%">
                <Translate>IBAN</Translate>
              </TableData>
              <TableData width="35%">{owner.iban}</TableData>
              <TableData width="15%">
                <Translate>BIC</Translate>
              </TableData>
              <TableData width="35%">{owner.bic}</TableData>
            </tr>
            <tr>
              <TableData
                sx={{
                  color: 'text.secondary',
                  width: 200,
                  pt: 3,
                  fontWeight: 500,
                }}
                colSpan="4"
              >
                <Translate>Settings</Translate>
              </TableData>
            </tr>
            <tr>
              <TableData width="15%">
                <Translate>Kurz-URL Tracking Status</Translate>
              </TableData>
              <TableData width="35%" colSpan="4">
                {owner.shortLinkTrackingActive ? 'aktiv' : 'inaktiv'}
              </TableData>
            </tr>
          </tbody>
        </Table>
      </Box>
    );
  }
}

export default Show;
