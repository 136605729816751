import React from 'react';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { de } from 'date-fns/locale/de';
import routes from './AppRoutes';
import { initStore } from './store';
import { createEeloyTheme } from './theme';

axios.defaults.params = {};
axios.defaults.params.mode = 'axios';

const store = initStore();

const router = createHashRouter(routes);
// const router = createBrowserRouter(routes)

const container = document.getElementById('root');

// const root = createRoot(container);  //if you use JavaScript
const root = createRoot(container!); // if you use TypeScript

// const theme = createEeloyTheme({ responsiveFontSizes: true });
const theme = createEeloyTheme({
  direction: 'ltr',
  contrast: 'high',
});

root.render(
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <RouterProvider
            router={router}
            fallbackElement={<div>fallback</div>}
          />
        </Provider>
      </StyledEngineProvider>
    </ThemeProvider>
  </LocalizationProvider>,
);
