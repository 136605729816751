import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toObj } from 'form-data-to-object';
import { isEqual, keyBy, isEmpty, values, pickBy } from 'lodash';
import Login from './Login';
import Translate from '../../../components/service/Translate';
import ACL from '../../../components/ACL';
import {
  loadRoles,
  initForm,
  loadStaff,
  loadPrintableCopyGroups,
} from '../actions';
import {
  Box,
  IconButton,
  Input,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Form from '../../../components/Form';
import { makeHeadLocations } from '../../Location/selectors_deprecated';
import * as Yup from 'yup';
import ValidationErrors from '../../../ValidationErrors';
import ValidationRules from '../../../ValidationRules';
import FormHelperText from '@mui/material/FormHelperText';
import { loadMailings } from '../../Mailing/actions';
import { loadAffiliates } from '../../Affiliate/actions';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { outdateEntity, resetEntity } from '../../../actions';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { loadLocations } from '../../Location/actions';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid2';
import { FormCheckbox } from '../../../components/StyledElements/StyledFormElements';
import { RestartAlt } from '@mui/icons-material';
import { HiddenInput } from '../../../components/StyledElements/StyledElements';

const Fulfilled = styled('li')({
  color: 'accent1Color',
});

const CheckCircleOutlineRoundedIconPosition = styled(
  CheckCircleOutlineRoundedIcon,
)({
  position: 'relative',
  top: '5px',
  mr: '4px',
});

const ErrorOutlineRoundedIconPosition = styled(ErrorOutlineRoundedIcon)({
  position: 'relative',
  top: '5px',
  mr: '4px',
});

const validationSchema = Yup.object().shape({
  gender: Yup.string().nullable(),
  firstname: Yup.string().nullable(),
  name: Yup.string().nullable(),
  position: Yup.string().nullable(),
  agentCode: Yup.string().nullable(),
  ifeedback: Yup.string().url(ValidationErrors.url).nullable(),
  phone: Yup.string()
    .nullable()
    .when(['printableCopy', 'staffId'], {
      is: (printableCopy) =>
        !isEmpty(printableCopy) && printableCopy.includes('sms'),
      then: () =>
        Yup.string()
          .matches(ValidationRules.phoneRegex, {
            message: ValidationErrors.phone,
          })
          .required(ValidationErrors.required),
      otherwise: () =>
        Yup.string()
          .matches(ValidationRules.phoneRegex, {
            message: ValidationErrors.phone,
          })
          .nullable(),
    }),
  email: Yup.string()
    .nullable()
    .when(['printableCopy', 'staffId'], {
      is: (printableCopy, staffId) =>
        !isEmpty(printableCopy) &&
        (printableCopy.includes('email') || staffId === null),
      then: () =>
        Yup.string()
          .email(ValidationErrors.email)
          .required(ValidationErrors.required),
      otherwise: () => Yup.string().email(ValidationErrors.email).nullable(),
    }),
  sendInitialEmail: Yup.bool(),
  language: Yup.string().nullable(),
  username: Yup.string().required(ValidationErrors.required),
  password: Yup.string()
    .min(8, ValidationErrors.passwordRule)
    .test('pwValidation', ValidationErrors.passwordRule, (pw) => {
      if (pw === '' || typeof pw === 'undefined') {
        return true;
      }

      const categories = ValidationRules.passwordRegexCategories;
      let counter = 0;

      categories.forEach((category) => {
        if (category.test(pw)) {
          counter++;
        }
      });
      return counter >= 3;
    }),
  password_confirmation: Yup.string().when('password', {
    is: (val) => !!(val && val.length > 0),
    then: () =>
      Yup.string()
        .oneOf([Yup.ref('password')], ValidationErrors.requiredPasswordConfirm)
        .required(ValidationErrors.required),
  }),
  role: Yup.string()
    .oneOf(
      [
        'ROLE_PRO_CALL',
        'ROLE_PRO_CALLMANAGER',
        'ROLE_PRO_CHECKOUT',
        'ROLE_PRO_STAFFLIMITED',
        'ROLE_PRO_STAFF',
        'ROLE_PRO_ADMIN',
        'ROLE_PRO_SUPERADMIN',
      ],
      ValidationErrors.incorrectRole,
    )
    .required(ValidationErrors.requiredSelect),
  locations: Yup.array()
    .of(
      Yup.number()
        .positive(ValidationErrors.positiveIntNumber)
        .integer(ValidationErrors.positiveIntNumber)
        .typeError(ValidationErrors.positiveIntNumber),
    )
    .required(ValidationErrors.requiredSelect),
  reminder: Yup.string().nullable(),
  invoice: Yup.string().nullable(),
  printableCopy: Yup.array()
    .of(
      Yup.string().oneOf(
        ['email', 'post', 'sms', 'contact'],
        ValidationErrors.requiredSelect,
      ),
    )
    .nullable(),
  printableCopyGroups: Yup.array().of(Yup.string()).nullable(),
  voucherPdfOffsetX: Yup.string().nullable(),
  voucherPdfOffsetY: Yup.string().nullable(),
  address: Yup.string()
    .nullable()
    .when('printableCopy', {
      is: (val) => val === 'post',
      then: () => Yup.string().required(ValidationErrors.required),
      otherwise: () => Yup.string().nullable(),
    }),
  zip: Yup.string()
    .nullable()
    .when('printableCopy', {
      is: (val) => val === 'post',
      then: () => Yup.string().required(ValidationErrors.requiredSelect),
      otherwise: () => Yup.string().nullable(),
    }),
  city: Yup.string()
    .nullable()
    .when('printableCopy', {
      is: (val) => val === 'post',
      then: () => Yup.string().required(ValidationErrors.requiredSelect),
      otherwise: () => Yup.string().nullable(),
    }),
});

class Edit extends Component {
  static propTypes = {
    staffId: PropTypes.number,
    initForm: PropTypes.func.isRequired,
    loadStaff: PropTypes.func.isRequired,
    loadPrintableCopyGroups: PropTypes.func.isRequired,
    loadMailings: PropTypes.func.isRequired,
    loadAffiliates: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func.isRequired,
    values: PropTypes.object,
    printableCopyGroups: PropTypes.array,
    token: PropTypes.string,
    style: PropTypes.object,
    comingFromProfile: PropTypes.bool,
    roles: PropTypes.array,
    loadRoles: PropTypes.func,
  };

  static defaultProps = {
    values: {
      invoice: false,
      reminder: false,
      sendInitialEmail: true,
      language: 'de',
      printableCopy: null,
    },
    style: {},
    printableCopyGroups: [],
    comingFromProfile: false,
  };

  state = {
    key: false,
    showAddress: false,
    dataLoading: false,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.setState({ key: Math.random() });

    this.props.outdateEntity('staff');

    const promises = [
      this.props.loadPrintableCopyGroups(),
      this.props.loadRoles(),
      this.props.loadLocations(
        [],
        [],
        ['affiliateHead', 'headquarter', 'primaryText'],
      ),
      this.props.loadMailings(['id', 'primaryText', 'printable']),
      this.props.loadAffiliates(null, null, null, ['id', 'primaryText']),
    ];

    if (this.props.staffId) {
      promises.push(this.props.loadStaff(this.props.staffId));
    } else {
      promises.push(this.props.initForm());
    }

    this.setState({ dataLoading: true });

    Promise.all(promises).then(
      () => {
        this.setState({
          showAddress: !isEmpty(this.props.staff.printableCopy),
          dataLoading: false,
        });
      },
      () => {
        this.setState({ dataLoading: false });
      },
    );
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.staffId, prevProps.staffId)) {
      Promise.all([this.props.loadStaff(this.props.staffId)]).then(() => {
        this.setState({
          showAddress: !isEmpty(this.props.staff.printableCopy),
        });
      });
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(this.props.values, nextProps.values) ||
      !isEqual(this.props.token, nextProps.token) ||
      !isEqual(this.state.dataLoading, nextState.dataLoading) ||
      !isEqual(this.state.showAddress, nextState.showAddress) ||
      !isEqual(this.props.roles, nextProps.roles) ||
      !isEqual(this.props.locations, nextProps.locations) ||
      !isEqual(this.props.printableCopyGroups, nextProps.printableCopyGroups) ||
      !isEqual(this.props.errors, nextProps.errors) ||
      !isEqual(this.props.touched, nextProps.touched)
    );
  };

  mapModel = (model) => {
    const furbished = Object.keys(model).reduce(function (mappedModel, key) {
      let base = mappedModel;

      if (key.indexOf('.') !== -1 && Array.isArray(model[key])) {
        const keys = key.split('.');
        let values = model[key];

        key = keys.shift();
        const objKey = keys.shift();

        values = values.map((item) => {
          return keyBy([item], function () {
            return objKey;
          });
        });

        base = values;
      } else {
        // eslint-disable-next-line no-unused-vars
        base = model[key];
      }

      return mappedModel;
    }, {});

    return toObj(furbished);
  };

  handleMailingCopyChange = (event) => {
    this.setState({ showAddress: !isEmpty(event.target.value) });
  };

  handleReset = (formikProps) => {
    Promise.all([
      formikProps.setFieldValue('printableCopy', [], false),
      formikProps.setFieldValue('printableCopyGroups', [], false),
      formikProps.setFieldValue('address', null, false),
      formikProps.setFieldValue('zip', null, false),
      formikProps.setFieldValue('city', null, false),
    ]).then(() => formikProps.validateForm());
    this.setState({ showAddress: false });
  };

  renderPrintableCopyGroups = (group) => {
    const { printableCopyGroups } = this.props;
    return (
      <MenuItem
        key={printableCopyGroups[group].id}
        value={printableCopyGroups[group].id}
      >
        <Translate>{printableCopyGroups[group].primaryText}</Translate>
      </MenuItem>
    );
  };

  renderPrint = (props) => {
    const { printableCopyGroups } = this.props;
    const { values, touched, errors, handleChange, handleBlur } = props;
    const mapping = {
      email: 'E-Mail',
      post: 'Post',
      sms: 'SMS',
      contact: 'wenn Ansprechpartner',
    };

    if (!this.props.inMbvdMode) {
      return (
        <ACL authorized={['ROLE_SUPERADMIN']} silent={true}>
          <Grid size={12}>
            <Typography variant="h6">
              <Translate>Printsettings</Translate>
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Offset X</Translate>}
              name="voucherPdfOffsetX"
              defaultValue={values.voucherPdfOffsetX}
              onChange={handleChange}
              helperText={
                touched.voucherPdfOffsetX ? errors.voucherPdfOffsetX : ''
              }
              error={
                touched.voucherPdfOffsetX && Boolean(errors.voucherPdfOffsetX)
              }
              onBlur={handleBlur}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Offset Y</Translate>}
              name="voucherPdfOffsetY"
              defaultValue={
                values.voucherPdfOffsetY ? values.voucherPdfOffsetY : ''
              }
              onChange={handleChange}
              helperText={
                touched.voucherPdfOffsetY ? errors.voucherPdfOffsetY : ''
              }
              error={
                touched.voucherPdfOffsetY && Boolean(errors.voucherPdfOffsetY)
              }
              onBlur={handleBlur}
            />
          </Grid>
          <HiddenInput type="hidden" name="printable_copy" value="[]" />
          <Grid size={12}>
            <Stack direction="row" spacing={1} alignItems="center" useFlexGap>
              <Typography variant="h6">
                <Translate>Print pattern</Translate>
              </Typography>
              <Tooltip title="Zurücksetzen">
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => this.handleReset(props)}
                >
                  <RestartAlt fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <FormControl
              error={touched.printableCopy && Boolean(errors.printableCopy)}
              fullWidth
              variant="filled"
            >
              <InputLabel>
                <Translate>Mailing copy</Translate>
              </InputLabel>
              <Select
                label={<Translate>Mailing copy</Translate>}
                name="printableCopy"
                value={values.printableCopy ? values.printableCopy : []}
                onChange={(e) => {
                  handleChange(e);
                  this.handleMailingCopyChange(e);
                }}
                onBlur={handleBlur}
                multiple
                renderValue={(selected) =>
                  selected ? selected.map((val) => mapping[val]).join(', ') : []
                }
              >
                <MenuItem value={'email'}>
                  {' '}
                  <Translate>email</Translate>
                </MenuItem>
                <MenuItem value={'post'}>
                  {' '}
                  <Translate>print</Translate>
                </MenuItem>
                <MenuItem value={'sms'}>
                  {' '}
                  <Translate>sms</Translate>
                </MenuItem>
                <MenuItem value={'contact'}>
                  {' '}
                  <Translate>contact</Translate>
                </MenuItem>
              </Select>
              <FormHelperText>
                {touched.printableCopy ? errors.printableCopy : ''}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <FormControl
              error={
                touched.printableCopyGroups &&
                Boolean(errors.printableCopyGroups)
              }
              fullWidth
              variant="filled"
            >
              <InputLabel>
                <Translate>Print pattern groups</Translate>
              </InputLabel>
              <Select
                label={<Translate>Print pattern groups</Translate>}
                name="printableCopyGroups"
                value={
                  values.printableCopyGroups ? values.printableCopyGroups : []
                }
                renderValue={(selected) => {
                  let values = [];
                  selected.map((idx) =>
                    values.push(
                      Object.values(
                        pickBy(printableCopyGroups, (el) => el.id === idx),
                      )[0].primaryText,
                    ),
                  );
                  return values.join(', ');
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                multiple
              >
                {Object.keys(printableCopyGroups).map(
                  this.renderPrintableCopyGroups,
                )}
              </Select>
              <FormHelperText>
                {touched.printableCopyGroups ? errors.printableCopyGroups : ''}
              </FormHelperText>
            </FormControl>
          </Grid>
        </ACL>
      );
    }

    return (
      <ACL authorized={['ROLE_SUPERADMIN']} silent={true}>
        <Grid size={12}>
          <Stack direction="row" spacing={1} alignItems="center" useFlexGap>
            <Typography variant="h6">
              <Translate>Print pattern</Translate>
            </Typography>
            <Tooltip title="Zurücksetzen">
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => this.handleReset(props)}
              >
                <RestartAlt fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormControl
            error={touched.printableCopy && Boolean(errors.printableCopy)}
            fullWidth
            variant="filled"
          >
            <InputLabel>
              <Translate>Mailing copy</Translate>
            </InputLabel>
            <Select
              label={<Translate>Mailing copy</Translate>}
              name="printableCopy"
              value={values.printableCopy ? values.printableCopy : []}
              onChange={(e) => {
                handleChange(e);
                this.handleMailingCopyChange(e);
              }}
              onBlur={handleBlur}
              multiple
              renderValue={(selected) =>
                selected ? selected.map((val) => mapping[val]).join(', ') : []
              }
            >
              <MenuItem value={'email'}>
                {' '}
                <Translate>email</Translate>
              </MenuItem>
              <MenuItem value={'post'}>
                {' '}
                <Translate>print</Translate>
              </MenuItem>
              <MenuItem value={'sms'}>
                {' '}
                <Translate>sms</Translate>
              </MenuItem>
              <MenuItem value={'contact'}>
                {' '}
                <Translate>contact</Translate>
              </MenuItem>
            </Select>
            <FormHelperText>
              {touched.printableCopy ? errors.printableCopy : ''}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormControl
            error={
              touched.printableCopyGroups && Boolean(errors.printableCopyGroups)
            }
            fullWidth
            variant="filled"
          >
            <InputLabel>
              <Translate>Print pattern groups</Translate>
            </InputLabel>
            <Select
              label={<Translate>Print pattern groups</Translate>}
              name="printableCopyGroups"
              value={
                values.printableCopyGroups ? values.printableCopyGroups : []
              }
              renderValue={(selected) => {
                let values = [];
                selected.map((idx) =>
                  values.push(
                    Object.values(
                      pickBy(printableCopyGroups, (el) => el.id === idx),
                    )[0].primaryText,
                  ),
                );
                return values.join(', ');
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              multiple
            >
              {Object.keys(printableCopyGroups).map(
                this.renderPrintableCopyGroups,
              )}
            </Select>
            <FormHelperText>
              {touched.printableCopyGroups ? errors.printableCopyGroups : ''}
            </FormHelperText>
          </FormControl>
        </Grid>
      </ACL>
    );
  };

  renderAddress = (props) => {
    const { showAddress } = this.state;
    const { values, handleChange, handleBlur, errors, touched } = props;

    if (showAddress) {
      return (
        <>
          <Grid size={12}>
            <Typography variant="h6">
              <Translate>Contact mailing copy</Translate>
            </Typography>
          </Grid>
          <Grid size={12}>
            <TextField
              label={<Translate>Address</Translate>}
              name="address"
              defaultValue={values.address ? values.address : ''}
              onChange={handleChange}
              helperText={touched.address ? errors.address : ''}
              error={touched.address && Boolean(errors.address)}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>ZIP</Translate>}
              name="zip"
              defaultValue={values.zip ? values.zip : ''}
              onChange={handleChange}
              helperText={touched.zip ? errors.zip : ''}
              error={touched.zip && Boolean(errors.zip)}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>City</Translate>}
              name="city"
              defaultValue={values.city ? values.city : ''}
              onChange={handleChange}
              helperText={touched.city ? errors.city : ''}
              error={touched.city && Boolean(errors.city)}
              onBlur={handleBlur}
            />
          </Grid>
        </>
      );
    }

    return null;
  };

  renderFieldset = (props) => {
    const { bindSubmitForm, comingFromProfile } = this.props;
    const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
      props;

    if (comingFromProfile) {
      bindSubmitForm(props.submitForm);
    }

    let fulfilled = [false, false, false]; //[length >=8, categoriesFulfilled >=3, password_confirmation]

    const pw = values.password;
    const pw_confirm = values.password_confirmation;

    if (comingFromProfile) {
      if (pw === '' || typeof pw === 'undefined') {
        fulfilled = [false, false, false];
      } else {
        if (pw.length >= 8) {
          fulfilled[0] = true;
        }

        const categories = ValidationRules.passwordRegexCategories;
        let counter = 0;

        categories.forEach((category) => {
          if (category.test(pw)) {
            counter++;
          }
        });
        if (counter >= 3) {
          fulfilled[1] = true;
        }

        if (pw === pw_confirm) {
          fulfilled[2] = true;
        }
      }
    }

    return (
      <>
        <HiddenInput
          type="hidden"
          name="updateToken"
          defaultValue={values.updateToken ? values.updateToken : ''}
        />
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              select
              name="gender"
              label={<Translate>gender</Translate>}
              value={values.gender ? values.gender : ''}
              onChange={handleChange('gender')}
              onBlur={handleBlur}
              helperText={touched.gender ? errors.gender : ''}
              error={touched.gender && Boolean(errors.gender)}
            >
              <MenuItem value={'female'}>Frau </MenuItem>
              <MenuItem value={'male'}>Herr </MenuItem>
              <MenuItem value={'company'}>Firma </MenuItem>
            </TextField>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>firstname</Translate>}
              name="firstname"
              defaultValue={values.firstname ? values.firstname : ''}
              onChange={handleChange}
              helperText={touched.firstname ? errors.firstname : ''}
              error={touched.firstname && Boolean(errors.firstname)}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>name</Translate>}
              defaultValue={values.name ? values.name : ''}
              name="name"
              onChange={handleChange}
              helperText={touched.name ? errors.name : ''}
              error={touched.name && Boolean(errors.name)}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>position</Translate>}
              name="position"
              defaultValue={values.position ? values.position : ''}
              onChange={handleChange}
              helperText={touched.position ? errors.position : ''}
              error={touched.position && Boolean(errors.position)}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>agent code</Translate>}
              name="agentCode"
              defaultValue={values.agentCode ? values.agentCode : ''}
              onChange={handleChange}
              helperText={touched.agentCode ? errors.agentCode : ''}
              error={touched.agentCode && Boolean(errors.agentCode)}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>iFeedback Link</Translate>}
              name="ifeedback"
              defaultValue={values.ifeedback ? values.ifeedback : ''}
              onChange={handleChange}
              helperText={touched.ifeedback ? errors.ifeedback : ''}
              error={touched.ifeedback && Boolean(errors.ifeedback)}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid size={12}>
            <Typography variant="h6">
              <Translate>Contact</Translate>
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>phone</Translate>}
              defaultValue={values.phone ? values.phone : ''}
              name="phone"
              onChange={handleChange}
              helperText={touched.phone ? errors.phone : ''}
              error={touched.phone && Boolean(errors.phone)}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>email</Translate>}
              defaultValue={values.email ? values.email : ''}
              name="email"
              onChange={handleChange}
              helperText={touched.email ? errors.email : ''}
              error={touched.email && Boolean(errors.email)}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              select
              name="language"
              label={<Translate>language</Translate>}
              value={values.language ? values.language : 'de'}
              onChange={handleChange('language')}
              helperText={touched.language ? errors.language : ''}
              error={touched.language && Boolean(errors.language)}
            >
              <MenuItem value={'de'}>
                <Translate>de</Translate>
              </MenuItem>
              <MenuItem value={'en'}>
                <Translate>en</Translate>
              </MenuItem>
            </TextField>
          </Grid>
          {!this.props.staffId ? (
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormCheckbox
                name="sendInitialEmail"
                label={<Translate>Send Email to User</Translate>}
                checked={values?.sendInitialEmail ?? false}
                setFieldValue={setFieldValue}
                error={touched?.sendInitialEmail && errors?.sendInitialEmail}
              />
            </Grid>
          ) : null}
          <Grid size={12}>
            <Typography variant="h6">
              <Translate>Login</Translate>
            </Typography>
          </Grid>
          {comingFromProfile ? (
            <Grid size={12}>
              <Typography variant="body">
                <Translate>
                  Please do not pass on your access data to third parties and
                  keep them under lock and key. We recommend the use of
                  so-called password safes. Do not use simple, easy-to-guess
                  combinations for the password.
                </Translate>
              </Typography>
            </Grid>
          ) : null}
          <Grid size={12}>
            <TextField
              label={<Translate>username</Translate>}
              name="username"
              defaultValue={values.username ? values.username : ''}
              onChange={handleChange}
              helperText={touched.username ? errors.username : ''}
              error={touched.username && Boolean(errors.username)}
              onBlur={handleBlur}
            />
          </Grid>
          {comingFromProfile ? (
            <>
              <Grid size={{ xs: 12, sm: 6 }}>
                <TextField
                  label={<Translate>password</Translate>}
                  name="password"
                  type="password"
                  defaultValue={values.password ? values.password : ''}
                  onChange={handleChange}
                  helperText={touched.password ? errors.password : ''}
                  error={touched.password && Boolean(errors.password)}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <TextField
                  label={<Translate>confirm password</Translate>}
                  name="password_confirmation"
                  type="password"
                  defaultValue={''}
                  onChange={handleChange}
                  helperText={
                    touched.password_confirmation
                      ? errors.password_confirmation
                      : ''
                  }
                  error={
                    touched.password_confirmation &&
                    Boolean(errors.password_confirmation)
                  }
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid size={12}>
                <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
                  {fulfilled[0] ? (
                    <Fulfilled>
                      <CheckCircleOutlineRoundedIconPosition />
                      <Typography variant="body">
                        <Translate>
                          The password must contain at least 8 characters.
                        </Translate>
                      </Typography>
                    </Fulfilled>
                  ) : (
                    <li>
                      <ErrorOutlineRoundedIconPosition />
                      <Typography variant="body">
                        <Translate>
                          The password must contain at least 8 characters.
                        </Translate>
                      </Typography>
                    </li>
                  )}
                  {fulfilled[1] ? (
                    <Fulfilled>
                      <CheckCircleOutlineRoundedIconPosition />
                      <Typography variant="body">
                        <Translate>
                          The password must contain at least 3 of the following
                          4 categories: capitals, lower case, numbers, special
                          characters.
                        </Translate>
                      </Typography>
                    </Fulfilled>
                  ) : (
                    <li>
                      <ErrorOutlineRoundedIconPosition />
                      <Typography variant="body">
                        <Translate>
                          The password must contain at least 3 of the following
                          4 categories: capitals, lower case, numbers, special
                          characters.
                        </Translate>
                      </Typography>
                    </li>
                  )}
                  {fulfilled[2] ? (
                    <Fulfilled>
                      <CheckCircleOutlineRoundedIconPosition />
                      <Typography variant="body">
                        <Translate>Passwords must match.</Translate>
                      </Typography>
                    </Fulfilled>
                  ) : (
                    <li>
                      <ErrorOutlineRoundedIconPosition />
                      <Typography variant="body">
                        <Translate>Passwords must match.</Translate>
                      </Typography>
                    </li>
                  )}
                </ul>
              </Grid>
            </>
          ) : (
            <ACL authorized={['ROLE_ADMIN']} silent={true}>
              <Login
                comingFromProfile={comingFromProfile}
                formikProps={props}
                values={values}
                touched={touched}
                errors={errors}
                handleChange={handleChange}
              />
            </ACL>
          )}
          <ACL authorized={['ROLE_STAFF', 'ROLE_PRO']} silent={true}>
            <Grid size={12}>
              <Typography variant="h6">
                <Translate>Subscriptions</Translate>
              </Typography>
            </Grid>
            <Grid size={12}>
              <Box sx={{ display: 'flex' }}>
                <FormCheckbox
                  name="invoice"
                  label={<Translate>Invoice email</Translate>}
                  checked={values?.invoice ?? false}
                  setFieldValue={setFieldValue}
                  error={touched?.invoice && errors?.invoice}
                />
                <FormCheckbox
                  name="reminder"
                  label={<Translate>Reminder email</Translate>}
                  checked={values?.reminder ?? false}
                  setFieldValue={setFieldValue}
                  error={touched?.reminder && errors?.reminder}
                />
              </Box>
            </Grid>
            {this.renderPrint(props)}
            {this.renderAddress(props)}
          </ACL>
        </Grid>
      </>
    );
  };

  render() {
    const {
      staff,
      token,
      onSubmit,
      OnMount,
      comingFromProfile,
      roles,
      locations,
      staffId,
      form,
      onCancel,
    } = this.props;
    const { ...defaultValues } = this.props.values;
    const { dataLoading } = this.state;

    if (dataLoading) {
      return <Translate>Data loading...</Translate>;
    }

    if (staffId && isEmpty(staff)) {
      // if staffId is set staff should not be empty
      return null;
    }

    if (!staffId && isEmpty(form)) {
      // if staffId is missing form should not be empty
      return null;
    }

    if (isEmpty(roles) || isEmpty(locations)) {
      return null;
    }

    if (!isEmpty(form)) {
      //if form is not empty read token and write token into defaultValues
      defaultValues.updateToken = token;
    }

    defaultValues.staffId = staffId;

    let headline = !isEmpty(staff) ? (
      <div>
        <Translate>Staff</Translate> {staff.primaryText}
      </div>
    ) : (
      <Translate>New staff</Translate>
    );

    if (comingFromProfile) {
      delete staff.password;
    }

    return (
      <Form
        disableToolbar={comingFromProfile}
        onCancel={onCancel}
        validationSchema={validationSchema}
        headline={headline}
        onMount={OnMount}
        onSubmit={onSubmit}
        initialValues={!isEmpty(staff) ? staff : defaultValues}
        name="staff"
        renderFieldset={this.renderFieldset}
        values={staff}
      />
    );
  }
}

function makeMapStateToProps(state, ownProps) {
  const getHeadLocations = makeHeadLocations();

  return (state, props) => {
    const staffId = ownProps.staffId;
    const {
      entities: {
        staff,
        printableCopyGroup,
        role,
        config: { config: config },
        owner,
      },
      loading: loading,
      forms: { staff: form },
    } = state;

    if (staff && staffId && staff[staffId]) {
      return {
        inMbvdMode: owner[config.owner].seedsMode === 'mbvd',
        staff: staff[staffId],
        token: !isEmpty(form) ? form.updateToken : '',
        form: form,
        printableCopyGroups: printableCopyGroup
          ? Object.values(printableCopyGroup)
          : [],
        roles: values(role),
        locations: values(getHeadLocations(state, props)),
        loading: loading,
      };
    }

    return {
      inMbvdMode: owner[config.owner].seedsMode === 'mbvd',
      staff: staff[staffId] ? staff[staffId] : {},
      token: !isEmpty(form) ? form.updateToken : '',
      form: form,
      printableCopyGroups: printableCopyGroup
        ? Object.values(printableCopyGroup)
        : [],
      roles: values(role),
      locations: values(getHeadLocations(state, props)),
      loading: loading,
    };
  };
}

export default connect(makeMapStateToProps, {
  loadMailings,
  loadAffiliates,
  loadRoles,
  loadLocations,
  initForm,
  loadStaff,
  loadPrintableCopyGroups,
  outdateEntity,
  resetEntity,
})(Edit);
