import { schema } from 'normalizr';

export const CustomerList = new schema.Entity(
  'customerList',
  {},
  { idAttribute: (customerList) => customerList.id },
);
export const CustomerListCustomer = new schema.Entity(
  'customerListCustomer',
  {},
  { idAttribute: (customerListCustomer) => customerListCustomer.id },
);
