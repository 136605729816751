import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box, Button } from '@mui/material';
import Translate from '../service/Translate';

class Upload extends Component {
  static propTypes = {
    label: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
  };

  static defaultProps = {
    style: {},
  };

  handleMount = (upload) => {
    this.upload = upload;
  };

  handleClick = () => {
    if (this.upload) {
      this.upload.click();
    }
  };

  render() {
    const { label, name, setFieldValue } = this.props;

    return (
      <Box>
        <label htmlFor={name}>
          <input
            style={{ display: 'none' }}
            id={name}
            multiple
            name={name}
            type="file"
            onChange={(event) => {
              setFieldValue(name, event.currentTarget.files[0]);
            }}
          />
          <Button
            variant="contained"
            color="primary"
            component="span"
            sx={(theme) => ({
              mt: 3,
              mb: 3,
              width: '100%',
              backgroundColor: 'buttonColor',
              color: 'white',
              '&:hover': {
                background: 'buttonColorHover',
              },
            })}
          >
            <Translate>{label}</Translate>
          </Button>
        </label>
      </Box>
    );
  }
}

export default Upload;
