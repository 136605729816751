export default {
  app: {
    en: 'Englisch',
    de: 'Deutsch',
    female: 'Frau',
    male: 'Herr',
    company: 'Firma',
    false: 'nein',
    true: 'ja',
    yes: 'ja',
    no: 'nein',
    passengercar: 'PKW',
    truck: 'LKW',
    warranty: 'Garantie',
    idle: 'ungenutzt/inaktiv',
    service_teile_partner: 'Service- & TeilePartner',
    transporter: 'Transporter',
    trailer: 'Anhänger',
    bus: 'Bus',
    other: 'Sonstige',
    completed: 'abgeschlossen',
    ineffective: 'nicht erfolgreich',
    ongoing: 'in Bearbeitung',
    unsolicited: 'nicht erfolgreich',
    ROLE_PRO_SUPERADMIN: 'SuperAdministrator',
    ROLE_FREE_SUPERADMIN: 'SuperAdministrator',
    ROLE_PRO_CHECKOUT: 'Kasse',
    ROLE_PRO_STAFFLIMITED: 'Mitarbeiter (eingeschränkt)',
    ROLE_PRO_STAFF: 'Mitarbeiter',
    ROLE_PRO_ADMIN: 'Adminstrator',
    ROLE_FREE_CHECKOUT: 'Kasse',
    ROLE_FREE_STAFFLIMITED: 'Mitarbeiter (eingeschränkt)',
    ROLE_FREE_STAFF: 'Mitarbeiter',
    ROLE_FREE_ADMIN: 'Adminstrator',
    personal: 'Entschuldigung vor Ort',
    voucher: 'Gutschrift oder Gutschein (Wert) ausstellen',
    followup: 'Nacharbeit erforderlich / neuer Termin vereinbaren',
    letter: 'Entschuldigung durch Brief',
    adherence: 'Termintreue',
    garagequality: 'Werkstattqualität',
    staff: 'Freundlichkeit/Personal',
    productquality: 'Produktqualität',
    price: 'Preis / Leistung',
    created: 'hinzugefügt',
    Settings: 'Einstellungen',
    Affiliates: 'Niederlassung',
    Affiliate: 'Niederlassungen',
    affiliate: 'NDL',
    Staff: 'Mitarbeiter',
    Car: 'Fahrzeug',
    Customer: 'Kunde',
    add: 'hinzufügen',
    'edit/remove': 'editieren / entfernen',
    cancel: 'abbrechen',
    remove: 'löschen',
    edit: 'bearbeiten',
    delete: 'löschen',
    save: 'speichern',
    'No entries found.': 'Es konnten keine Einträge gefunden werden.',
    'No customers found.': 'Es wurden keine Kunden gefunden.',
    'Interface ID': 'VFNR',
    Email: 'E-Mail',
    Emails: 'E-Mails',
    Website: 'Webseite',
    Name: 'Name',
    Created: 'hinzugefügt',
    Updated: 'letzte Aktualisierung',
    Date: 'Datum',
    Activity: 'Aktion',
    'Are you sure?': 'Sind Sie sicher?',
    'Do you really want to remove %current.primaryText%?':
      'Wollen Sie %current.primaryText% wirklich löschen?',
    'Do you really want to remove %affiliate.name%?':
      'Wollen Sie die Filiale %affiliate.name% wirklich löschen?',
    'Do you really want to remove the location %location.name%?':
      'Wollen Sie den Standort %location.name% wirklich löschen?',
    'Do you really want to remove staff %staff.name%?':
      'Wollen Sie den Mitarbeiter %staff.name% wirklich löschen?',
    'Do you really want to remove module %mailingModule.name%?':
      'Wollen Sie den Baustein %mailingModule.name% wirklich löschen?',
    'Do you really want to remove the Affiliate Setting: %affiliateSetting.type%?':
      'Wollen Sie die Einstellung %affiliateSetting.type% wirklich entfernen?',
    Logout: 'Abmelden',
    Profile: 'Profil',
    gender: 'Anrede',
    firstname: 'Vorname',
    name: 'Name',
    'agent code': 'Kürzel',
    location: 'Standort',
    Location: 'Standort',
    'Location and contact': 'Standort & Kontakt',
    position: 'Position',
    'last login': 'letzter Login',
    username: 'Benutzername',
    password: 'Passwort',
    'repeat password': 'Passwort wiederholen',
    'Car dealership': 'Autohaus',
    Locations: 'Standorte',
    address: 'Adresse',
    zip: 'PLZ',
    city: 'Ort',
    phone: 'Telefon',
    language: 'Sprache',
    affiliateHead: 'Hauptsitz-Niederlassung',
    headquarter: 'Hauptsitz',
    ZIP: 'PLZ',
    City: 'Ort',
    Phone: 'Telefon',
    Contact: 'Kontakt',
    Login: 'Zugangsdaten',
    Address: 'Adresse',
    'Use web as QR code?': 'URL als QR-Code verwenden?',
    Web: 'Webseite',
    'Form letter': 'Serienbrief',
    'Complimentary close': 'Grußformel',
    'Affiliate headquarter?': 'Hauptsitz der Filiale?',
    'Car dealership headquarter?': 'Hauptsitz des Autohauses?',
    updated: 'letzte Änderung',
    email: 'E-Mail',
    www: 'Webseite',
    'You do not have sufficient rights.':
      'Leider haben Sie nicht ausreichend Rechte um diesen Bereich zu sehen.',
    'Not found': 'Upps! Diese Seite wurde leider nicht gefunden.',
    'No authorisation':
      'Huch, was machen Sie denn hier? Der Zugriff zu dieser Seite wurde Ihnen versperrt.',
    e403: 'Wenn Sie eine URL eingetippt haben, stellen Sie bitte sicher, dass sich keine Tippfehler eingeschlichen haben.<br /><br />Sollten Sie dennoch nicht auf Ihre erwartete Information gestoßen sein, rufen Sie uns doch einfach an unter 0661 4609010 oder schreiben Sie uns eine Mail an <a href="mailto:hallo@eeloy.com">hallo@eeloy.com</a>.',
    e404: 'Wenn Sie eine URL eingetippt haben, stellen Sie bitte sicher, dass sich keine Tippfehler eingeschlichen haben.<br /><br />Sollten Sie dennoch nicht auf Ihre erwartete Information gestoßen sein, rufen Sie uns doch einfach an unter 0661 4609010 oder schreiben Sie uns eine Mail an <a href="mailto:hallo@eeloy.com">hallo@eeloy.com</a>.',
    Months: 'Monate',
    months: 'Monate',
    'Upload for %values.primaryText% (%mode%)':
      'Datentransfer für %values.primaryText% (%mode%)',
    'Upload data': 'Datentransfer',
    'XLS/CSV file': 'XLS/CSV Datei',
    Abstract: 'Übersicht',
    'Bank account': 'Bankverbindung',
    Acronym: 'Abkürzung',
    Subscriptions: 'Abonnements',
    'Campaign Tracking Status': 'Kampagnen Tracking',
    Print: 'Druck',
    Printsettings: 'Drucker Einstellung',
    'Invoice email': 'Rechnung',
    'Reminder email': 'Freigabe AutoSelect',
    'Postcard template': 'Postkarte',
    'Letter paper': 'Briefpapier',
    Letter: 'Briefpapier',
    Authorisation: 'Autorisierung',
    'Account holder': 'Kontoinhaber',
    Sender: 'Absender',
    Transmitter: 'Übertragung',
    Addressee: 'Empfänger',
    'Information block (sidebar)': 'Informationsblock (rechte Spalte)',
    Business: 'Geschäftsbezeichnung',
    'Custom letter paper (replace car dealership settings)':
      'Briefpapier (überschreibt Einstellung des Autohauses)',
    'Has custom letter paper?': 'Individuelles Briefpapier?',
    'Your download is starting!': 'Ihr Download startet in einem Augenblick!',
    'Config key': 'Config Schlüssel',
    'Postbox ZIP': 'PLZ Postfach',
    Postbox: 'Postfach',
    'Postbox City': 'Postfach Ort',
    tag: 'Ausprägung',
    Tag: 'Ausprägung',
    Tags: 'Ausprägungen',
    'Filter Tags': 'Filter Tags',
    'represented by': 'vertreten durch',
    organisationId: 'VFNR',
    cv: 'Nfz.',
    mvn: 'MVN GmbH',
    pc: 'PKW',
    sg: 'smart',
    mvp: 'MVP GmbH',
    anota: 'Anota GmbH',
    'Address window': 'Adressfenster',
    'Premium tag': 'Premium label - Tag',
    Sidebar: 'Informationsleiste (rechts)',
    Headline: 'Überschrift',
    'Running text': 'Fließtext',
    'Running text (HTML)': 'Fließtext - HTML Version',
    'Running text (Text)': 'Fließtext - Text Version',
    Footer: 'Fußbereich',
    enabled: 'Aktiviert',
    subscribed: 'am',
    'template status': 'Template Status',
    preparation: 'nicht freigegeben',
    approved: 'freigegeben',
    blocked: 'gesperrt',
    'Appointment contact': 'Terminvereinbarung',
    'Showing locations below title?': 'Standorte aufzählen?',
    'Showing city ahead of date?': 'Ort vor Datum ausgeben?',
    'Premium address?': 'Premiumadress?',
    'Mailing copy': 'Belegexemplar',
    always: 'immer',
    never: 'nie',
    none: 'keine',
    print: 'Post',
    post: 'Post',
    sms: 'SMS',
    contact: 'wenn Ansprechpartner',
    'Contact mailing copy': 'Adresse Belegexemplar',
    'Position location list': 'Sortierung Standortliste',
    'Signature (format: pdf-x3)': 'Unterschrift (Format: pdf-x3)',
    Signature: 'Unterschrift',
    'Mailing settings': 'Mailing Einstellungen',
    'Signature - select a Mailing': 'Unterschrift - Mailing auswählen',
    Width: 'Breite',
    Height: 'Höhe',
    Legend: 'Legende',
    'Offset x': 'Versatz Legende - x',
    'Offset y': 'Versatz Legende - y',
    'Position of signatures': 'Reihenfolge Unterschriften',
    'Order no.': 'Auftragsnr.',
    'Invoice no.': 'Rechnungsnr.',
    'Date of order': 'Auftragsstellung',
    'Date of invoice': 'Rechnungsstellung',
    Sum: 'Summe',
    Agent: 'Berater',
    'Invoice no.: %number%, Date of invoice: %printed%, Sum: ':
      'Rechnungsnr.: %number%, Rechnungsstellung: %printed%, Summe: ',
    'deleted customer': 'gelöschter Kunde',
    'added to selection': 'zur Selektion hinzugefügt',
    'deleted.StaffFirstname': 'gelöschter',
    'deleted.StaffFirstname deleted.StaffName': 'gelöschter Mitarbeiter',
    'deleted.StaffName': 'Mitarbeiter',
    'deleted.Affiliate': 'gelöschte Niederlassung',
    'deleted.Brand': 'gelöschter Hersteller',
    'deleted.Campaign': 'gelöschte Kampagne',
    'deleted.Car': 'A-Z 123 (gelöschtes Fahrzeug)',
    'deleted.CustomerFirstname': 'gelöschter',
    'deleted.CustomerFirstname deleted.CustomerName': 'gelöschter Kunde',
    'deleted.CustomerName, deleted.CustomerFirstname': 'Kunde, gelöscht',
    'deleted.CustomerName': 'Kunde',
    'deleted.Letter': 'gelöschtes Briefpapier',
    'deleted.Location': 'gelöschter Standort',
    'deleted.Mailing': 'gelöschtes Mailing',
    'deleted.Season': 'gelöschte Kampagne',
    'deleted.Selection': 'gelöschte Selection',
    'deleted.Voucher': 'gelöschte Gutscheinvorlage',
    'deleted.VoucherCustomer': 'gelöschter Gutschein',
    'deleted.VoucherSet': 'gelöschtes Gutscheinset',
    'License number': 'Kennzeichen',
    'License number: %licenseNumber%, Registration date: %registrationDate%, Last maintenance: %lastMaintenance%':
      'Kennzeichen: %licenseNumber%, Erstzulassung: %registrationDate%, Letzter Werkstattbesuch: %lastMaintenance%',
    'Registration date': 'Erstzulassung',
    'Last maintenance': 'Letzter Werkstattbesuch',
    Classification: 'Klassifizierung',
    'Location (used for every location if blank)':
      'Standort (gültig für alle wenn leer)',
    'Affiliate (used for every affiliate if blank)':
      'Niederlassung (gültig für alle wenn leer)',
    'Use as fallback': 'als Fallback verwenden',
    'Mailing modules': 'Bausteine',
    'Size & position': 'Größe & Position',
    Module: 'Baustein',
    Font: 'Schrift',
    Border: 'Rahmen',
    Style: 'Typ',
    Size: 'Größe',
    'Color (cmyk)': 'Farbe (cmyk)',
    SeasonJob: 'Aussand',
    'entire network': 'gesamter Verbund',
    'selection upload': 'Selektion (xls/csv)',
    'CCS upload (csv)': 'CCS Abgleich',
    'Upload CCS denied list': 'Ergebnis CCS Abgleich importieren',
    'CCS scan template': 'Vorlage CCS Abgleich',
    'Import data': 'Selektion importieren (nur Demo-Daten)',
    Download: 'exportieren',
    'Upload file %type%': 'Datei auswählen (%type%)',
    embedded: 'im Fließtext',
    'Embedded?': 'Im Fließtext?',
    'Stand alone?': 'eigenständige Seite?',
    Shape: 'Form',
    rectangle: 'Rechteck',
    circle: 'Kreis',
    solid: 'durchgehend',
    dashed: 'gestrichelt',
    dotted: 'gepunktet',
    Alignment: 'Ausrichtung',
    left: 'linksbündig',
    center: 'zentriert',
    right: 'rechtsbündig',
    'Template mode': 'Typ',
    'flip side': 'Rückseite',
    positionable: 'frei positionierbar',
    autonomous: 'eigenständige Seite',
    'Reply to': 'zu Händen',
    'header.help': 'Hilfe',
    type: 'Typ',
    configKey: 'Konfigurations ID',
    channel: 'Medium',
    hasChildren: 'Hat untergeordnete Kampagnen',
    isChild: 'Konsolidiert?',
    Consolidation: 'Konsolidiert',
    'Campaign date': 'Aktionsmonat',
    Page: 'Seite',
    pageDefault: 'erste Seite',
    pagePage2: 'zweite Seite',
    pagePage3: 'dritte Seite',
    pagePage4: 'vierte Seite',
    pageBack: 'Rückseite (Brief)',
    pageOutside: 'Rückseite (Postkarte)',
    logAction_insert: 'angelegt',
    logAction_update: 'aktualisiert',
    logAction_delete: 'entfernt',
    'Entity %entity% with ID %id% has been %mode%.':
      '%entity% mit der ID <b>%id%</b> wurde <b>%mode%</b>.',
    logCar: 'Das <b>Fahrzeug</b>',
    logVoucherCustomer: 'Der <b>Gutschein</b>',
    logCustomerListCustomer: 'Der <b>Empfänger (Mailing/Telefonie)</b>',
    logAffiliate: 'Die <b>Niederlassung</b>',
    logCampaign: 'Die <b>Kampagne</b>',
    logCustomer: 'Der <b>Kunde</b>',
    logLocation: 'Der <b>Standort</b>',
    logMailing: 'Das <b>Mailing</b>',
    logMailingModule: 'Der <b>Mailingbaustein</b>',
    logMailingTemplate: 'Die <b>Mailingvorlage</b>',
    logOwner: 'Das <b>Autohaus</b>',
    logSeason: 'Die <b>Aktion</b>',
    logSelectionCriterion: 'Das <b>Kriterium</b>',
    logSignature: 'Die <b>Unterschrift</b>',
    logStaff: 'Der <b>Mitarbeiter</b>',
    logUpload: 'Der <b>Upload</b>',
    logVoucher: 'Die <b>Gutscheinvorlage</b>',
    Overview: 'Übersicht',
    'Activity report': 'Tätigkeitsbericht',
    month: 'Monat',
    tries: 'Anrufversuche',
    todo: 'noch offen',
    pipe: 'zur Freigabe',
    successful: 'erreicht',
    rate: 'Quote',
    'monthly statistics': 'Statistik nach Monaten',
    finished: 'beendet',
    day: 'Tag',
    more: 'mehr',
    less: 'weniger',
    Orienatation: 'Ausrichtung',
    Rotation: 'Drehung',
    Manufactor: 'Hersteller',
    revision: 'Revision',
    uploaded: 'hochgeladen',
    processing: 'in Bearbeitung',
    imported: 'importiert',
    denied: 'abgelehnt',
    Selection: 'Selektion',
    General: 'Allgemein',
    'Due date': 'Fälligkeitsdatum',
    'Due dates': 'Fälligkeiten',
    Other: 'Sonstige',
    'New condition': 'Neue Bedingung',
    Condition: 'Bedingung',
    Description: 'Beschreibung',
    'Display conditions': 'Bedingung hinzufügen',
    'CRM ID': 'CRM Auftragsnummer',
    Timing: 'Zeitplan',
    'Approval period': 'Zeitraum Freigabe (Monatsende - x Tage)',
    'Approval date': 'Freigabe ab',
    'Print period': 'Zeitraum Druck (Monatsanfang + x Tage)',
    'Print date': 'Druck ab',
    Pricing: 'Preise',
    Dispatch: 'Versand',
    'Master template': 'Templatevorlage',
    Subject: 'Betreff',
    Addresser: 'Absender',
    'Body (HTML)': 'Nachricht (HTML)',
    'Body (text)': 'Nachricht (Text)',
    'Print pattern': 'Belegexemplar',
    'Print pattern groups':
      'Wenn Mailing/E-Mail zu einer der folgenden Gruppen gehört',
    create: 'erstellen',
    Create: 'Erstellen',
    'Display name pattern': 'Muster für Anzeige',
    'Customer list': 'Empfängerliste',
    'Location aware': 'Standortabhängig',
    'New location': 'Neuer Standort',
    'New affiliate': 'Neue Niederlassung',
    'New staff': 'Neuer Mitarbeiter',
    'New module': 'Neuer Baustein',
    'Data loading...': 'Daten werden geladen...',
    'New account': 'Neuer Account',
    'New layout': 'Neues Layout',
    'New Campaign': 'Neue Kampagne',
    'New email': 'Neue E-Mail',
    'New sms': 'Neue SMS',
    monthly: 'Monatlich',
    singular: 'Einmalig',
    consecutively: 'Fortlaufend',
    cycle: 'Versand',
    combined: 'Mehrere Schreiben pro Kunde zusammenfassen',
    'confirm password': 'Passwort bestätigen',
    'current month': 'aktueller Monat',
    'current quarter': 'aktuelles Quartal',
    'current year': 'aktuelles Jahr',
    'last month': 'letzter Monat',
    'last quarter': 'letztes Quartal',
    'last year': 'letztes Jahr',
    'Send Email to User':
      'E-Mail, mit der Aufforderung sein Passwort festzulegen versenden',
    from: 'von',
    until: 'bis',
    mode: 'Modus',
    'Signature Mode': 'Art',
    all: 'Alle',
    'email account': 'E-Mail Account',
    'Reference group for job patterns': 'Zugehörigkeit Belegexemplar',
    Parent: 'Teil von',
    'Unlayer activated': 'E-Mail Editor',
    'campaign key': 'Kampagnenschlüssel',
    Content: 'Inhalt',
    'Abandon Interval': 'Datensatz freigeben nach',
    'Request Preview': 'Vorschau anfordern',
    'To which address should the preview be sent?':
      'An welche Adresse soll die Vorschau verschickt werden?',
    'Are you sure you want to revoke the approval? Until it is approved again, the campaign will be suspended.':
      'Wollen Sie die Freigabe wirklich widerrufen? Bis zur erneuten Freigabe, wird der Aussand der Kampagne ausgesetzt.',
    'Reset Campaign': 'Kampagne zurücksetzen',
    Cancel: 'Abbrechen',
    'Save & Approve': 'Speichern & Freigeben',
    Send: 'Abschicken',
    Save: 'Speichern',
    'Text preview': 'Text Vorschau',
    layout: 'Layout',
    product: 'Produkt',
    service: 'Service',
    template: 'Vorlage',
    'customer list tag': 'Empfängerliste',
    Peculiarity: 'Ausprägung',
    Category: 'Kategorie',
    module_category_layout: 'Master-Bits',
    module_category_product: 'Zubehör & Collection',
    module_category_service: 'Service',
    module_category_template: 'Vorlage',
    module_category_vehicles: 'Neu- & Gebrauchtfahrzeuge',
    module_category_image: 'Image & Bindung',
    module_category_other: 'Sonstige',
    'Delay Interval': 'Freigabe Intervall',
    Archived: 'Archivierung',
    Tyres: 'Räder/Reifen',
    Username: 'Benutzername',
    Password: 'Passwort',
    'Sender E-Mail': 'Absender E-Mail',
    'E-Mail reply to': 'Antwort E-Mail',
    'Bcc (used for backup)': 'BCC (für Backup genutzt)',
    'Receiver test run': 'Empfängertestlauf',
    Host: 'Host',
    'Inbox Host (deprecated)': 'Host Posteingang (veraltet)',
    SMTP: 'SMTP',
    SSL: 'SSL',
    TLS: 'TLS',
    Port: 'Port',
    Subscribermanagement: 'Abonnentenverwaltung',
    'Mailer URL': 'SEEDS mailer Domain',
    'Campaign Tracking': 'Kampagne verfolgen',
    'Personalized Tracking': 'Kampagne personalisiert verfolgen',
    Tracking: 'Nachverfolgung',
    Add: 'Hinzufügen',
    Edit: 'Bearbeiten',
    Delete: 'Löschen',
    Duplicate: 'Duplizieren',
    'Invalid credentials.': 'Fehlerhafte Zugangsdaten.',
    'New signee': 'Neuer Unterzeichner',
    'Configuration files': 'Konfigurationsdateien',
    Type: 'Dateityp',
    'Crm Key': 'CRM-Schlüssel',
    label: 'Bezeichnung',
    required: 'Datei erforderlich',
    description: 'Beschreibung',
    'New File': 'Neue Datei',
    Status: 'Status',
    Image: 'Bild',
    'New product': 'Neues Produkt',
    'Price Headline': 'Preis Überschrift',
    'Price Appendix 1': 'Preis Anhang 1',
    'Price Appendix 2': 'Preis Anhang 2',
    'Price Appendix 3': 'Preis Anhang 3',
    Priority: 'Priorität',
    'Print Config': 'Print Konfiguration',
    'Email Config': 'E-Mail Konfiguration',
    'Web Config': 'Web Konfiguration',
    Price: 'Preis',
    Footnote: 'Fußnote',
    Next: 'Weiter',
    Back: 'Zurück',
    'Config Label': 'Konfigurationsbezeichnung',
    'Config Key': 'Konfigurationsschlüssel',
    'Mapping (comma-separated list)': 'Ausprägung (kommagetrennte Liste)',
    Seasons: 'Kampagnen',
    Campaigns: 'Kampagnen',
    'SPPS price key': 'SPPS Bezeichnung',
    Campaign: 'Kampagne',
    'New param': 'Neuer Parameter',
    'reference date': 'Referenz-Datum',
    effect: 'Modus',
    exclude: 'ausschließen',
    exclude_opened: 'Öffnungen ausschließen',

    //Validation
    'This field should not be empty.': 'Dieses Feld darf nicht leer sein.',
    'Please choose an option.': 'Bitte treffen Sie eine Auswahl.',
    'Please use only numbers and ..': 'Bitte nur Zahlen und "." verwenden.',
    'Please use only numbers and . or ,.':
      'Bitte nur Zahlen und "." oder "," verwenden.',
    'Please use only CMYK farb definitions.':
      'Bitte nur CMYK-Farbdefinitionen verwenden.',
    'Enter a valid email.': 'Bitte geben Sie eine gültige E-Mail Adresse an.',
    'Enter only positive integral numbers':
      'Bitte geben Sie nur ganze Zahlen größer 0 ein.',
    'Enter a valid config key. Allowed: all alphanumeric characters and underscore.':
      'Bitte geben Sie einen gültigen Config Schlüssel ein. Erlaubt: alle alphanumerische Zeichen und Unterstrich.',
    'Enter valid url.': 'Bitte geben Sie eine gültige URL ein.',
    'Enter valid phone number.':
      'Bitte geben Sie eine gültige Telefonnummer an.',
    'This role is not a valid choice.':
      'Diese Rolle ist keine gültige Auswahl.',
    'The password must contain at least 8 characters from 3 of the following 4 categories: capitals, lower case, numbers, special characters.':
      'Das Passwort muss mindestens 8 Zeichen lang sein und 3 der folgenden 4 Kategorien beinhalten: Großbuchstaben, Kleinbuchstaben, Zahlen, Sonderzeichen.',
    'Passwords do not match.': 'Die Passwörter stimmen nicht überein.',
    'Please do not pass on your access data to third parties and keep them under lock and key. We recommend the use of so-called password safes. Do not use simple, easy-to-guess combinations for the password.':
      'Bitte geben Sie Ihre Zugangsdaten nicht an Dritte weiter und halten Sie diese unter Verschluss. Wir empfehlen die Nutzung von sogenannten Passwort-Safes. Verwenden Sie für das Passwort keine einfachen, leicht zu erratenden Kombinationen.',
    'The password must contain at least 8 characters.':
      'Das Passwort muss mindestens 8 Zeichen lang sein.',
    'The password must contain at least 3 of the following 4 categories: capitals, lower case, numbers, special characters.':
      'Das Passwort muss 3 der folgenden 4 Kategorien beinhalten: Großbuchstaben, Kleinbuchstaben, Zahlen, Sonderzeichen.',
    'Passwords must match.': 'Die Passwörter müssen übereinstimmen.',
    'masterTemplate.sender must be at most 21 characters':
      'Der Sender darf max. 21 Zeichen lang sein.',
    'season.delayInterval wrong format':
      'Das Freigabeintervall muss dem Format dieses Beispiels entsprechen: "P2D".',
    missing: 'Daten fehlen',
    delta_review:
      'es liegen zu viele Reaktivierungen vor, Daten wurden nicht geändert',
    delat_deletes:
      'es liegen zu viele Löschungen vor, Daten wurden nicht bereinigt',
    'The Editor should not be empty.': 'Der Editor darf nicht leer sein.',
    'Choose a signature to upload.': 'Bitte laden Sie eine Unterschrift hoch.',
    'Add signature to at least one mailing.':
      'Weisen Sie die Unterschrift mindestens einem Mailing zu.',
    'Use a valid date format.': 'Bitte geben Sie ein gültiges Datum ein.',
    'No Duplicates.':
      'Dieser Wert darf im Formular nur einmal bei allen Konfiguartionsdateien vorkommen.',
    commercial: 'Firmenkunde',
    private: 'Privatkunde',
  },
  date: {
    January: 'Januar',
    February: 'Februar',
    March: 'März',
    May: 'Mai',
    June: 'Juni',
    July: 'Juli',
    October: 'Oktober',
    December: 'Dezember',
  },
  form: {
    words: 'Bitte nur Buchstaben verwenden',
    color: 'Bitte nur CMYK-Farbdefinitionen verwenden',
    maxLength: 'Die max. Anzahl an Zeichen wurde überschritten',
    isEqual: 'Die angegebenen Passwörter stimmen nicht überein.',
    isFloat: 'Bitte nur Zahlen und "." verwenden.',
    isEuro: 'Bitte nur Zahlen und "," verwenden.',
    isPhone:
      'Bitte eine gültige Telefonnr. verwenden. Erlaubte Zeichen Zahlen, Leerzeichen, +, -',
    isEmail: 'Bitte eine gültige E-Mail Adresse verwenden.',
    isAlphanumeric: 'Bitte nur Zahlen und Buchstaben verwenden.',
    isUsername:
      'Bitte nur Zahlen und Buchstaben verwenden, max. 1 Wort erlaubt.',
    numeric: 'Bitte nur Zahlen verwenden.',
    url: 'Bitte eine gültige URL eingeben verwenden.',
    isExisty: 'Bitte geben Sie einen Wert ein',
    isOneWord: 'Bitte geben Sie nur ein Wort ein (_ möglich)',
  },
  customerGroups: {
    KG1: 'Einlagerer mit Bedarf und Angebot',
    KG2: 'Einlagerer mit Bedarf ohne Angebot',
    KG3: 'Einlagerer ohne  Bedarf',
    KG4: 'Nichteinlagerer Aktive mit Bedarf',
    KG5: 'Nichteinlagerer Aktive ohne Bedarf',
    KG6: 'Werkstattfernbleiber',
    KG7: 'Neufahrzeuge',
    inDepotWithDemand: 'Einlagerer mit Bedarf',
    inDepotWithoutDemand: 'Einlagerer ohne Bedarf',
    notInDepot: 'Nichteinlagerer',
  },
  campaignVersion: {
    Pkw: 'PKW',
    Trapo: 'Vans',
    Smart: 'smart',
  },
};
