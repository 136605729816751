import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { isEqual } from 'lodash';
import { Box, Snackbar, Alert } from '@mui/material';
import { withRouter, WithRouterProps } from '../../withRouter';
import {
  resetErrorMessage,
  resetInfoMessage,
  resetDownload,
  resetWarningMessage,
} from '../../actions';
import Loading from '../../components/Loading';
import Download from '../../components/Download';
import AppNavDrawer from './components/AppNavDrawer';
import { loadStaffConfig, loadSession } from '../Staff/actions';
import Login from '../Login/Login';
import Background1 from '../../theme/images/login/background_1.jpg';
import Background2 from '../../theme/images/login/background_2.jpg';
import Background3 from '../../theme/images/login/background_3.jpg';
import Background4 from '../../theme/images/login/background_4.jpg';
import { RootState } from '../../reducers';

type DefaultProps = {
  autoHideDuration: number;
  loading: boolean;
  errorMessage: string;
  infoMessage: string;
};

type DefaultState = {
  navOpen: boolean;
  loginChecking: boolean;
  loggedInBefore: boolean;
  loadingSession: boolean;
  backgroundImage: string;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & DefaultProps & WithRouterProps;

class Default extends Component<Props, DefaultState> {
  static defaultProps = {
    autoHideDuration: 6500,
    loading: true,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      navOpen: true,
      loginChecking: true,
      loggedInBefore: false,
      loadingSession: false,
      backgroundImage: '',
    };
  }

  componentDidMount() {
    const randomNumber = Math.floor(Math.random() * Math.floor(4));
    const backgroundImages: string[] = [
      Background1,
      Background2,
      Background3,
      Background4,
    ];
    this.setState({ backgroundImage: backgroundImages[randomNumber] });

    this.loadSession();
    this.checkSession();
  }

  shouldComponentUpdate(nextProps: Props, nextState: DefaultState) {
    const {
      errorMessage,
      download,
      infoMessage,
      warningMessage,
      loading,
      loggedIn,
    } = this.props;
    const { navOpen, loggedInBefore, loadingSession, loginChecking } =
      this.state;

    return (
      !isEqual(nextProps.errorMessage, errorMessage) ||
      !isEqual(nextProps.download, download) ||
      !isEqual(nextProps.infoMessage, infoMessage) ||
      !isEqual(nextProps.warningMessage, warningMessage) ||
      !isEqual(nextProps.loading, loading) ||
      !isEqual(nextState.navOpen, navOpen) ||
      !isEqual(nextState.loggedInBefore, loggedInBefore) ||
      !isEqual(nextState.loadingSession, loadingSession) ||
      !isEqual(nextState.loginChecking, loginChecking) ||
      !isEqual(nextProps.loggedIn, loggedIn)
    );
  }

  loadSession = () => {
    const { loadSession, loadStaffConfig } = this.props;

    Promise.all([loadSession()]).then(
      (values) => {
        if (values[0].type === 'SESSION_SUCCESS') {
          const { config } = this.props;
          if (!config) {
            Promise.all([loadStaffConfig()]).then(
              () => {
                const {
                  config: { config: configDetails },
                } = this.props;
                // const {config: configDetails} = this.props.config
                const { user } = configDetails;
                localStorage.setItem(
                  'user',
                  JSON.stringify({
                    username: user.username,
                    language: user.language,
                    role: user.role,
                    roles: configDetails.roles,
                  }),
                );
                this.hideLoginModal();
              },
              () => {
                this.setState({ loginChecking: false, loadingSession: false });
              },
            );
          } else {
            this.setState({ loginChecking: false, loadingSession: false });
          }
        } else {
          this.setState({ loginChecking: false, loadingSession: false });
        }
      },
      () => {
        this.setState({ loginChecking: false, loadingSession: false });
      },
    );
  };

  handleChangeList = (v: string) => {
    const { navigate } = this.props;

    if (v) {
      navigate(v);
    }
  };

  handleDownloadMount = (link: React.RefObject<HTMLAnchorElement>) => {
    if (link.current) {
      link.current.click();
    }
  };

  renderErrorMessage = () => {
    const { errorMessage, autoHideDuration, resetErrorMessage } = this.props;

    if (!errorMessage) {
      return null;
    }

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!errorMessage}
        onClose={resetErrorMessage}
        autoHideDuration={autoHideDuration}
        key="bottomcenter"
      >
        <Alert severity="error" variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>
    );
  };

  renderInfoMessage = () => {
    const { infoMessage, autoHideDuration, resetInfoMessage } = this.props;

    if (!infoMessage) {
      return null;
    }

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!infoMessage}
        onClose={resetInfoMessage}
        autoHideDuration={autoHideDuration}
        key="bottomcenter"
      >
        <Alert severity="success" variant="filled">
          {infoMessage}
        </Alert>
      </Snackbar>
    );
  };

  renderWarningMessage = () => {
    const { warningMessage, autoHideDuration, resetWarningMessage } =
      this.props;

    if (!warningMessage) {
      return null;
    }

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!warningMessage}
        onClose={resetWarningMessage}
        autoHideDuration={autoHideDuration}
        key="bottomcenter"
      >
        <Alert severity="warning" variant="filled">
          {warningMessage}
        </Alert>
      </Snackbar>
    );
  };

  renderDownload = () => {
    const { download, autoHideDuration, resetDownload } = this.props;

    if (!download) {
      return null;
    }

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!download}
        onClose={resetDownload}
        autoHideDuration={autoHideDuration}
        key="bottomcenter"
      >
        <Alert severity="info" variant="filled">
          <Download
            file={download.path}
            onMount={this.handleDownloadMount}
            label="Your download is starting!"
          />
        </Alert>
      </Snackbar>
    );
  };

  renderLoading = () => {
    const { loading } = this.props;

    if (loading) {
      return <Loading />;
    }
    return null;
  };

  hideLoginModal = () => {
    this.setState({
      loggedInBefore: true,
      loginChecking: false,
      loadingSession: false,
    });
  };

  checkSession() {
    const { loggedIn } = this.props;

    if (loggedIn) {
      this.loadSession();
    }
    // 10 Minuten in Millisekunden: 600000; 10 Sekunden:
    setTimeout(() => {
      this.checkSession();
    }, 600000);
  }

  renderBackgroundImage = () => {
    const { backgroundImage } = this.state;
    return (
      <Box
        sx={{
          position: 'fixed',
          top: '-50%',
          left: '-50%',
          width: '200%',
          height: '200%',
        }}
      >
        <Box
          component="img"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 'auto',
            minWidth: '50%',
            minHeight: '50%',
          }}
          src={backgroundImage}
          alt="Background"
        />
      </Box>
    );
  };

  renderComponents = () => {
    const { loggedInBefore } = this.state;

    if (loggedInBefore) {
      return (
        <>
          <AppNavDrawer onChangeList={this.handleChangeList} />
          {this.renderLoading()}
          {this.renderInfoMessage()}
          {this.renderErrorMessage()}
          {this.renderWarningMessage()}
          {this.renderDownload()}
        </>
      );
    }
    return <>{this.renderBackgroundImage()}</>;
  };

  render() {
    const { loggedInBefore, loadingSession, loginChecking } = this.state;
    const { loggedIn } = this.props;

    if (loginChecking) {
      return this.renderLoading();
    }
    return (
      <>
        {this.renderComponents()}
        <Login
          showLogin={!loggedIn || loadingSession}
          onLoginSuccess={(_event, reason) => {
            if (reason !== 'backdropClick') {
              this.setState({ loadingSession: true });
              this.loadSession();
            }
          }}
          loggedInBefore={loggedInBefore}
        />
      </>
    );
  }
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state: RootState) {
  return {
    errorMessage: state.errorMessage,
    infoMessage: state.infoMessage,
    warningMessage: state.warningMessage,
    download: state.downloads,
    loading: state.loading.length > 0,
    config: state.entities.config,
    session: state.entities.session,
    staff: state.entities.staff,
    owner: state.entities.owner,
    loggedIn: state.loggedIn ? state.loggedIn.loggedIn : false,
  };
}

const connector = connect(mapStateToProps, {
  resetErrorMessage,
  resetInfoMessage,
  resetWarningMessage,
  resetDownload,
  loadStaffConfig,
  loadSession,
});

export default withRouter(connector(Default));
