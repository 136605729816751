import { PaletteColor } from '@mui/material/styles/createPalette';
import { alpha } from '@mui/material/styles';

const withAlphas = (color: PaletteColor): PaletteColor => ({
  ...color,
  alpha4: alpha(color.main, 0.04),
  alpha8: alpha(color.main, 0.08),
  alpha12: alpha(color.main, 0.12),
  alpha30: alpha(color.main, 0.3),
  alpha50: alpha(color.main, 0.5),
});

export const neutral = {
  50: '#F8F9FA',
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D2D6DB',
  400: '#9DA4AE',
  500: '#6C737F',
  600: '#4D5761',
  700: '#2F3746',
  800: '#1C2536',
  900: '#111927',
};

// generated with https://m2.material.io/inline-tools/color/
const eeloyBluePalette = {
  50: '#e1f5fc',
  100: '#b4e5f7',
  200: '#83d4f2',
  300: '#56c3ed',
  400: '#36b6ea',
  500: '#1ca9e7',
  600: '#169bd9',
  700: '#0e88c6',
  800: '#0d77b2',
  900: '#045890',
};

// light and dark from: https://mui.com/material-ui/customization/color/
export const eeloyBlue = withAlphas({
  lightest: eeloyBluePalette[50],
  light: '#44afe0',
  main: '#169CD9',
  dark: '#0f6d97',
  darkest: eeloyBluePalette[900],
  contrastText: '#FFFFFF',
});

export const blue = withAlphas({
  lightest: '#F5F8FF',
  light: '#EBEFFF',
  main: '#2970FF',
  dark: '#004EEB',
  darkest: '#00359E',
  contrastText: '#FFFFFF',
});

export const green = withAlphas({
  lightest: '#F6FEF9',
  light: '#EDFCF2',
  main: '#16B364',
  dark: '#087443',
  darkest: '#084C2E',
  contrastText: '#FFFFFF',
});

export const indigo = withAlphas({
  lightest: '#F5F7FF',
  light: '#EBEEFE',
  main: '#6366F1',
  dark: '#4338CA',
  darkest: '#312E81',
  contrastText: '#FFFFFF',
});

export const purple = withAlphas({
  lightest: '#F9F5FF',
  light: '#F4EBFF',
  main: '#9E77ED',
  dark: '#6941C6',
  darkest: '#42307D',
  contrastText: '#FFFFFF',
});

export const success = withAlphas({
  lightest: '#F0FDF9',
  light: '#3FC79A',
  main: '#10B981',
  dark: '#0B815A',
  darkest: '#134E48',
  contrastText: '#FFFFFF',
});

export const info = withAlphas({
  lightest: '#ECFDFF',
  light: '#CFF9FE',
  main: '#06AED4',
  dark: '#0E7090',
  darkest: '#164C63',
  contrastText: '#FFFFFF',
});

export const warning = withAlphas({
  lightest: '#FFFAEB',
  light: '#FEF0C7',
  main: '#F79009',
  dark: '#B54708',
  darkest: '#7A2E0E',
  contrastText: '#FFFFFF',
});

export const error = withAlphas({
  lightest: '#FEF3F2',
  light: '#FEE4E2',
  main: '#F04438',
  dark: '#B42318',
  darkest: '#7A271A',
  contrastText: '#FFFFFF',
});

// Old Eeloy Colors
export const seedsGrey50 = '#303030';
export const seedsGrey100 = '#404853';
export const seedsGrey125 = '#7e7e7e';
export const seedsGrey140 = '#E9E9EF';
export const seedsGrey150 = '#f7f8fa';
export const seedsGrey150Alpha09 = 'rgba(247,248,250, 0.9)';

export const seedsBlue50 = '#4282b0';
export const seedsBlue100 = '#2580c3';
export const seedsBlue150 = '#619dd8';
