import selectors from '../../selectors';
import { pickBy } from 'lodash';

// eslint-disable-next-line no-unused-vars
const getAffiliates = (state, props) => state.entities.affiliate;
// eslint-disable-next-line no-unused-vars
const getAffiliateSettings = (state, props) => state.entities.affiliatesetting;

const getParentFilter = (state, props) =>
  props.affiliateId ? parseInt(props.affiliateId) : null;
const getAffiliateFilter = (state, props) =>
  props.affiliateId ? parseInt(props.affiliateId) : null;

export const makeAffiliatesByParent = () => {
  return selectors([getParentFilter, getAffiliates], (parentId, affiliates) => {
    return pickBy(affiliates, { parent: parentId });
  });
};

export const makeAffiliateById = () => {
  return selectors(
    [getAffiliateFilter, getAffiliates],
    (affiliateId, affiliates) => {
      return pickBy(affiliates, { id: affiliateId });
    },
  );
};
