import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ACL from '../../components/ACL';
import AffiliateSetting from './AffiliateSetting';

class AffiliateSettingIndex extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_ADMIN'],
  };

  render() {
    const { requiredRoles, ...rest } = this.props;

    return (
      <ACL authorized={requiredRoles}>
        <AffiliateSetting {...rest} />
      </ACL>
    );
  }
}

export default AffiliateSettingIndex;
