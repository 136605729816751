import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import KeyIndicators from './components/KeyIndictors/KeyIndicators';
import Recipients from './components/Recipients/Recipients';
import Interactions from './components/Interactions/Interactions';
import ReportTables from './components/ReportTables/ReportTables';

export default function StatisticCampaignIndex() {
  const { id } = useParams();

  if (id === undefined || Number.isNaN(parseInt(id, 10))) {
    return <Navigate to="/" />;
  }

  const campaignId = parseInt(id, 10);

  return (
    <Stack spacing={3}>
      <KeyIndicators campaignId={campaignId} />
      <Recipients campaignId={campaignId} />
      <Interactions campaignId={campaignId} />
      <ReportTables campaignId={campaignId} />
    </Stack>
  );
}
