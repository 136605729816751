const paths = {
  index: '/',
  emailAccount: {
    index: '/email/account',
    create: '/email/account/create',
    edit: '/email/account/edit/:accountId',
  },
};

export default paths;
