import type { ThemeOptions } from '@mui/material/styles/createTheme';

import type { Direction } from './index';
import { createTypography } from './CreateTypography';
import { createComponents } from './CreateComponents';

interface Config {
  direction?: Direction;
}

// Here we do not modify the "palette" and "shadows" because "light" and "dark" mode
// may have different values.

// eslint-disable-next-line import/prefer-default-export
export const createOptions = (config: Config): ThemeOptions => {
  const { direction = 'ltr' } = config;

  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
      },
    },
    components: createComponents(),
    direction,
    shape: {
      borderRadius: 8,
    },
    typography: createTypography(),
    locale: {
      de: 'de-DE',
      deString: 'dd.MM.yyyy',
      fr: 'fr-ca',
    },
  };
};
