import React from 'react';
import ACL from '../../components/ACL';
import EmailAccount from './Account';

type EmailAccountIndexProps = {
  requiredRoles?: string[];
};

function EmailAccountIndex({
  requiredRoles = ['ROLE_SUPERADMIN'],
}: EmailAccountIndexProps): JSX.Element {
  return (
    <ACL authorized={requiredRoles}>
      <EmailAccount />
    </ACL>
  );
}

export default EmailAccountIndex;
